import React, { useContext } from "react";
import { forEach, now } from "lodash";
import XLSX from "xlsx";
import { format } from "date-fns";

import { makeStyles, Button, Box } from "@material-ui/core";

import { StoreContext } from "../context";
import { OrderConfirmationDialog } from "./Orders/Dialogs/OrderConfirmationDialog";
import { ORDER_STATUS } from "./Orders/constants";

const useStyles = makeStyles((theme) => ({
  btns: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    marginTop: 5,
    marginLeft: 5,
    marginRight: 5,
  },
}));

const CartButtonsAdmin = () => {
  const classes = useStyles();
  const ctx = useContext(StoreContext);

  const { cartInfo, isAdmin } = ctx;
  const { status, _id } = cartInfo;
  const { screens, roofs, parts } = cartInfo;

  const handleOrderExport = async (e) => {
    e.preventDefault();

    const fileName = `${format(new Date(now()), "yyyyMMdd")}_${
      ctx?.cartInfo?.orderName
    }.xlsx`;

    const orderExport = (type) => {
      let orderDetails = [];
      let scrDetails = [];
      let scrKits = [];
      let boms = [];
      let rfDetails = [];
      let rfKits = [];
      let prtLines = [];

      function extractRfKits(data, roofName) {
        function processArray(array, lengthKey, categoryName) {
          if (Array.isArray(array) && array.length > 0) {
            array.forEach((item) => {
              let kit =
                item.frameKit ||
                item.BeamKit ||
                item.bladeKit ||
                item.postKit ||
                item.addPartKit;
              if (kit) {
                rfKits.push({
                  "Roof Name": roofName,
                  "Item Number": kit.number,
                  "Display Name": kit.displayName,
                  "Unit of Measure": kit.unitOfMeasure,
                  Length: item[lengthKey] || null,
                });
              }
            });
          }
        }
        processArray(data.frameKits, "frameLength", "frameKit");
        processArray(data.beamMKits, "beamLength", "BeamKit");
        processArray(data.beamAKits, "beamLength", "BeamKit");
        processArray(data.beamXKits, "beamLength", "BeamKit");
        processArray(data.bladeKits, "bladeLength", "bladeKit");
        processArray(data.postKits, "postKit", "postKit");
        processArray(data.addPartsKits, "addPartQty", "addPartKit");
        if (data.motorKit) {
          rfKits.push({
            "Roof Name": roofName,
            "Item Number": data.motorKit.number,
            "Display Name": data.motorKit.displayName,
            "Unit of Measure": data.motorKit.unitOfMeasure,
            Length: null, // Motor does not have a length
          });
        }
      }

      switch (type) {
        case "details":
          orderDetails.push({
            "Dealer Name": ctx?.cartInfo?.userDetails?.bc_customer_displayName,
            "User Name": ctx?.cartInfo?.userDetails?.userName,
            "Price Group": ctx?.cartInfo?.userDetails?.bc_customer_priceGroup,
            "Order Name": ctx?.cartInfo?.orderName,
            "Order Number": ctx?.cartInfo?.orderNumber,
            "Order Date": format(
              new Date(ctx?.cartInfo?.createdAt),
              "MMM dd, yyyy"
            ),
            "Last Updated": format(
              new Date(ctx?.cartInfo?.updatedAt),
              "MMM dd, yyyy"
            ),
            "Extra Details for Parts": ctx?.cartInfo?.extraDetails,
          });
          return { orderDetails };

        case "screens":
          forEach(screens, (scr) => {
            scrDetails.push({
              "Screen Name": scr.screenName,
              "Screen Type": scr.options["Housing Size"],
              "Job Color": scr.options.jobColor,
              "Mesh Only": scr.meshOnly ? "Yes" : "No",
              "Mesh Type": scr.options.materialType,
              "Mesh Color": scr.options[`${scr.options.materialType} Color`],
              Motor: scr.options["Motor Type"],
              "Motor Side": scr.options["Motor Side"],
              "Track Left": scr.options["Track Left"],
              "Track Right": scr.options["Track Right"],
              "Bar Finish": scr.options["Bottom Bar Finish"],
              "Mounting Angle": scr.options.mountingAngle,
              "Screen Width": scr.measurements.widthDisplay,
              "Drop Left": scr.measurements.dropLeftDisplay,
              "Drop Right": scr.measurements.dropRightDisplay,
              "Additional Details": scr.details,
            });
            forEach(scr.mappedScrKits.BOMS, (bom) => {
              scrKits.push({
                "Screen Name": scr.screenName,
                "Id Number": String(bom.parentId),
                "Kit Name": bom.description,
              });
            });
            forEach(scr.mappedScrKits.items, (item) => {
              scrKits.push({
                "Screen Name": scr.screenName,
                "Id Number": String(item.number),
                "Kit Name": item.displayName,
              });
            });
            forEach(scr.mappedScrKits.BOMS, (bom) => {
              forEach(bom.childItems, (childItem) => {
                let quantity = childItem.quantityPer;
                if (
                  String(bom.parentId).startsWith("BOM-RS-5WC") ||
                  String(bom.parentId).startsWith("BOM-RS-5WS") ||
                  String(bom.parentId).startsWith("BOM-RS-7WC")
                ) {
                  let fractionValue = 0;
                  if (scr.measurements.widthFraction !== "0") {
                    let fraction = scr.measurements.widthFraction;
                    let [numerator, denominator] = fraction
                      .split("/")
                      .map(Number);
                    fractionValue = numerator / denominator;
                  }
                  let totalInches =
                    Number(scr.measurements.width) + fractionValue;
                  quantity = (
                    childItem.quantityPer *
                    (totalInches / 12)
                  ).toFixed(2);
                }
                boms.push({
                  "Id Number": String(bom.parentId),
                  "Child Item": childItem.description,
                  Quantity: quantity,
                  "Units of measure": childItem.unitOfMeasureCode,
                });
              });
            });
          });
          return { scrDetails, scrKits, boms };

        case "roofs":
          forEach(roofs, (rf) => {
            rfDetails.push({
              "Roof Name": rf.roofName,
              "Motor Type": rf.motorType,
              "Motor Quantity": rf.motorQuantity,
              "Frame Color": rf.colors.frameColor,
              "Blade Color": rf.colors.bladeColor,
              "Post Color": rf.colors.postColor,
            });
            forEach(rf.frames, (frame, i) => {
              rfDetails[0][`Frame${i} Type`] = frame.frameType;
              rfDetails[0][`Frame${i} Feet`] = frame.frameFeet;
              rfDetails[0][`Frame${i} Inches`] = frame.frameInches;
            });
            forEach(rf.beamMs, (beam, i) => {
              rfDetails[0][`Mid Beam${i} Type`] = beam.beamType;
              rfDetails[0][`Mid Beam${i} Feet`] = beam.beamFeet;
              rfDetails[0][`Mid Beam${i} Inches`] = beam.beamInches;
            });
            forEach(rf.beamAs, (beam, i) => {
              rfDetails[0][`Acc Beam${i} Type`] = beam.beamType;
              rfDetails[0][`Acc Beam${i} Feet`] = beam.beamFeet;
              rfDetails[0][`Acc Beam${i} Inches`] = beam.beamInches;
            });
            forEach(rf.beamXs, (beam, i) => {
              rfDetails[0][`Extra Beam${i} Type`] = beam.beamType;
              rfDetails[0][`Extra Beam${i} Feet`] = beam.beamFeet;
              rfDetails[0][`Extra Beam${i} Inches`] = beam.beamInches;
            });
            forEach(rf.blades, (blade, i) => {
              rfDetails[0][`Blade${i} Type`] = blade.bladeType;
              rfDetails[0][`Blade${i} Feet`] = blade.bladeFeet;
              rfDetails[0][`Blade${i} Inches`] = blade.bladeInches;
              rfDetails[0][`Blade${i} Qty`] = blade.bladeQty;
            });
            forEach(rf.posts, (post, i) => {
              rfDetails[0][`Post${i} Style`] = post.postStyle;
              rfDetails[0][`Post${i} Length`] = post.postLength;
            });
            forEach(rf.addParts, (part, i) => {
              rfDetails[0][`Add Part${i} Name`] = part.partName;
              rfDetails[0][`Add Part${i} Qty`] = part.partQty;
            });
            extractRfKits(rf.mappedRfKits, rf.roofName);
          });
          return {
            rfDetails,
            rfKits,
          };

        case "parts":
          // console.log('parts', parts);
          forEach(parts, (prt) => {
            prtLines.push({
              "Part Name": prt.displayName,
              "Master Number": String(prt.number),
              Quantity: prt.quantity,
              Price: prt.price,
              "Line Total": Number(prt.quantity) * Number(prt.price),
            });
          });
          return { prtLines };

        default:
          return "";
      }
    };

    const orderDetailsWorksheet = XLSX.utils.json_to_sheet(
      orderExport("details").orderDetails
    );
    const screenDetailsWorksheet = XLSX.utils.json_to_sheet(
      orderExport("screens").scrDetails
    );
    const screenKitsWorksheet = XLSX.utils.json_to_sheet(
      orderExport("screens").scrKits
    );
    const bomsWorksheet = XLSX.utils.json_to_sheet(orderExport("screens").boms);
    const roofDetailsWorksheet = XLSX.utils.json_to_sheet(
      orderExport("roofs").rfDetails
    );
    const roofKitsWorksheet = XLSX.utils.json_to_sheet(
      orderExport("roofs").rfKits
    );
    const partsWorksheet = XLSX.utils.json_to_sheet(
      orderExport("parts").prtLines
    );
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      workbook,
      orderDetailsWorksheet,
      "Order Details"
    );
    XLSX.utils.book_append_sheet(
      workbook,
      screenDetailsWorksheet,
      "Screens List"
    );
    XLSX.utils.book_append_sheet(
      workbook,
      screenKitsWorksheet,
      "Screen Kits List"
    );
    XLSX.utils.book_append_sheet(
      workbook,
      bomsWorksheet,
      "Screen Bom Item List"
    );
    XLSX.utils.book_append_sheet(
      workbook,
      roofDetailsWorksheet,
      "Roof Details List"
    );
    XLSX.utils.book_append_sheet(workbook, roofKitsWorksheet, "Roof Kits List");
    XLSX.utils.book_append_sheet(workbook, partsWorksheet, "Parts List");
    XLSX.writeFile(workbook, fileName);
  };

  const displayAdminButtons = () => {
    let statusesForDisplay = [ORDER_STATUS.SUBMITTED, ORDER_STATUS.APPROVED];
    return isAdmin && _id && statusesForDisplay.includes(status);
  };

  return displayAdminButtons() ? (
    <Box className={classes.btns}>
      <Button
        className={classes.exportBtn}
        variant="contained"
        color="primary"
        size="small"
        onClick={(e) => handleOrderExport(e)}
      >
        Export Cart
      </Button>
      <OrderConfirmationDialog
        dialogTitle="Process Order"
        openDialogButtonLabel="Process Order"
        saveButtonLabel="Save Order"
        submitButtonLabel="Approve Order"
      />
    </Box>
  ) : null;
};

export { CartButtonsAdmin };
