import {
  FILE_UPLOAD_REQUEST,
  FILE_UPLOAD_SUCCESS,
  FILE_UPLOAD_FAILURE,
  FILE_LIST_REQUEST,
  FILE_LIST_SUCCESS,
  FILE_LIST_FAILURE,
  FILE_DELETE_REQUEST,
  FILE_DELETE_SUCCESS,
  FILE_DELETE_FAILURE,
  GET_PHOTOS_FAILURE,
  GET_PHOTOS_REQUEST,
  GET_PHOTOS_SUCCESS,
} from "../constants/fileConstants";

export const fileListReducer = (state = {}, action) => {
  switch (action.type) {
    case FILE_UPLOAD_REQUEST:
    case FILE_LIST_REQUEST:
    case FILE_DELETE_REQUEST:
      return { ...state, loading: true };

    case FILE_UPLOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        files: {
          ...state.files,
          [action.payload.bucket]: [
            ...(state.files[action.payload.bucket] || []),
            ...action.payload.uploadedFiles,
          ],
        },
      };

    case FILE_LIST_SUCCESS:
      return { ...state, loading: false, files: action.payload };

    case GET_PHOTOS_REQUEST:
      return { ...state, loading: true, error: null };

    case GET_PHOTOS_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case GET_PHOTOS_SUCCESS: {
      const { photos } = action.payload;

      return {
        ...state,
        files: {
          ...state.files,
          photos,
        },
        loading: false,
        error: null,
      };
    }

    case FILE_DELETE_SUCCESS:
      return {
        loading: false,
        error: null,
        files: {
          ...state.files,
          [action.payload.bucket]: state.files[action.payload.bucket].filter(
            (file) => file !== action.payload.file
          ),
        },
      };

    case FILE_UPLOAD_FAILURE:
    case FILE_LIST_FAILURE:
    case FILE_DELETE_FAILURE:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};
