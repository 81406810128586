import React from "react";

import { withStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

const CustomContentBox = withStyles((theme) => ({
  root: {
    marginBottom: "auto",

    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(2)
    },
  },
}))(Box);

const ContentBox = ({ children }) => {
  return <CustomContentBox>{children}</CustomContentBox>;
};

export { ContentBox };
