import api from "../api";
import ObjectID from "bson-objectid";
import { toast } from "react-toastify";

import {
  ORDER_CREATE_REQUEST,
  ORDER_CREATE_SUCCESS,
  ORDER_CREATE_FAIL,
  ORDER_DETAILS_REQUEST,
  ORDER_DETAILS_SUCCESS,
  ORDER_DETAILS_FAIL,
  ORDER_LIST_MY_FAIL,
  ORDER_LIST_MY_SUCCESS,
  ORDER_LIST_MY_REQUEST,
  ORDER_DELETE_REQUEST,
  ORDER_DELETE_SUCCESS,
  ORDER_DELETE_FAIL,
  ORDER_LIST_TEAM_REQUEST,
  ORDER_LIST_TEAM_SUCCESS,
  ORDER_LIST_TEAM_FAIL,
  ORDER_REJECT_REQUEST,
  ORDER_REJECT_SUCCESS,
  ORDER_REJECT_FAIL,
} from "../constants";
import { toTitleCase } from "../utils/stringHelpers";

/**
 * @typedef {import('../types/orderTypes.js').Order} Order
 */

/**
 * Creates a new order or updates an existing order.
 *
 * @param {Object} existingOrder - The existing order object.
 * @param {Order} newOrder - The new order details.
 * @returns {Function} A function that takes dispatch and getState as arguments and performs the order creation.
 */
export const createOrder =
  (existingOrder, newOrder) => async (dispatch, getState) => {
    const { extraDetails, orderNumber, receiver, sender, status, transfer } =
      newOrder;

    const orderName = toTitleCase(newOrder.orderName);

    try {
      dispatch({ type: ORDER_CREATE_REQUEST });

      const completeOrder = {
        ...existingOrder,
        extraDetails: extraDetails || existingOrder.extraDetails,
        orderId: existingOrder.orderId || ObjectID().toString(),
        orderName: orderName || existingOrder.orderName,
        orderNumber: orderNumber || existingOrder.orderNumber,
        quoteId: "",
        receiver: transfer ? receiver || existingOrder.receiver : "",
        sender: transfer ? sender || existingOrder.sender : "",
        status: status || existingOrder.status,
        transfer: transfer ?? Boolean(existingOrder.transfer),
      };

      const res = await api.post("/api/orders", completeOrder);

      dispatch({
        type: ORDER_CREATE_SUCCESS,
        payload: {
          order: res.data,
          status: res.status,
          statusText: res.statusText,
        },
      });
    } catch (err) {
      dispatch({ type: ORDER_CREATE_FAIL, payload: err.message });
    }
  };

export const getOrderDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_DETAILS_REQUEST,
    });

    const { data } = await api.get(`/api/orders/${id}`);

    dispatch({
      type: ORDER_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: ORDER_DETAILS_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const listMyOrders = (offset) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_LIST_MY_REQUEST,
    });

    const { data } = await api.get(`/api/orders/myorders?offset=${offset}`);

    dispatch({
      type: ORDER_LIST_MY_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: ORDER_LIST_MY_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const listTeamOrders = (offset) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_LIST_TEAM_REQUEST,
    });

    const { data } = await api.get(`/api/orders/teamorders?offset=${offset}`);

    dispatch({
      type: ORDER_LIST_TEAM_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: ORDER_LIST_TEAM_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const deleteOrder = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_DELETE_REQUEST,
    });

    await api.delete(`/api/orders/${id}`);

    dispatch({
      type: ORDER_DELETE_SUCCESS,
    });
  } catch (err) {
    dispatch({
      type: ORDER_DELETE_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const rejectOrder = (orderId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_REJECT_REQUEST,
    });

    await api.patch(`/api/orders/${orderId}`);

    toast.success("Order rejected", {
      toastId: orderId,
    });

    dispatch({
      type: ORDER_REJECT_SUCCESS,
    });
  } catch (error) {
    toast.error("Could not reject order", {
      toastId: orderId,
    });

    dispatch({
      type: ORDER_REJECT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
