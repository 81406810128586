import React from "react";
import Box from "@material-ui/core/Box";
import { withStyles } from "@material-ui/core/styles";

const StyledHeaderBox = withStyles((theme) => ({
  root: {
    display: "flex",
    flexFlow: "column",
    padding: `${theme.spacing(2)}px 0`,
    justifyContent: "space-between",
    alignItems: "flex-start",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
  },
}))(Box);

const HeaderBox = ({ children }) => {
  return <StyledHeaderBox>{children}</StyledHeaderBox>;
};

export default HeaderBox;
