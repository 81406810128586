import React from "react";

import { withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

const CustomContainer = withStyles((theme) => ({
  root: {
    padding: 0,

    [theme.breakpoints.up("sm")]: {
      padding: `0 ${theme.spacing(2)}px`,
    },
  },
}))(Container);

/**
 * Custom container for view components that removes horizontal padding
 * on small screens (mobile) and adds padding on larger screens
 */
const ViewContainer = ({ children, ...props }) => {
  return <CustomContainer {...props}>{children}</CustomContainer>;
};

export { ViewContainer };
