import React, { useEffect, useReducer, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Box, Tab, Tabs } from "@material-ui/core";
import queryString from "query-string";

import { Loader } from "../../components";

import {
  adminBcCustomerList,
  adminGetCustomerTiers,
  adminGetPortalPricing,
  bcItemsList,
  logout,
} from "../../actions";

import Data from "./Data";
import Orders from "./Orders";
import Pricing from "./Pricing";
import Users from "./Users/index";
import { ViewContainer } from "../../components/ViewContainer";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div hidden={value !== index} {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function useQuery() {
  const location = useLocation();
  return queryString.parse(location.search);
}

const AdminView = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery();

  const [value, setValue] = useState(0);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const bcSalesQuoteInit = useSelector((state) => state.bcSalesQuoteInit);
  const { loading: quoteLoading } = bcSalesQuoteInit;
  const adminPortalPricingSave = useSelector(
    (state) => state.adminPortalPricingSave
  );

  function isTokenExpired(token) {
    const expiry = JSON.parse(Buffer.from(token.split(".")[1], "base64")).exp;
    return Math.floor(new Date().getTime() / 1000) >= expiry;
  }

  const { status: adminPPSaveStatus } = adminPortalPricingSave;

  const acctTab = query.tab === "accounts";

  const forceUpdate = useReducer((x) => x + 1, 0)[1];

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (!userInfo || isTokenExpired(userInfo?.token) || !userInfo.isAdmin) {
      dispatch(logout());
      history.push("/login");
    } else {
      dispatch(adminBcCustomerList());
      dispatch(adminGetPortalPricing());
      dispatch(adminGetCustomerTiers());
      dispatch(bcItemsList());
      if (acctTab) {
        handleChange(null, 2);
      }
      if (adminPPSaveStatus && adminPPSaveStatus === 201) {
        forceUpdate();
      }
    }
  }, [history, userInfo, acctTab, adminPPSaveStatus, dispatch, forceUpdate]);

  // const importPricesHandler = (e) => {
  //   e.preventDefault();
  //   dispatch(adminImportPrices());
  // };

  // const handleBCItemsDispatch = (e) => {
  //   e.preventDefault();
  //   setBcItemsPg(e.target.value);
  //   dispatch(bcItemsList(e.target.value));
  // };

  const pricingAccess = ["devops@suncoastbuilt.com", "devin@suncoastbuilt.com"];

  const shouldShowPricingSection =
    userInfo?.email.length > 0 && pricingAccess.includes(userInfo.email);

  const shouldShowDataManagementSection =
    userInfo?.email.length > 0 && userInfo.email === "devops@suncoastbuilt.com";

  return (
    <ViewContainer maxWidth="lg">
      <Tabs value={value} onChange={handleChange} variant="scrollable">
        <Tab label="Dealer Orders" />
        {/* <Tab label="Dealer Tickets" /> */}
        {/* <Tab label='Dealer Warranties'/> */}
        <Tab label="Portal Users" />
        {shouldShowPricingSection && <Tab label="Pricing" />}
        {shouldShowDataManagementSection && <Tab label="Data Management" />}
      </Tabs>

      <TabPanel value={value} index={0}>
        {quoteLoading ? <Loader /> : <Orders type={"admin"} shipped={false} />}
      </TabPanel>

      {/* <TabPanel value={value} index={1}>
        {<Tickets admin />}
      </TabPanel> */}

      {/* <TabPanel value={value} index={2}>
        {<WarrantyList  type={"admin"} />}
       </TabPanel> */}

      <TabPanel value={value} index={1}>
        <Users />
      </TabPanel>

      {shouldShowPricingSection && (
        <TabPanel value={value} index={2}>
          <Pricing />
        </TabPanel>
      )}

      {shouldShowDataManagementSection && (
        <TabPanel value={value} index={3}>
          <Data />
        </TabPanel>
      )}
    </ViewContainer>
  );
};

export { AdminView };
