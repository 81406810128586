import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";

import { adminSavePortalPricing, adminUpdateBcCustomers } from "../../actions";

const CustomFormControl = withStyles((theme) => ({
  root: {
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(),
    marginRight: theme.spacing(),
    width: "99%",
  },
}))((props) => <FormControl {...props} />);

const Pricing = () => {
  const customers = useSelector((state) => state.adminBcCustomerList);
  const { bcCustomers } = customers;
  const adminPortalPricing = useSelector((state) => state.adminPortalPricing);
  const { portalPricings } = adminPortalPricing;
  const adminCustomerTiers = useSelector((state) => state.adminCustomerTiers);
  const { customerTiers } = adminCustomerTiers;
  const dispatch = useDispatch();

  const [portalPricingValue, setPortalPricingValue] = useState(0);
  const [portalPricingMethod, setPortalPricingMethod] = useState("");
  const [editedBcCustomers, setEditedBcCustomers] = useState(bcCustomers || []);
  const [editedBcCustomersTiers, setEditedBcCustomersTiers] = useState(
    customerTiers || []
  );

  useEffect(() => {
    setEditedBcCustomers(
      bcCustomers
        ?.filter((c) => c.bc_customer_displayName !== "unmatched")
        .sort((a, b) => {
          const nameA = a.bc_customer_displayName.toUpperCase();
          const nameB = b.bc_customer_displayName.toUpperCase();
          return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
        })
    );
    setEditedBcCustomersTiers(
      customerTiers?.sort((a, b) => b.customerTierRate - a.customerTierRate)
    );
  }, [bcCustomers, customerTiers]);

  const handlePortalPricingSave = (e, p) => {
    e.preventDefault();
    let pricing = {
      _id: p._id,
      portalPricingValue: !portalPricingValue
        ? p.portalPricingValue
        : portalPricingValue,
      portalPricingMethod: !portalPricingMethod
        ? p.portalPricingMethod
        : portalPricingMethod,
    };
    dispatch(adminSavePortalPricing(pricing));
  };

  const handleSaveBcCustomers = (e) => {
    e.preventDefault();
    // console.log('editedBcCustomers', editedBcCustomers);
    dispatch(adminUpdateBcCustomers(editedBcCustomers)).then(() => {
      // window.location.reload();
    });
  };

  const handleEditBcCustomer = (e, index, key, customerTierId) => {
    e.preventDefault();
    const updatedBcCustomers = [...editedBcCustomers];

    // Find the selected customerTier object
    const selectedCustomerTier = customerTiers.find(
      (tier) => tier._id === customerTierId
    );

    // Update the bcCustomer with the entire customerTier object
    if (selectedCustomerTier) {
      updatedBcCustomers[index][key] = selectedCustomerTier;
    }

    setEditedBcCustomers(updatedBcCustomers);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <h1>Pricing & Customer Tiers</h1>
      </Grid>
      <Grid item xs={6}>
        <h2>Portal Pricings</h2>
        <Grid container spacing={2}>
          {portalPricings?.length > 0 &&
            portalPricings.map((p, i) => (
              <Grid item xs={12} key={i}>
                <Box display="flex">
                  <Card variant="outlined" style={{ minWidth: "350px" }}>
                    <CardHeader title={p.portalPricingName} />
                    <CardContent>
                      <CustomFormControl required>
                        <TextField
                          type="number"
                          defaultValue={p.portalPricingValue}
                          InputLabelProps={{ shrink: true }}
                          label="Amount"
                          onFocus={(e) => e.target.select()}
                          onChange={(e) =>
                            setPortalPricingValue(e.target.value)
                          }
                        />
                      </CustomFormControl>
                      <CustomFormControl required>
                        <InputLabel shrink>Method</InputLabel>
                        <Select
                          required
                          defaultValue={p.portalPricingMethod}
                          onChange={(e) =>
                            setPortalPricingMethod(e.target.value)
                          }
                        >
                          {p.portalPricingMethods?.map((m, i) => (
                            <MenuItem
                              key={i}
                              value={m.portalPricingMethodValue}
                            >
                              {m.portalPricingMethodName}
                            </MenuItem>
                          ))}
                        </Select>
                      </CustomFormControl>
                    </CardContent>
                    <CardActions>
                      <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        onClick={(e) => handlePortalPricingSave(e, p)}
                        style={{ marginLeft: "auto" }}
                      >
                        Save
                      </Button>
                    </CardActions>
                  </Card>
                </Box>
              </Grid>
            ))}
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <h2>Customer Tiers</h2>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>BC Customer</TableCell>
                <TableCell>Customer Tier</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {editedBcCustomers?.length > 0 &&
                editedBcCustomers.map((c, i) => (
                  <TableRow key={i}>
                    <TableCell>{c.bc_customer_displayName}</TableCell>
                    <TableCell>
                      <CustomFormControl required>
                        <Select
                          name="customerTier"
                          required
                          value={c.customerTier?._id}
                          onChange={(e) =>
                            handleEditBcCustomer(
                              e,
                              i,
                              "customerTier",
                              e.target.value
                            )
                          }
                        >
                          {editedBcCustomersTiers?.map((t) => (
                            <MenuItem key={t?._id} value={t?._id}>
                              {t.customerTierName}
                            </MenuItem>
                          ))}
                        </Select>
                      </CustomFormControl>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box display="flex">
          <ButtonGroup
            style={{
              marginLeft: "auto",
              marginTop: 10,
            }}
          >
            <Button
              variant="contained"
              color="secondary"
              size="small"
              onClick={handleSaveBcCustomers}
            >
              Save BC Customers
            </Button>
          </ButtonGroup>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Pricing;
