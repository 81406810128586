import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";

import { clearCart, rejectOrder } from "../../../actions";
import { StoreContext } from "../../../context";

import {
  makeStyles,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(() => ({
  rejectBtn: {
    marginRight: "auto",
  },
}));

function RejectOrderDialog() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const ctx = useContext(StoreContext);
  const history = useHistory();

  const [open, setOpen] = useState(false);
  const [confirmYes, setConfirmYes] = useState("");
  const { cartInfo } = ctx;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setConfirmYes("");
    setOpen(false);
  };

  const handleReject = (e) => {
    e.preventDefault();
    if (confirmYes.toLowerCase() === "reject") {
      setOpen(false);
      dispatch(rejectOrder(cartInfo._id));
      dispatch(clearCart());
      history.push("/admin");
    }
  };

  return (
    <div>
      <Button
        variant="contained"
        color="secondary"
        size="small"
        onClick={handleClickOpen}
        className={classes.rejectBtn}
      >
        Reject Order
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="reject-dialog-title"
        style={{ minWidth: "450" }}
      >
        <DialogTitle id="reject-dialog-title">{`Reject order: ${cartInfo?.orderName}?`}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`To reject this order, please type REJECT into the field below and click REJECT.`}
          </DialogContentText>
          <TextField
            autoFocus
            value={confirmYes}
            margin="dense"
            id="name"
            label="Reject Order?"
            type="text"
            fullWidth
            onChange={(e) => setConfirmYes(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            variant="contained"
            color="secondary"
            size="small"
            className={classes.cancelBtn}
          >
            CANCEL
          </Button>
          <Button
            onClick={(e) => handleReject(e)}
            variant="contained"
            color="primary"
            size="small"
          >
            REJECT
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export { RejectOrderDialog };
