import { withStyles } from "@material-ui/core/styles";
import TableHead from "@material-ui/core/TableHead";

const StickyTableHead = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    position: "sticky",
    top: theme.spacing(8),
    zIndex: 1,
    boxShadow: `0 4px 4px -2px  rgba(0,0,0,0.2)`,
  },
}))(TableHead);

export { StickyTableHead };
