import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { logout } from "../actions";
import { StoreContextProvider } from "../context";
import { StoreTabs, Loader, Message, Cart } from "../components";

import { Grid } from "@material-ui/core";
import { ViewContainer } from "../components/ViewContainer";

const StoreView = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [tabValue, setTabValue] = useState(0);

  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, userInfo } = userLogin;

  function isTokenExpired(token) {
    const expiry = JSON.parse(Buffer.from(token.split(".")[1], "base64")).exp;
    return Math.floor(new Date().getTime() / 1000) >= expiry;
  }

  useEffect(() => {
    if (
      !userInfo ||
      isTokenExpired(userInfo?.token) ||
      !userInfo?.storeAccess
    ) {
      dispatch(logout());
      history.push("/login");
    }
  }, [history, userInfo, dispatch]);

  return (
    <ViewContainer maxWidth="xl">
      <StoreContextProvider>
        {loading ? (
          <Loader />
        ) : error ? (
          <Message severity="error">{error}</Message>
        ) : (
          <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
              <StoreTabs tabValue={tabValue} setTabValue={setTabValue} />
            </Grid>
            <Grid item xs={12} md={4}>
              <Cart setTabValue={setTabValue} />
            </Grid>
          </Grid>
        )}
      </StoreContextProvider>
    </ViewContainer>
  );
};

export { StoreView };
