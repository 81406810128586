import {
  WARRANTY_CREATE_FAIL,
  WARRANTY_CREATE_REQUEST,
  WARRANTY_CREATE_SUCCESS,
  // WARRANTY_DELETE_FAIL,
  // WARRANTY_DELETE_REQUEST,
  // WARRANTY_DELETE_SUCCESS,
  // WARRANTY_DETAILS_FAIL,
  // WARRANTY_DETAILS_REQUEST,
  // WARRANTY_DETAILS_SUCCESS,
  WARRANTY_LIST_MY_FAIL,
  WARRANTY_LIST_MY_REQUEST,
  WARRANTY_LIST_MY_RESET,
  WARRANTY_LIST_MY_SUCCESS,
} from '../constants';

export const warrantyCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case WARRANTY_CREATE_REQUEST:
      return {
        loading: true,
      };
    case WARRANTY_CREATE_SUCCESS:
      const { warranty, status, statusText } = action.payload;
      return {
        loading: false,
        warranty,
        status,
        statusText,
      };
    case WARRANTY_CREATE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
export const warrantyListMyReducer = (state = { warranties: [] }, action) => {
  switch (action.type) {
    case WARRANTY_LIST_MY_REQUEST:
      return {
        loading: true,
      };

    case WARRANTY_LIST_MY_SUCCESS:
      return {
        loading: false,
        warranties: action.payload,
      };
    case WARRANTY_LIST_MY_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case WARRANTY_LIST_MY_RESET:
      return {
        warranties: [],
      };

    default:
      return state;
  }
};