import React from "react";
import { withStyles, lighten } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

const CustomBox = (props) => {
  const { expanded, ...other } = props;
  return <Box {...other} />;
};

const CustomIcon = (props) => {
  const { expanded, ...other } = props;
  return <KeyboardArrowDownIcon {...other} />;
};

const StyledContainer = withStyles((theme) => ({
  root: (props) => ({
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    backgroundColor: props.expanded
      ? lighten(theme.palette.secondary.light, 0.55)
      : lighten(theme.palette.secondary.light, 0.85),
    borderRadius: 6,
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",
    transition: "background-color 0.3s",
    "&:hover": {
      backgroundColor: lighten(theme.palette.secondary.light, 0.55),
    },
  }),
}))(CustomBox);

const TitleBox = withStyles(() => ({
  root: {
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
  },
}))(Box);

const SquareBox = withStyles((theme) => ({
  root: {
    width: 32,
    height: 32,
    backgroundColor: theme.palette.secondary.main,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 6,
    marginRight: theme.spacing(2),
    flexShrink: 0,
  },
}))(Box);

const StyledSubcategoryIcon = withStyles((theme) => ({
  root: {
    color: theme.palette.common.white,
  },
}))(({ icon, classes }) =>
  React.cloneElement(icon, { className: classes.root })
);

const SummaryBox = withStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(2),
  },
}))(Box);

const StyledChevronIcon = withStyles((theme) => ({
  root: (props) => ({
    color: theme.palette.primary.main,
    fontSize: "1.5rem",
    transform: props.expanded ? "rotate(180deg)" : "rotate(0deg)",
    transition: "transform 0.3s",
  }),
}))(CustomIcon);

const ItemsSummaryTypography = withStyles((theme) => ({
  root: {
    display: "flex",
    gap: theme.spacing(1),
    flexShrink: 0,
  },
}))(({ classes, ...props }) => (
  <Typography className={classes.root} {...props} />
));

const CountLabelBadge = withStyles((theme) => ({
  root: {
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    fontSize: "inherit",
  },
}))(Box);

/**
 * SubcategoryHeadingBox component.
 *
 * This component renders a heading box for a subcategory in the library.
 * It displays the title of the subcategory, the number of videos, and a chevron icon that rotates based on the expanded state.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.collapsedIcon - The icon to display in the heading box when collapsed.
 * @param {React.ReactNode} props.expandedIcon - The icon to display in the heading box when expanded.
 * @param {boolean} props.filterApplied - Indicates whether the search filter is applied.
 * @param {boolean} props.expanded - Indicates whether the subcategory is expanded.
 * @param {function} props.onClick - The function to call when the heading box is clicked.
 * @param {string} props.title - The title of the subcategory.
 * @param {string} props.itemsCountLabel -The label for the type of items.
 * @param {number} props.itemsCount -The number of items.
 * @returns {JSX.Element} The rendered component.
 */
const SubcategoryHeadingBox = ({
  collapsedIcon,
  expanded,
  expandedIcon,
  filterApplied,
  itemsCount,
  itemsCountLabel,
  onClick,
  title,
}) => {
  return (
    <StyledContainer expanded={expanded} onClick={onClick}>
      <TitleBox>
        <SquareBox>
          {expanded ? (
            <StyledSubcategoryIcon icon={expandedIcon} />
          ) : (
            <StyledSubcategoryIcon icon={collapsedIcon} />
          )}
        </SquareBox>
        <Typography noWrap style={{ fontSize: "1.2rem", width: "100%" }}>
          {title}
        </Typography>
      </TitleBox>
      <SummaryBox>
        <ItemsSummaryTypography variant="body2" component="span">
          {filterApplied && itemsCount ? (
            <CountLabelBadge>{itemsCount}</CountLabelBadge>
          ) : (
            itemsCount
          )}{" "}
          {itemsCountLabel}
        </ItemsSummaryTypography>
        <StyledChevronIcon expanded={expanded} fontSize="small" />
      </SummaryBox>
    </StyledContainer>
  );
};

export default SubcategoryHeadingBox;
