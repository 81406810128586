import React from "react";
import { withStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

const StyledToolbarBox = withStyles((theme) => ({
  root: {
    display: "flex",
    flexFlow: "column",
    alignItems: "flex-end",
    flex: "1 1 auto",

    [theme.breakpoints.up("md")]: {
      flexFlow: "row",
      justifyContent: "space-between",
    },
  },
}))(Box);

const ExpandButtonsBox = withStyles((theme) => ({
  root: {
    display: "flex",
    flexFlow: "row",
    justifyContent: "flex-start",
    gap: theme.spacing(0.5),
    [theme.breakpoints.up("md")]: {
      justifyContent: "flex-end",
    },
  },
}))(Box);

const ExpandAllTypography = withStyles((theme) => ({
  root: {
    color: theme.palette.secondary.main,
    "&:hover": {
      textDecoration: "underline",
      cursor: "pointer",
    },
  },
}))(Typography);

const SearchBox = withStyles((theme) => ({
  root: {
    width: `calc(100vw - ${theme.spacing(8)}px)`,
    [theme.breakpoints.up("md")]: {
      width: theme.spacing(40),
    },
  },
}))(Box);

/**
 * ToolbarBox component renders a toolbar with expand/collapse buttons and a search box with autocomplete functionality.
 *
 * @param {Object} props - The properties object.
 * @param {Array<string>} props.autocompleteOptions - The options for the autocomplete input.
 * @param {Function} props.onCollapseAll - The function to call when the "Collapse all" button is clicked.
 * @param {Function} props.onExpandAll - The function to call when the "Expand all" button is clicked.
 * @param {Function} props.onSearchChange - The function to call when the search input value changes.
 * @param {string} [props.searchPlaceholder] - The placeholder text for the search input.
 */
const ToolbarBox = ({
  autocompleteOptions,
  onCollapseAll,
  onExpandAll,
  onSearchChange,
  searchPlaceholder,
}) => {
  return (
    <StyledToolbarBox>
      <SearchBox>
        <Autocomplete
          freeSolo
          options={autocompleteOptions}
          onInputChange={(event, value) => onSearchChange(value)}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={searchPlaceholder || "Search..."}
              margin="normal"
              variant="outlined"
              size="small"
            />
          )}
        />
      </SearchBox>

      <ExpandButtonsBox>
        <ExpandAllTypography onClick={onExpandAll} variant="caption">
          Expand all
        </ExpandAllTypography>
        |
        <ExpandAllTypography onClick={onCollapseAll} variant="caption">
          Collapse all
        </ExpandAllTypography>
      </ExpandButtonsBox>
    </StyledToolbarBox>
  );
};

export default ToolbarBox;
