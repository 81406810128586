// create order
export const ORDER_CREATE_REQUEST = 'ORDER_CREATE_REQUEST';
export const ORDER_CREATE_SUCCESS = 'ORDER_CREATE_SUCCESS';
export const ORDER_CREATE_FAIL = 'ORDER_CREATE_FAIL';

// order details
export const ORDER_DETAILS_REQUEST = 'ORDER_DETAILS_REQUEST';
export const ORDER_DETAILS_SUCCESS = 'ORDER_DETAILS_SUCCESS';
export const ORDER_DETAILS_FAIL = 'ORDER_DETAILS_FAIL';

// order details
export const ORDER_LIST_MY_REQUEST = 'ORDER_LIST_MY_REQUEST';
export const ORDER_LIST_MY_SUCCESS = 'ORDER_LIST_MY_SUCCESS';
export const ORDER_LIST_MY_FAIL = 'ORDER_LIST_MY_FAIL';
export const ORDER_LIST_MY_RESET = 'ORDER_LIST_MY_RESET';

export const ORDER_LIST_TEAM_REQUEST = 'ORDER_LIST_TEAM_REQUEST';
export const ORDER_LIST_TEAM_SUCCESS = 'ORDER_LIST_TEAM_SUCCESS';
export const ORDER_LIST_TEAM_FAIL = 'ORDER_LIST_TEAM_FAIL';

// order details
export const ORDER_DELETE_REQUEST = 'ORDER_DELETE_REQUEST';
export const ORDER_DELETE_SUCCESS = 'ORDER_DELETE_SUCCESS';
export const ORDER_DELETE_FAIL = 'ORDER_DELETE_FAIL';
export const ORDER_DELETE_RESET = 'ORDER_DELETE_RESET';

// order rejection
export const ORDER_REJECT_REQUEST = 'ORDER_REJECT_REQUEST';
export const ORDER_REJECT_SUCCESS = 'ORDER_REJECT_SUCCESS';
export const ORDER_REJECT_FAIL = 'ORDER_REJECT_FAIL';
