import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";

import { login } from "../../actions";

import { Message, Loader } from "../../components";

import {
  Button,
  CssBaseline,
  Grid,
  Link,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import AuthIcon from "./AuthIcon";
import { ViewContainer } from "../../components/ViewContainer";
import InfoAlert from "../../components/Alerts/InfoAlert";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(2, 0, 2),
  },
}));

const LoginView = ({ location, history }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [reset] = useState(location?.state?.reset);

  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, userInfo } = userLogin;

  const userRegister = useSelector((state) => state.userRegister);
  const { registerInfo } = userRegister;

  function isTokenExpired(token) {
    const expiry = JSON.parse(Buffer.from(token.split(".")[1], "base64")).exp;
    return Math.floor(new Date().getTime() / 1000) >= expiry;
  }

  useEffect(() => {
    if (userInfo && !isTokenExpired(userInfo?.token)) {
      history.push("/");
    }
  }, [history, userInfo]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(login(email, password));
  };

  return (
    <ViewContainer maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <AuthIcon />
        <Typography gutterBottom variant="h5">
          Sign in
        </Typography>
        {loading ? (
          <Loader />
        ) : (
          <>
            {error && <Message severity="error">{error}</Message>}

            {!error && registerInfo && (
              <InfoAlert severity="success">
                Thank you for registering! Your account request is pending
                approval. You will be notified by email when your account is
                ready.
              </InfoAlert>
            )}

            {reset && reset === "complete" && (
              <InfoAlert severity="success">
                Your password has been reset, please sign in below.
              </InfoAlert>
            )}

            <form className={classes.form} onSubmit={submitHandler}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Sign In
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link component={RouterLink} to="/reset">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link component={RouterLink} to="/register">
                    Don't have an account?
                  </Link>
                </Grid>
              </Grid>
            </form>
          </>
        )}
      </div>
    </ViewContainer>
  );
};

export { LoginView };
