import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Tab, Box, Tabs } from "@material-ui/core";
import queryString from "query-string";

import { logout } from "../actions";
import { Message, Loader } from "../components";
// import Tickets from "./Admin/Tickets";
import Orders from "./Admin/Orders";
import { ViewContainer } from "../components/ViewContainer";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function useQuery() {
  const location = useLocation();
  return queryString.parse(location.search);
}

const AccountView = ({ feedback }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery();

  const [value, setValue] = useState(0);

  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, userInfo } = userLogin;
  const bcSalesQuoteInit = useSelector((state) => state.bcSalesQuoteInit);
  const { loading: quoteLoading } = bcSalesQuoteInit;

  function isTokenExpired(token) {
    const expiry = JSON.parse(Buffer.from(token.split(".")[1], "base64")).exp;
    return Math.floor(new Date().getTime() / 1000) >= expiry;
  }

  const tickTab = query.tab === "tickets";

  useEffect(() => {
    if (!userInfo || isTokenExpired(userInfo?.token)) {
      dispatch(logout());
      history.push("/login");
    }
    if (tickTab) {
      handleChange(null, 2);
    }
  }, [history, tickTab, userInfo, dispatch]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <ViewContainer maxWidth="lg">
      {loading ? (
        <Loader />
      ) : error ? (
        <Message severity="error">{error}</Message>
      ) : (
        <div>
          <Tabs value={value} onChange={handleChange} variant="scrollable">
            {userInfo?.storeAccess && <Tab label="My Orders" />}
            {userInfo?.storeAccess && <Tab label="Team Orders" />}
            {/* <Tab label="My Tickets" /> */}
          </Tabs>

          {userInfo?.storeAccess && (
            <TabPanel value={value} index={0}>
              {quoteLoading ? <Loader /> : <Orders type={"myorders"} />}
            </TabPanel>
          )}

          {userInfo?.storeAccess && (
            <TabPanel value={value} index={1}>
              {quoteLoading ? <Loader /> : <Orders type={"teamorders"} />}
            </TabPanel>
          )}

          {/* <TabPanel value={value} index={userInfo?.storeAccess ? 2 : 0}>
            {loading ? <Loader /> : <Tickets userInfo={userInfo} />}
          </TabPanel> */}
        </div>
      )}
    </ViewContainer>
  );
};

export { AccountView };
