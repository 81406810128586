import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { adminListAllOrders } from "../actions";
import { Message, Loader } from ".";

import {
  Box,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  MenuItem,
  Paper,
  Select,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  makeStyles,
  withStyles,
} from "@material-ui/core";
import {
  AssignmentReturned,
  AssignmentTurnedIn,
  Clear,
  Language,
  LocalShipping,
  ArrowDownward,
  ArrowUpward,
  ThumbDownAlt,
} from "@material-ui/icons";

import { ReactComponent as CASvg } from "../assets/images/ca.svg";
import { ReactComponent as USSvg } from "../assets/images/us.svg";
import { ORDER_STATUS } from "./Orders/constants";
import { StickyTableHead } from "./Table/StickyTableHead";
import useDebounce from "../utils/hooks/useDebounce";
import OrderListItemAdmin from "./OrderListItemAdmin";
import useMobile from "../utils/hooks/useMobile";

const useStyles = makeStyles((theme) => ({
  sortedHeader: {
    fontSize: "1rem",
    verticalAlign: "middle",
    fontWeight: "bold",
    cursor: "pointer",
    userSelect: "none",
  },
  header: {
    fontSize: "1rem",
    verticalAlign: "middle",
    fontWeight: "bold",
    cursor: "pointer",
    userSelect: "none",
  },
  sortedColumn: {
    backgroundColor: "#f5f5f5",
  },
  column: {},
  sortIcon: {
    fontSize: "1rem",
    verticalAlign: "middle",
    marginLeft: "0.25rem",
  },
  expandIcon: {
    verticalAlign: "middle",
    cursor: "pointer",
  },
  textLink: {
    color: theme.palette.text.link,
    cursor: "pointer",
  },
}));

const MobileFiltersToolbar = withStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "end",
    position: "sticky",
    top: theme.spacing(7),
    backgroundColor: theme.palette.background.paper,
    zIndex: 1,
    padding: `0 ${theme.spacing(1)}px`,
    marginBottom: theme.spacing(1),
  },
}))(Box);

const DesktopFiltersToolbar = withStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "end",
    position: "sticky",
    top: theme.spacing(7),
    backgroundColor: theme.palette.background.paper,
    zIndex: 1,
    padding: `0 ${theme.spacing(1)}px`,
    marginBottom: theme.spacing(1),
  },
}))(Box);

const DesktopFilterSelectBox = withStyles((theme) => ({
  root: { display: "flex", alignItems: "center", gap: 5 },
}))(Box);

const CardedOrderList = ({ classes }) => {
  const dispatch = useDispatch();
  const isMobile = useMobile();

  const userLogin = useSelector((state) => state.userLogin);
  const { error, userInfo } = userLogin;

  const adminListOrders = useSelector((state) => state.adminListOrders);
  const { orders: adminOrders, count, loading } = adminListOrders;

  const [orderBy, setOrderBy] = useState("updatedAt");
  const [sortOrder, setSortOrder] = useState("desc");
  const [currentPage, setCurrentPage] = useState(0);
  const [dealerFilter, setDealerFilter] = useState("");
  const debouncedDealerFilter = useDebounce(dealerFilter, 1000);
  const [currencyFilter, setCurrencyFilter] = useState("ALL");
  const [status, setStatus] = useState("ALL");
  const [expandedCardId, setExpandedCardId] = useState(null);

  const itemsPerPage = 50;

  useEffect(() => {
    if (userInfo?.isAdmin) {
      dispatch(
        adminListAllOrders({
          filter: debouncedDealerFilter,
          limit: itemsPerPage,
          offset: itemsPerPage * currentPage,
          orderBy,
          sortOrder,
          status: status !== "ALL" ? status : "",
          currency: currencyFilter !== "ALL" ? currencyFilter : "",
        })
      );
    }
  }, [
    currencyFilter,
    currentPage,
    debouncedDealerFilter,
    dispatch,
    orderBy,
    sortOrder,
    status,
    userInfo?.isAdmin,
  ]);

  const handleExpandClick = (id) => {
    setExpandedCardId((prevId) => (prevId === id ? null : id));
  };

  const sortOrders = (field) => {
    if (orderBy === field) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setOrderBy(field);
      setSortOrder("asc");
    }
  };

  const getMobileContens = () => {
    let contents;

    if (loading) {
      contents = (
        <Box>
          <Loader />
        </Box>
      );
    } else if (adminOrders?.length > 0) {
      contents = adminOrders.map((order) => (
        <OrderListItemAdmin
          key={order._id}
          order={order}
          isExpanded={expandedCardId === order._id}
          onExpandClick={handleExpandClick}
          status={status}
          classes={classes}
        />
      ));
    } else {
      contents = (
        <Box>
          <Typography variant="h6" align="center">
            No orders to display.
          </Typography>
        </Box>
      );
    }

    return <Box>{contents}</Box>;
  };

  const getDesktopContents = () => {
    let tableBody;

    if (loading) {
      tableBody = (
        <TableRow>
          <TableCell colSpan={6}>
            <Loader />
          </TableCell>
        </TableRow>
      );
    } else if (adminOrders?.length > 0) {
      tableBody = adminOrders.map((order) => (
        <OrderListItemAdmin
          key={order._id}
          order={order}
          isExpanded={expandedCardId === order._id}
          onExpandClick={handleExpandClick}
          status={status}
          classes={classes}
        />
      ));
    } else if (!loading && adminOrders?.length === 0) {
      tableBody = (
        <TableRow>
          <TableCell colSpan={6}>
            <Typography variant="h6" align="center">
              No orders to display.
            </Typography>
          </TableCell>
        </TableRow>
      );
    }

    return (
      <Paper>
        <Table>
          <StickyTableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={orderBy === "createdAt"}
                  direction={orderBy === "createdAt" ? sortOrder : "asc"}
                  onClick={() => sortOrders("createdAt")}
                >
                  Created
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === "updatedAt"}
                  direction={orderBy === "updatedAt" ? sortOrder : "asc"}
                  onClick={() => sortOrders("updatedAt")}
                >
                  Updated
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === "userDetails.bc_customer_displayName"}
                  direction={
                    orderBy === "userDetails.bc_customer_displayName"
                      ? sortOrder
                      : "asc"
                  }
                  onClick={() =>
                    sortOrders("userDetails.bc_customer_displayName")
                  }
                >
                  Dealer / User
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === "orderName"}
                  direction={orderBy === "orderName" ? sortOrder : "asc"}
                  onClick={() => sortOrders("orderName")}
                >
                  Order
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === "status"}
                  direction={orderBy === "status" ? sortOrder : "asc"}
                  onClick={() => sortOrders("status")}
                >
                  Status
                </TableSortLabel>
              </TableCell>
              <TableCell align="right">
                <TableSortLabel
                  active={orderBy === "totalPrice"}
                  direction={orderBy === "totalPrice" ? sortOrder : "asc"}
                  onClick={() => sortOrders("totalPrice")}
                >
                  Total
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </StickyTableHead>
          <TableBody>{tableBody}</TableBody>
        </Table>
      </Paper>
    );
  };

  const filtersForMobile = (
    <MobileFiltersToolbar>
      <FormControl>
        <Select
          value={currencyFilter}
          onChange={(e) => {
            setCurrencyFilter(e.target.value);
          }}
          renderValue={(selected) => {
            if (selected === "USD") {
              return (
                <SvgIcon
                  component={USSvg}
                  viewBox="0 0 640 480"
                  fontSize="small"
                />
              );
            }
            if (selected === "CAD") {
              return (
                <SvgIcon
                  component={CASvg}
                  viewBox="0 0 640 480"
                  fontSize="small"
                />
              );
            }
            return <Language fontSize="small" />;
          }}
        >
          <ListSubheader>Order currency</ListSubheader>
          <MenuItem value="USD">
            <ListItemIcon>
              <SvgIcon
                component={USSvg}
                viewBox="0 0 640 480"
                fontSize="small"
              />
            </ListItemIcon>
            <ListItemText primary="USD" />
          </MenuItem>

          <MenuItem value="CAD">
            <ListItemIcon>
              <SvgIcon
                component={CASvg}
                viewBox="0 0 640 480"
                fontSize="small"
              />
            </ListItemIcon>
            <ListItemText primary="CAD" />
          </MenuItem>

          <MenuItem value="ALL">
            <ListItemIcon>
              <Language fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="All" />
          </MenuItem>
        </Select>
      </FormControl>
      <FormControl>
        <Select
          value={status}
          onChange={(e) => setStatus(e.target.value)}
          renderValue={(selected) => {
            if (selected === ORDER_STATUS.SUBMITTED) {
              return <AssignmentReturned fontSize="small" />;
            }
            if (selected === ORDER_STATUS.APPROVED) {
              return <AssignmentTurnedIn fontSize="small" />;
            }
            if (selected === "Shipped") {
              return <LocalShipping fontSize="small" />;
            }
            // Default case: "Submitted & Approved"
            return (
              <Box display="inline-flex">
                <AssignmentReturned fontSize="small" />
                <AssignmentTurnedIn fontSize="small" />
              </Box>
            );
          }}
        >
          <ListSubheader>Order status</ListSubheader>
          <MenuItem value={ORDER_STATUS.SUBMITTED}>
            <ListItemIcon>
              <AssignmentReturned fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={ORDER_STATUS.SUBMITTED} />
          </MenuItem>

          <MenuItem value={ORDER_STATUS.APPROVED}>
            <ListItemIcon>
              <AssignmentTurnedIn fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={ORDER_STATUS.APPROVED} />
          </MenuItem>

          <MenuItem value="ALL">
            <ListItemIcon>
              <Box display="inline-flex">
                <AssignmentReturned fontSize="small" />
                <AssignmentTurnedIn fontSize="small" />
              </Box>
            </ListItemIcon>
            <ListItemText primary="Submitted & Approved" />
          </MenuItem>

          <MenuItem value="Shipped">
            <ListItemIcon>
              <LocalShipping fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Shipped" />
          </MenuItem>

          <MenuItem value={ORDER_STATUS.REJECTED}>
            <ListItemIcon>
              <ThumbDownAlt fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={ORDER_STATUS.REJECTED} />
          </MenuItem>
        </Select>
      </FormControl>
      <Select
        value={sortOrder}
        onChange={(e) => setSortOrder(e.target.value)}
        renderValue={(selected) => {
          if (selected === "asc") {
            return <ArrowUpward fontSize="small" />;
          }
          return <ArrowDownward fontSize="small" />;
        }}
      >
        <ListSubheader>Sort direction</ListSubheader>
        <MenuItem value="asc">Sort ascending</MenuItem>
        <MenuItem value="desc">Sort descending</MenuItem>
      </Select>
      <Select value={orderBy} onChange={(e) => sortOrders(e.target.value)}>
        <ListSubheader>Sort by</ListSubheader>
        <MenuItem value="createdAt">created</MenuItem>
        <MenuItem value="updatedAt">updated</MenuItem>
        <MenuItem value="userDetails.bc_customer_displayName">dealer</MenuItem>
        <MenuItem value="orderName">order</MenuItem>
        <MenuItem value="status">status</MenuItem>
        <MenuItem value="totalPrice">total</MenuItem>
      </Select>
      <FormControl size="small">
        <InputLabel>Filter by dealer</InputLabel>
        <Input
          value={dealerFilter}
          onChange={(e) => setDealerFilter(e.target.value)}
          endAdornment={
            <InputAdornment position="end">
              {dealerFilter && (
                <IconButton
                  edge="end"
                  onClick={() => setDealerFilter("")}
                  size="small"
                >
                  <Clear fontSize="small" color="error" />
                </IconButton>
              )}
            </InputAdornment>
          }
        />
      </FormControl>
    </MobileFiltersToolbar>
  );

  const filtersForDesktop = (
    <DesktopFiltersToolbar>
      <FormControl>
        <Select
          value={currencyFilter}
          onChange={(e) => {
            setCurrencyFilter(e.target.value);
          }}
          renderValue={(selected) => {
            if (selected === "USD") {
              return (
                <DesktopFilterSelectBox>
                  <SvgIcon
                    component={USSvg}
                    viewBox="0 0 640 480"
                    fontSize="small"
                  />
                  <Typography>USD</Typography>
                </DesktopFilterSelectBox>
              );
            }
            if (selected === "CAD") {
              return (
                <DesktopFilterSelectBox>
                  <SvgIcon
                    component={CASvg}
                    viewBox="0 0 640 480"
                    fontSize="small"
                  />
                  <Typography>CAD</Typography>
                </DesktopFilterSelectBox>
              );
            }
            return (
              <DesktopFilterSelectBox>
                <Language fontSize="small" />
                <Typography>USD & CAD</Typography>
              </DesktopFilterSelectBox>
            );
          }}
        >
          <ListSubheader>Order currency</ListSubheader>
          <MenuItem value="USD">
            <ListItemIcon>
              <SvgIcon
                component={USSvg}
                viewBox="0 0 640 480"
                fontSize="small"
              />
            </ListItemIcon>
            <ListItemText primary="USD" />
          </MenuItem>

          <MenuItem value="CAD">
            <ListItemIcon>
              <SvgIcon
                component={CASvg}
                viewBox="0 0 640 480"
                fontSize="small"
              />
            </ListItemIcon>
            <ListItemText primary="CAD" />
          </MenuItem>

          <MenuItem value="ALL">
            <ListItemIcon>
              <Language fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="All" />
          </MenuItem>
        </Select>
      </FormControl>
      <FormControl>
        <Select
          value={status}
          onChange={(e) => setStatus(e.target.value)}
          renderValue={(selected) => {
            if (selected === ORDER_STATUS.SUBMITTED) {
              return (
                <DesktopFilterSelectBox>
                  <AssignmentReturned fontSize="small" />
                  <Typography>{ORDER_STATUS.SUBMITTED}</Typography>
                </DesktopFilterSelectBox>
              );
            }
            if (selected === ORDER_STATUS.APPROVED) {
              return (
                <DesktopFilterSelectBox>
                  <AssignmentTurnedIn fontSize="small" />
                  <Typography>{ORDER_STATUS.APPROVED}</Typography>
                </DesktopFilterSelectBox>
              );
            }
            if (selected === ORDER_STATUS.REJECTED) {
              return (
                <DesktopFilterSelectBox>
                  <AssignmentTurnedIn fontSize="small" />
                  <Typography>{ORDER_STATUS.REJECTED}</Typography>
                </DesktopFilterSelectBox>
              );
            }
            if (selected === "Shipped") {
              return (
                <DesktopFilterSelectBox>
                  <LocalShipping fontSize="small" />
                  <Typography>Shipped</Typography>
                </DesktopFilterSelectBox>
              );
            }
            // Default case: "Submitted & Approved"
            return (
              <DesktopFilterSelectBox>
                <AssignmentReturned fontSize="small" />
                <AssignmentTurnedIn fontSize="small" />
                <Typography>Submitted & Approved</Typography>
              </DesktopFilterSelectBox>
            );
          }}
        >
          <ListSubheader>Order status</ListSubheader>
          <MenuItem value={ORDER_STATUS.SUBMITTED}>
            <ListItemIcon>
              <AssignmentReturned fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={ORDER_STATUS.SUBMITTED} />
          </MenuItem>

          <MenuItem value={ORDER_STATUS.APPROVED}>
            <ListItemIcon>
              <AssignmentTurnedIn fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={ORDER_STATUS.APPROVED} />
          </MenuItem>

          <MenuItem value="ALL">
            <ListItemIcon>
              <Box display="inline-flex">
                <AssignmentReturned fontSize="small" />
                <AssignmentTurnedIn fontSize="small" />
              </Box>
            </ListItemIcon>
            <ListItemText primary="Submitted & Approved" />
          </MenuItem>

          <MenuItem value="Shipped">
            <ListItemIcon>
              <LocalShipping fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Shipped" />
          </MenuItem>

          <MenuItem value={ORDER_STATUS.REJECTED}>
            <ListItemIcon>
              <ThumbDownAlt fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={ORDER_STATUS.REJECTED} />
          </MenuItem>
        </Select>
      </FormControl>
      <FormControl size="small">
        <InputLabel>Filter by dealer</InputLabel>
        <Input
          value={dealerFilter}
          onChange={(e) => setDealerFilter(e.target.value)}
          endAdornment={
            <InputAdornment position="end">
              {dealerFilter && (
                <IconButton
                  edge="end"
                  onClick={() => setDealerFilter("")}
                  size="small"
                >
                  <Clear fontSize="small" color="error" />
                </IconButton>
              )}
            </InputAdornment>
          }
        />
      </FormControl>
    </DesktopFiltersToolbar>
  );

  if (error) {
    return <Message severity="error">Could not fetch orders. {error}</Message>;
  }

  return (
    <>
      {isMobile ? filtersForMobile : filtersForDesktop}

      {isMobile ? getMobileContens() : getDesktopContents()}

      <TablePagination
        component="div"
        count={count ? count : 0}
        page={currentPage}
        onPageChange={(e, newPage) => {
          setCurrentPage(newPage);
        }}
        rowsPerPage={itemsPerPage}
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        labelRowsPerPage=""
      />
    </>
  );
};

const OrderListAdmin = ({ shipped }) => {
  const userLogin = useSelector((state) => state.userLogin);
  const { error, userInfo } = userLogin;
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    if (!userInfo) {
      history.push("/login");
    }
  }, [history, userInfo]);

  return error ? (
    <Message severity="error">{error}</Message>
  ) : (
    <CardedOrderList classes={classes} shipped={shipped} />
  );
};

export { OrderListAdmin };
