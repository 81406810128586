import React from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  EditEntityDialogTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(2),
  },
  EditEntityDialogSubtitle: {
    minWidth: "1px",
    width: "100%",
  },
  EditEntityDialogToolbarActions: {
    display: "flex",
    flexFlow: "row noWrap",
    color: theme.palette.grey[500],
  },
  EditEntityDialogCloseBtn: {
    color: theme.palette.grey[500],
  },
  EditEntityDialogContent: {
    padding: theme.spacing(2),
  },
  EditEntityDialogActions: {
    margin: 0,
    padding: `${theme.spacing(1)}px`,
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "space-between",
  },
  EditEntityDialogSectionDivider: {
    marginLeft: `-${theme.spacing(2)}px`,
    marginRight: `-${theme.spacing(2)}px`,
    marginTop: `${theme.spacing(4)}px`,
    marginBottom: `${theme.spacing(3)}px`,
  },
}));

/**
 * Renders a generic confirmation dialog.
 *
 * @component
 * @param {Object} props - Component props.
 * @param {React.ReactNode} [props.children] - Dialog content.
 * @param {boolean} [props.confirmButtonDisabled] - Disable the confirm button.
 * @param {string} [props.confirmButtonText] - Text for the confirm button.
 * @param {boolean} [props.discardButtonDisabled] - Disable the discard button.
 * @param {string} [props.discardButtonText] - Text for the discard button.
 * @param {function} [props.onConfirm] - Callback function when confirming.
 * @param {function} [props.onDiscard] - Callback function when discarding.
 * @param {boolean} props.isOpen - Whether the dialog is open.
 * @param {string} [props.subtitle] - Optional subtitle for the dialog.
 * @param {string} [props.text] - Text content to display, alternative to children.
 * @param {string} props.title - Title of the dialog.
 * @param {...Object} otherMuiProps - Additional MaterialUI Dialog properties.
 */
const ConfirmationDialog = (props) => {
  const {
    children,
    confirmButtonDisabled,
    confirmButtonText,
    discardButtonDisabled,
    discardButtonText,
    onConfirm,
    onDiscard,
    isOpen,
    subtitle,
    text,
    title,
    ...otherMuiProps
  } = props;
  const classes = useStyles();

  const handleConfirm = (event) => {
    onConfirm(event);
  };

  const handleDiscard = (event) => {
    onDiscard(event);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleDiscard}
      onClick={(event) => {
        event.stopPropagation();
      }}
      {...otherMuiProps}
    >
      <DialogTitle className={classes.EditEntityDialogTitle} disableTypography>
        <div>
          <Typography variant="h6" noWrap>
            {title}
          </Typography>
          {subtitle && (
            <Typography variant="body1" noWrap>
              {subtitle}
            </Typography>
          )}
        </div>
      </DialogTitle>
      <DialogContent>
        {text ? <DialogContentText>{text}</DialogContentText> : children}
      </DialogContent>
      <DialogActions>
        {onDiscard && (
          <Button
            disabled={discardButtonDisabled}
            onClick={handleDiscard}
            color="default"
            title="Cancel this action"
          >
            {discardButtonText || "Cancel"}
          </Button>
        )}
        {onConfirm && (
          <Button
            disabled={confirmButtonDisabled}
            onClick={handleConfirm}
            color="secondary"
            title="Confirm this action"
            variant="contained"
          >
            {confirmButtonText || "OK"}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export { ConfirmationDialog };
