import { useEffect, useState } from "react";
import { useTheme } from "@material-ui/core";

function useMobile() {
  const theme = useTheme();
  const [isMobile, setIsMobile] = useState(
    window.innerWidth <= theme.breakpoints.values.md
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= theme.breakpoints.values.md);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [theme.breakpoints.values.md]);

  return isMobile;
}

export default useMobile;
