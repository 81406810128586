import api from "../api";

export const generateOrderId = async (token) => {
  try {
    const result = await api.get("/api/orders/generateOrderId");
    return result.data._id;
  } catch (error) {
    console.error("Error generating order ID:", error);
    throw error;
  }
};
