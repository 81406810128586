import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, Link as RouterLink } from "react-router-dom";
import { format } from "date-fns";
import "../assets/css/account.css";
import { WarrantyDialog } from "./WarrantyDialog";

import { listMyOrders, listTeamOrders } from "../actions";
import { numberFormat } from "../utils/numberFormatter";

import { Message, Loader } from ".";

import {
  Box,
  Collapse,
  IconButton,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
} from "@material-ui/core";

import {
  ArrowDownward,
  ArrowUpward,
  Edit,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Pageview,
} from "@material-ui/icons";
import { ORDER_STATUS } from "./Orders/constants";

const useStyles = makeStyles((theme) => ({
  sortedHeader: {
    fontSize: "1rem",
    verticalAlign: "middle",
    fontWeight: "bold",
    cursor: "pointer",
    userSelect: "none",
  },
  header: {
    fontSize: "1rem",
    verticalAlign: "middle",
    fontWeight: "bold",
    cursor: "pointer",
    userSelect: "none",
  },
  sortedColumn: {
    backgroundColor: "#f5f5f5",
  },
  column: {},
  sortIcon: {
    fontSize: "1rem",
    verticalAlign: "middle",
    marginLeft: "0.25rem",
  },
}));

const OrderListAccount = ({ type }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { error, userInfo } = userLogin;
  const orderListMy = useSelector((state) =>
    type === "myorders" ? state.orderListMy : state.orderListTeam
  );
  const { orders: myOrders, loading } = orderListMy;

  const [sortedField, setSortedField] = useState("updatedAt");
  const [sortDirection, setSortDirection] = useState("desc");
  const [page, setPage] = useState(0);
  const [openRow, setOpenRow] = useState(null);

  const handleRowClick = (index) => {
    setOpenRow(openRow === index ? null : index);
  };

  const updateOrders = (offset) => {
    if (type === "myorders") {
      dispatch(listMyOrders(offset));
    } else {
      dispatch(listTeamOrders(offset));
    }
  };

  useEffect(() => {
    if (!userInfo) {
      history.push("/login");
    }
    updateOrders(0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, userInfo, dispatch]);

  const sortOrders = (field) => {
    if (sortedField === field) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortedField(field);
      setSortDirection("asc");
    }
  };
  const handleChangePage = (event, newPage) => {
    let offset = 20 * newPage;
    updateOrders(offset);
    setPage(newPage);
  };
  const orders = myOrders?.orders
    ? myOrders?.orders.sort((a, b) => {
        // if (sortedField === null) return a.updatedAt > b.updatedAt ? -1 : 1;
        let result;
        if (a[sortedField] > b[sortedField]) result = 1;
        if (a[sortedField] < b[sortedField]) result = -1;
        if (sortDirection === "desc") result *= -1;
        return result;
      })
    : [];

  return loading ? (
    <Loader />
  ) : error ? (
    <Message severity="error">{error}</Message>
  ) : (
    <Paper>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell
                align="left"
                className={
                  sortedField === "createdAt"
                    ? classes.sortedHeader
                    : classes.header
                }
                onClick={() => sortOrders("createdAt")}
              >
                Date Placed
                {sortedField === "createdAt" &&
                  (sortDirection === "asc" ? (
                    <ArrowUpward color="action" className={classes.sortIcon} />
                  ) : (
                    <ArrowDownward
                      color="action"
                      className={classes.sortIcon}
                    />
                  ))}
              </TableCell>
              <TableCell
                className={
                  sortedField === "orderName"
                    ? classes.sortedHeader
                    : classes.header
                }
                onClick={() => sortOrders("orderName")}
              >
                Order Name
                {sortedField === "orderName" &&
                  (sortDirection === "asc" ? (
                    <ArrowUpward color="action" className={classes.sortIcon} />
                  ) : (
                    <ArrowDownward
                      color="action"
                      className={classes.sortIcon}
                    />
                  ))}
              </TableCell>
              <TableCell
                className={
                  sortedField === "orderNumber"
                    ? classes.sortedHeader
                    : classes.header
                }
                onClick={() => sortOrders("orderNumber")}
              >
                Order Number
                {sortedField === "orderNumber" &&
                  (sortDirection === "asc" ? (
                    <ArrowUpward color="action" className={classes.sortIcon} />
                  ) : (
                    <ArrowDownward
                      color="action"
                      className={classes.sortIcon}
                    />
                  ))}
              </TableCell>
              <TableCell
                align="right"
                className={
                  sortedField === "totalPrice"
                    ? classes.sortedHeader
                    : classes.header
                }
                onClick={() => sortOrders("totalPrice")}
              >
                Total Price
                {sortedField === "totalPrice" &&
                  (sortDirection === "asc" ? (
                    <ArrowUpward color="action" className={classes.sortIcon} />
                  ) : (
                    <ArrowDownward
                      color="action"
                      className={classes.sortIcon}
                    />
                  ))}
              </TableCell>
              <TableCell
                align="left"
                className={
                  sortedField === "updatedAt"
                    ? classes.sortedHeader
                    : classes.header
                }
                onClick={() => sortOrders("updatedAt")}
              >
                Date Updated
                {sortedField === "updatedAt" &&
                  (sortDirection === "asc" ? (
                    <ArrowUpward color="action" className={classes.sortIcon} />
                  ) : (
                    <ArrowDownward
                      color="action"
                      className={classes.sortIcon}
                    />
                  ))}
              </TableCell>
              <TableCell
                className={
                  sortedField === "status"
                    ? classes.sortedHeader
                    : classes.header
                }
                onClick={() => sortOrders("status")}
              >
                Status
                {sortedField === "status" &&
                  (sortDirection === "asc" ? (
                    <ArrowUpward color="action" className={classes.sortIcon} />
                  ) : (
                    <ArrowDownward
                      color="action"
                      className={classes.sortIcon}
                    />
                  ))}
              </TableCell>
              <TableCell colSpan={2}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders && orders.length > 0 ? (
              orders.map((order, index) => (
                <React.Fragment key={order._id}>
                  <TableRow onClick={() => handleRowClick(index)}>
                    <TableCell>
                      <IconButton aria-label="expand row" size="small">
                        {openRow === index ? (
                          <KeyboardArrowUp />
                        ) : (
                          <KeyboardArrowDown />
                        )}
                      </IconButton>
                    </TableCell>
                    <TableCell
                      align="left"
                      className={
                        sortedField === "createdAt"
                          ? classes.sortedColumn
                          : classes.column
                      }
                    >
                      {format(new Date(order.createdAt), "MMM dd, yyyy")}
                    </TableCell>
                    <TableCell
                      className={
                        sortedField === "orderName"
                          ? classes.sortedColumn
                          : classes.column
                      }
                    >
                      {order.orderName}
                    </TableCell>
                    <TableCell
                      className={
                        sortedField === "orderNumber"
                          ? classes.sortedColumn
                          : classes.column
                      }
                    >
                      {order.orderNumber}
                    </TableCell>
                    <TableCell
                      align="right"
                      className={
                        sortedField === "totalPrice"
                          ? classes.sortedColumn
                          : classes.column
                      }
                    >
                      {numberFormat("cur-display", order.totalPrice)}
                    </TableCell>
                    <TableCell
                      align="left"
                      className={
                        sortedField === "updatedAt"
                          ? classes.sortedColumn
                          : classes.column
                      }
                    >
                      {format(new Date(order.updatedAt), "MMM dd, yyyy")}
                    </TableCell>
                    <TableCell
                      className={
                        sortedField === "status"
                          ? classes.sortedColumn
                          : classes.column
                      }
                    >
                      {order.status}
                    </TableCell>
                    <TableCell>
                      {order.status === ORDER_STATUS.DRAFT ? (
                        <Link
                          size="small"
                          color="textSecondary"
                          component={RouterLink}
                          variant="body2"
                          to={{
                            pathname: "/store",
                            search: `order=${order._id}`,
                          }}
                        >
                          <Edit fontSize="small" />
                        </Link>
                      ) : (
                        <Link
                          size="small"
                          color="textSecondary"
                          component={RouterLink}
                          variant="body2"
                          to={{
                            pathname: "/store",
                            search: `order=${order._id}`,
                          }}
                        >
                          <Pageview fontSize="small" />
                        </Link>
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                      colSpan={9}
                    >
                      <Collapse
                        in={openRow === index}
                        timeout="auto"
                        unmountOnExit
                      >
                        <Box margin={1}>
                          <div className="collapsible">
                            <div className="orderItemList">
                              <div className="orderItems">
                                <Typography
                                  variant="h6"
                                  gutterBottom
                                  component="div"
                                >
                                  Roofs
                                </Typography>
                                {order.roofs.length > 0 ? (
                                  <ul className="orderBreakdown">
                                    {order.roofs.map((item, idx) => (
                                      <li key={idx}>{item.roofName}</li>
                                    ))}
                                  </ul>
                                ) : (
                                  "No Roofs"
                                )}
                              </div>
                              <div className="orderItems">
                                <Typography
                                  variant="h6"
                                  gutterBottom
                                  component="div"
                                >
                                  Screens
                                </Typography>
                                {order.screens.length > 0 ? (
                                  <ul className="orderBreakdown">
                                    {order.screens.map((item, idx) => (
                                      <li key={idx}>{item.screenName}</li>
                                    ))}
                                  </ul>
                                ) : (
                                  "No Screens"
                                )}
                              </div>
                              <div className="orderItems">
                                <Typography
                                  variant="h6"
                                  gutterBottom
                                  component="div"
                                >
                                  Parts
                                </Typography>
                                {order.parts.length > 0 ? (
                                  <ul className="orderBreakdown">
                                    {order.parts.map((item, idx) => (
                                      <li key={idx}>{item.displayName}</li>
                                    ))}
                                  </ul>
                                ) : (
                                  "No Parts"
                                )}
                              </div>
                            </div>
                            <WarrantyDialog mode="new" order={order} />
                          </div>
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  <h3>No orders to display.</h3>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <Box display="flex">
          <Typography
            variant="caption"
            style={{
              marginLeft: "auto",
              marginRight: 5,
              marginTop: 5,
              marginBottom: 5,
            }}
            color="error"
            align="right"
          >
            *Only Draft or Rejected orders can be deleted.
          </Typography>
        </Box>
      </TableContainer>
      <TablePagination
        component="div"
        count={myOrders.count ? myOrders.count : 0}
        page={page}
        rowsPerPage={20}
        labelRowsPerPage=""
        onPageChange={handleChangePage}
      />
    </Paper>
  );
};

export { OrderListAccount };
