import api from "../api";

import {
  DOC_LIST_REQUEST,
  DOC_LIST_SUCCESS,
  DOC_LIST_FAIL,
  DOC_ROOF_DOWNLOAD_REQUEST,
  DOC_ROOF_DOWNLOAD_SUCCESS,
  DOC_ROOF_DOWNLOAD_FAIL,
  DOC_UPLOAD_REQUEST,
  DOC_UPLOAD_SUCCESS,
  DOC_UPLOAD_FAIL,
} from "../constants";

export const listDocs = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: DOC_LIST_REQUEST,
    });

    const { data } = await api.get("/api/docs");

    let links = [];

    links = await Promise.all(
      data?.map((doc) =>
        api.post("/api/docs/dl", { key: doc.Key }).then((res) => {
          return {
            ...doc,
            DownloadUrl: res.data,
          };
        })
      )
    );

    dispatch({
      type: DOC_LIST_SUCCESS,
      payload: links,
    });
  } catch (err) {
    dispatch({
      type: DOC_LIST_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

// function encodeUriAll(value) {
//   return value.replace(
//     /[^A-Za-z0-9]/g,
//     (c) => `%${c.charCodeAt(0).toString(16).toUpperCase()}`
//   );
// }

export const docRoofLink =
  (customer, ordername, roofname, filename) => async (dispatch, getState) => {
    try {
      dispatch({
        type: DOC_ROOF_DOWNLOAD_REQUEST,
      });

      const key = `${customer}/${ordername}/${roofname}/${filename}`;

      let link = await api.post("/api/docs/dl", {
        bucket: "roof",
        key: key,
      });

      dispatch({
        type: DOC_ROOF_DOWNLOAD_SUCCESS,
        payload: link.data,
      });
    } catch (err) {
      dispatch({
        type: DOC_ROOF_DOWNLOAD_FAIL,
        payload:
          err.response && err.response.data.message
            ? err.response.data.message
            : err.message,
      });
    }
  };

export const uploadDoc = (formData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: DOC_UPLOAD_REQUEST,
    });

    const { data } = await api.post("/api/docs", formData, {
      "Content-Type": "multipart/form-data",
    });

    dispatch({
      type: DOC_UPLOAD_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: DOC_UPLOAD_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};
