import React, { useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import {
  Box,
  Button,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  makeStyles,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { withStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  navBtn: {
    marginRight: theme.spacing(1),
  },
  menuButton: {
    marginRight: theme.spacing(2),
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      marginRight: "0px",
    },
  },
  toolBar: {
    [theme.breakpoints.down("sm")]: {
      paddingRight: "0px",
      paddingLeft: "0px",
    },
  },
}));

const NavItemsBox = withStyles((theme) => ({
  root: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      gap: "1rem",
      flexGrow: 1,
    },
  },
}))(Box);

const NavItemButton = withStyles((theme) => ({
  root: (props) => ({
    borderRadius: 0,
    borderBottom: props.isActive
      ? `solid 2px ${theme.palette.secondary.main}`
      : "solid 2px transparent",
    fontWeight: props.isActive ? 500 : "normal",
  }),
}))(({ isActive, ...props }) => <Button {...props} />);

const Navbar = ({ userInfo }) => {
  const location = useLocation();
  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const handleItemClick = () => {
    setDrawerOpen(false);
  };

  const navItems = (
    <>
      {userInfo && userInfo.storeAccess && (
        <ListItem
          button
          component={RouterLink}
          to="/store"
          onClick={handleItemClick}
        >
          <ListItemText primary="Store" />
        </ListItem>
      )}
      <ListItem
        button
        component={RouterLink}
        to="/docs"
        onClick={handleItemClick}
      >
        <ListItemText primary="Library" />
      </ListItem>
      <ListItem
        button
        component={RouterLink}
        to="/account"
        onClick={handleItemClick}
      >
        <ListItemText primary="Account" />
      </ListItem>
      {userInfo && userInfo.isAdmin && (
        <ListItem
          button
          component={RouterLink}
          to="/admin"
          onClick={handleItemClick}
        >
          <ListItemText primary="Admin" />
        </ListItem>
      )}
    </>
  );

  return (
    <Toolbar className={classes.toolBar}>
      <IconButton
        edge="start"
        color="inherit"
        aria-label="menu"
        className={classes.menuButton}
        onClick={toggleDrawer(true)}
      >
        <MenuIcon />
      </IconButton>

      {/* Desktop Buttons */}
      <NavItemsBox>
        {userInfo && userInfo.storeAccess && (
          <NavItemButton
            isActive={location.pathname === "/store"}
            color="primary"
            component={RouterLink}
            size="small"
            to="/store"
            variant="contained"
          >
            STORE
          </NavItemButton>
        )}

        <NavItemButton
          isActive={location.pathname === "/docs"}
          color="primary"
          component={RouterLink}
          size="small"
          to="/docs"
          variant="contained"
        >
          Library
        </NavItemButton>
        <NavItemButton
          isActive={location.pathname === "/account"}
          color="primary"
          component={RouterLink}
          size="small"
          to="/account"
          variant="contained"
        >
          Account
        </NavItemButton>

        {userInfo && userInfo.isAdmin && (
          <NavItemButton
            isActive={location.pathname === "/admin"}
            color="secondary"
            component={RouterLink}
            size="small"
            to="/admin"
            variant="text"
          >
            Admin
          </NavItemButton>
        )}
      </NavItemsBox>

      {/* Drawer for Mobile */}
      <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
        <List>{navItems}</List>
      </Drawer>
    </Toolbar>
  );
};

export { Navbar };
