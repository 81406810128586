import React, { useContext, useState, useEffect } from "react";

import { StoreContext } from "../context";
import { Loader, Message, DropDirection } from "../components";
import { InfoDialog } from ".";

import {
  makeStyles,
  TextField,
  Button,
  Grid,
  Typography,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Tabs,
  Tab,
  Box,
} from "@material-ui/core";
import { ORDER_STATUS } from "./Orders/constants";
import InfoTooltip from "./Tooltips/InfoTooltip";
import UnorderedList from "./Lists/UnorderedList";
import UnorderedListItem from "./Lists/UnorderedListItem";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%",
  },
  submit: {
    marginTop: theme.spacing(1),
  },
  marg: {
    margin: theme.spacing(1),
  },
  formControl: {
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(),
    marginRight: theme.spacing(),
    width: "99%",
  },
  mountingAngle: {
    margin: theme.spacing(1),
  },
  btns: {
    display: "flex",
    flexDirection: "row",
  },
  splitCol: {
    display: "flex",
  },
  screenTabs: {
    fontSize: "18px",
    paddingRight: "40px",
    paddingLeft: "40px",
  },
  screenSideIndicator: {
    maxWidth: "75%",
  },
  headerBox: {
    display: "flex",
    alignItems: "center",
    flexWrap: "nowrap",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  headerBoxText: {
    fontSize: "18px",
  },
}));

const DynamicAttributes = ({ kitAttributes, scr, setScr, xsSize }) => {
  const renderKitAttributes = (kitAttributes) => {
    return kitAttributes
      ?.filter((attr) => attr.attributeName.startsWith("Kit-"))
      ?.map((attr) => {
        const truncatedName = attr.attributeName.replace("Kit-", "");
        return (
          <Grid item xs={xsSize ? xsSize : 12} sm={4} key={truncatedName}>
            <FormControl fullWidth>
              <InputLabel shrink>{truncatedName}</InputLabel>
              <Select
                value={scr.options[truncatedName] || ""}
                fullWidth
                required
                onChange={(e) =>
                  setScr({
                    ...scr,
                    options: {
                      ...scr.options,
                      [truncatedName]: e.target.value,
                    },
                  })
                }
              >
                {attr.attributeValues.map((value) => (
                  <MenuItem key={value} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        );
      });
  };

  return <>{renderKitAttributes(kitAttributes)}</>;
};

const ScreenMaterial = ({
  kitAttributes,
  scr,
  setScr,
  materialType,
  setMaterialType,
}) => {
  const renderKitAttributes = (kitAttributes) => {
    return kitAttributes
      ?.filter((attr) =>
        attr?.attributeName.startsWith(`Kit-${scr.options.materialType}`)
      )
      ?.filter((attr) => attr?.attributeName !== "Kit-Material Type")
      ?.map((attr) => {
        const truncatedName = attr?.attributeName?.replace("Kit-", "");
        if (truncatedName.toLowerCase().includes("color")) {
          attr?.attributeValues.sort((a, b) => {
            const hasBlackA = a.toLowerCase().includes("black") ? 0 : 1;
            const hasBlackB = b.toLowerCase().includes("black") ? 0 : 1;

            return hasBlackA - hasBlackB;
          });
        }

        return (
          <Grid item xs={12} sm={4} key={truncatedName}>
            <InputLabel shrink>{truncatedName}</InputLabel>
            <Select
              value={scr?.options[truncatedName] || ""}
              fullWidth
              onChange={(e) => {
                setScr({
                  ...scr,
                  options: {
                    ...scr?.options,
                    [truncatedName]: e.target.value,
                  },
                });
              }}
              required
            >
              {attr?.attributeValues?.map((value) => (
                <MenuItem key={value} value={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        );
      });
  };

  return (
    <>
      <Grid item xs={12} sm={4}>
        <FormControl fullWidth>
          <InputLabel shrink>Material Type</InputLabel>
          <Select
            value={scr?.options?.materialType || ""}
            required
            placeholder="Select an option"
            fullWidth
            onChange={(e) => {
              for (const key in scr.options) {
                if (key.includes(scr.options.materialType)) {
                  delete scr.options[key];
                }
              }
              setMaterialType(e.target.value);
              setScr({
                ...scr,
                options: {
                  ...scr?.options,
                  materialType: e.target.value,
                },
              });
            }}
          >
            {kitAttributes
              ?.filter((k) => k?.attributeName === "Kit-Material Type")
              .flatMap((k) => k?.attributeValues)
              .map((value) => (
                <MenuItem key={value} value={value}>
                  {value}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid>
      {renderKitAttributes(kitAttributes)}
    </>
  );
};

const ScreenForm = ({ order, status }) => {
  const classes = useStyles();
  const ctx = useContext(StoreContext);

  const [materialType, setMaterialType] = useState("");
  const [motorTypeDisplay, setMotorTypeDisplay] = useState({});
  const [jobColorSize, setjobColorSize] = useState(4);
  const { portalScreenKits, scr, setScr } = ctx || {};
  const { Bar, Material, Motor } =
    portalScreenKits !== undefined && portalScreenKits;
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    if (Motor) {
      updateAttribute(
        ctx.scr.measurements.width,
        ctx.scr.measurements.dropLeft,
        ctx.scr.measurements.dropRight
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    Motor,
    ctx.scr.measurements.width,
    ctx.scr.measurements.dropLeft,
    ctx.scr.measurements.dropRight,
  ]);

  function updateAttribute(width, dropLeft, dropRight) {
    let newMotorDisplay = [
      "Standard - 21 RPM",
      "Fast - 34 RPM",
      "HD - 21 RPM",
      "Super HD - 16 RPM",
    ];
    if (width >= 120 && width < 240 && dropLeft < 132 && dropRight < 132) {
      newMotorDisplay = [
        "Standard - 21 RPM",
        "HD - 21 RPM",
        "Super HD - 16 RPM",
      ];
    } else if (
      (width >= 240 && width < 300) ||
      (width < 300 && (dropLeft >= 132 || dropRight >= 132))
    ) {
      newMotorDisplay = ["HD - 21 RPM", "Super HD - 16 RPM"];
    } else if (width >= 300) {
      newMotorDisplay = ["Super HD - 16 RPM"];
    }
    if (!newMotorDisplay.includes(ctx.scr.options["Motor Type"])) {
      setScr({
        ...ctx.scr,
        options: {
          ...ctx.scr.options,
          "Motor Type": "",
        },
      });
    }
    setMotorTypeDisplay({
      displayName2: Motor.displayName2,
      itemCategoryCode: Motor.itemCategoryCode,
      attributes: Motor.attributes.map((item) => {
        if (item.attributeName === "Kit-Motor Type") {
          return {
            ...item,
            attributeValues: newMotorDisplay,
          };
        }
        return item;
      }),
    });
  }
  function resetMotor() {
    setMotorTypeDisplay({
      displayName2: motorTypeDisplay.displayName2,
      itemCategoryCode: motorTypeDisplay.itemCategoryCode,
      attributes: motorTypeDisplay.attributes.map((item) => {
        if (item.attributeName === "Kit-Motor Type") {
          return {
            ...item,
            attributeValues: [
              "Standard - 21 RPM",
              "Fast - 34 RPM",
              "HD - 21 RPM",
              "Super HD - 16 RPM",
            ],
          };
        }
        return item;
      }),
    });
  }

  const setHousing = (field, value) => {
    const width = field === "width" ? value : ctx.scr.measurements.width;
    const dropLeft =
      field === "dropLeft" ? value : ctx.scr.measurements.dropLeft;
    const dropRight =
      field === "dropRight" ? value : ctx.scr.measurements.dropRight;
    updateAttribute(width, dropLeft, dropRight);
    if (width >= 264 || dropLeft >= 132 || dropRight >= 132) {
      ctx.setScr({
        ...ctx.scr,
        measurements: {
          ...ctx.scr.measurements,
          [field]: value,
        },
        options: {
          ...ctx.scr.options,
          "Housing Size": "7",
          "Housing Shape": "Curved",
        },
      });
    } else {
      ctx.setScr({
        ...ctx.scr,
        measurements: {
          ...ctx.scr.measurements,
          [field]: value,
        },
      });
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    ctx.setScr({ ...ctx.scr, meshOnly: newValue === 1 });
  };

  const viewingMeshOnlyTab = ctx.scr.meshOnly && tabValue === 1;

  return (
    <div className={classes.paper}>
      <form
        className={classes.form}
        onSubmit={(e) => {
          ctx.handleAddScrToCart(e, ctx.scr);
          resetMotor();
        }}
      >
        <Box display="flex" justifyContent="center" mb={2}>
          <Tabs value={tabValue} onChange={handleTabChange}>
            <Tab label="Complete Unit" className={classes.screenTabs} />
            <Tab label="Mesh / Vinyl Only" className={classes.screenTabs} />
          </Tabs>
        </Box>

        <Grid container spacing={2}>
          {ctx.scr.screenName && (
            <Grid item xs={12}>
              <Typography color="inherit" variant="h6">
                {ctx.scr.screenName}
              </Typography>
            </Grid>
          )}

          <Grid container item xs={12} spacing={3}>
            <Grid item xs={12}>
              <Box flexGrow={1} className={classes.headerBox}>
                <Box style={{ display: "flex", flexDirection: "column" }}>
                  <Typography
                    color="inherit"
                    variant="h6"
                    className={classes.headerBoxText}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    Dimensions <InfoDialog type={"dimensions"} />
                  </Typography>
                  {viewingMeshOnlyTab && (
                    <Typography
                      color="error"
                      variant="subtitle2"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                        margin: "auto",
                      }}
                    >
                      This is the width of the Housing, not the Mesh
                    </Typography>
                  )}
                </Box>
              </Box>
            </Grid>

            <Grid item xs={9} sm={3}>
              <TextField
                fullWidth
                required
                type="number"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  inputProps: { min: 36, max: 360 },
                }}
                label="Width (Inches)"
                value={
                  ctx.scr.measurements.width === 0
                    ? ""
                    : ctx.scr.measurements.width
                }
                onFocus={(e) => e.target.select()}
                onChange={(e) => {
                  const value =
                    e.target.value === "" ? "" : Number(e.target.value);
                  setHousing("width", value);
                }}
              />
            </Grid>
            <Grid item xs={3} sm={1}>
              <FormControl fullWidth>
                <InputLabel shrink></InputLabel>
                <Select
                  value={ctx.scr.measurements.widthFraction}
                  required
                  onChange={(e) =>
                    ctx.setScr({
                      ...ctx.scr,
                      measurements: {
                        ...ctx.scr.measurements,
                        widthFraction: e.target.value,
                      },
                    })
                  }
                >
                  <MenuItem value={0}>0</MenuItem>
                  <MenuItem value={"1/8"}>1/8</MenuItem>
                  <MenuItem value={"1/4"}>1/4</MenuItem>
                  <MenuItem value={"3/8"}>3/8</MenuItem>
                  <MenuItem value={"1/2"}>1/2</MenuItem>
                  <MenuItem value={"5/8"}>5/8</MenuItem>
                  <MenuItem value={"3/4"}>3/4</MenuItem>
                  <MenuItem value={"7/8"}>7/8</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={9} sm={3}>
              <TextField
                fullWidth
                required
                type="number"
                InputLabelProps={{ shrink: true }}
                InputProps={{ inputProps: { min: 12 } }}
                label="Drop Left (Inches)"
                value={
                  ctx.scr.measurements.dropLeft === 0
                    ? ""
                    : ctx.scr.measurements.dropLeft
                }
                onFocus={(e) => e.target.select()}
                onChange={(e) => {
                  const value =
                    e.target.value === "" ? "" : Number(e.target.value);
                  setHousing("dropLeft", value);
                }}
              />
            </Grid>
            <Grid item xs={3} sm={1}>
              <FormControl fullWidth>
                <InputLabel shrink></InputLabel>
                <Select
                  value={ctx.scr.measurements.dropLeftFraction}
                  required
                  onChange={(e) =>
                    ctx.setScr({
                      ...ctx.scr,
                      measurements: {
                        ...ctx.scr.measurements,
                        dropLeftFraction: e.target.value,
                      },
                    })
                  }
                >
                  <MenuItem value={0}>0</MenuItem>
                  <MenuItem value={"1/4"}>1/4</MenuItem>
                  <MenuItem value={"1/2"}>1/2</MenuItem>
                  <MenuItem value={"3/4"}>3/4</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={9} sm={3}>
              <TextField
                fullWidth
                required
                type="number"
                InputLabelProps={{ shrink: true }}
                InputProps={{ inputProps: { min: 12 } }}
                label="Drop Right (Inches)"
                value={
                  ctx.scr.measurements.dropRight === 0
                    ? ""
                    : ctx.scr.measurements.dropRight
                }
                onFocus={(e) => e.target.select()}
                onChange={(e) => {
                  const value =
                    e.target.value === "" ? "" : Number(e.target.value);
                  setHousing("dropRight", value);
                }}
              />
            </Grid>
            <Grid item xs={3} sm={1}>
              <FormControl fullWidth>
                <InputLabel shrink></InputLabel>
                <Select
                  value={ctx.scr.measurements.dropRightFraction}
                  required
                  onChange={(e) =>
                    ctx.setScr({
                      ...ctx.scr,
                      measurements: {
                        ...ctx.scr.measurements,
                        dropRightFraction: e.target.value,
                      },
                    })
                  }
                >
                  <MenuItem value={0}>0</MenuItem>
                  <MenuItem value={"1/4"}>1/4</MenuItem>
                  <MenuItem value={"1/2"}>1/2</MenuItem>
                  <MenuItem value={"3/4"}>3/4</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <DropDirection
              l={ctx.scr.measurements.dropLeft || ""}
              lf={ctx.scr.measurements.dropLeftFraction || ""}
              r={ctx.scr.measurements.dropRight || ""}
              rf={ctx.scr.measurements.dropRightFraction || ""}
            />
          </Grid>

          {viewingMeshOnlyTab ? (
            ""
          ) : (
            <Grid container item xs={12} spacing={3}>
              <Grid item xs={12}>
                <Box flexGrow={1} className={classes.headerBox}>
                  <Typography
                    color="inherit"
                    variant="h6"
                    className={classes.headerBoxText}
                  >
                    Housing
                  </Typography>
                  <InfoTooltip
                    title={
                      <UnorderedList>
                        <UnorderedListItem>
                          5.5” (recommended when screens are less than 22’ wide
                          and / or less than 11’ tall)
                        </UnorderedListItem>
                        <UnorderedListItem>
                          7” (required when screens are greater than 22’ wide or
                          11’ tall)
                        </UnorderedListItem>
                      </UnorderedList>
                    }
                    arrow
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={jobColorSize}>
                <FormControl fullWidth>
                  <InputLabel shrink>Housing Size</InputLabel>
                  <Select
                    margin="dense"
                    value={ctx.scr.options["Housing Size"] || ""}
                    required
                    onChange={(e) => {
                      if (e.target.value === "5.5") {
                        setScr({
                          ...ctx.scr,
                          options: {
                            ...ctx.scr.options,
                            "Housing Size": e.target.value,
                            "Housing Shape": "Square",
                          },
                        });
                      } else {
                        setScr({
                          ...ctx.scr,
                          options: {
                            ...ctx.scr.options,
                            "Housing Size": e.target.value,
                            "Housing Shape": "Curved",
                          },
                        });
                      }
                    }}
                    disabled={
                      ctx.scr.measurements.width >= 264 ||
                      ctx.scr.measurements.dropLeft >= 132 ||
                      ctx.scr.measurements.dropRight >= 132
                    }
                  >
                    <MenuItem value="5.5">5.5"</MenuItem>
                    <MenuItem value="7">7"</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={jobColorSize}>
                <FormControl fullWidth>
                  <InputLabel shrink>Housing Shape</InputLabel>
                  <Select
                    margin="dense"
                    value={ctx.scr.options["Housing Shape"] || ""}
                    required
                    onChange={(e) =>
                      setScr({
                        ...ctx.scr,
                        options: {
                          ...ctx.scr.options,
                          "Housing Shape": e.target.value,
                        },
                      })
                    }
                    disabled={
                      ctx.scr.measurements.width >= 264 ||
                      ctx.scr.measurements.dropLeft >= 132 ||
                      ctx.scr.measurements.dropRight >= 132 ||
                      ctx.scr.options["Housing Size"] === "7"
                    }
                  >
                    <MenuItem value={"Curved"}>Curved</MenuItem>
                    <MenuItem value={"Square"}>Square</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={jobColorSize}>
                <FormControl fullWidth>
                  <InputLabel shrink>Job Color</InputLabel>
                  <Select
                    margin="dense"
                    required={!viewingMeshOnlyTab}
                    disabled={viewingMeshOnlyTab}
                    value={ctx.scr.options.jobColor}
                    onChange={(e) => {
                      if (e.target.value === "MF") {
                        setjobColorSize(3);
                      } else {
                        delete scr.options["RAL Color"];
                        setjobColorSize(4);
                      }
                      ctx.setScr({
                        ...ctx.scr,
                        options: {
                          ...ctx.scr.options,
                          jobColor: e.target.value,
                        },
                      });
                    }}
                  >
                    <MenuItem value={"Black"}>Black</MenuItem>
                    <MenuItem value={"White"}>White</MenuItem>
                    <MenuItem value={"Brown"}>Brown</MenuItem>
                    <MenuItem value={"Tan"}>Tan</MenuItem>
                    <MenuItem value={"Linen"}>Linen</MenuItem>
                    <MenuItem value={"Grey"}>Grey</MenuItem>
                    <MenuItem value={"MF"}>Custom *</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {ctx.scr.options.jobColor === "MF" ? (
                <Grid
                  style={{ paddingTop: "9px" }}
                  item
                  xs={12}
                  sm={jobColorSize}
                >
                  <FormControl fullWidth>
                    <TextField
                      fullWidth
                      required
                      type="number"
                      InputLabelProps={{ shrink: true }}
                      InputProps={{ maxLength: 4 }}
                      label="RAL Color (4 Digit Code)"
                      value={
                        ctx.scr.options["RAL Color"] === 0
                          ? ""
                          : ctx.scr.options["RAL Color"]
                      }
                      onFocus={(e) => e.target.select()}
                      onKeyDown={(e) => {
                        if (
                          e.key === "e" ||
                          e.key === "E" ||
                          e.key === "." ||
                          e.key === "+"
                        ) {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) => {
                        const value = e.target.value.slice(0, 4);
                        ctx.setScr({
                          ...ctx.scr,
                          options: { ...ctx.scr.options, "RAL Color": value },
                        });
                      }}
                    />
                  </FormControl>
                </Grid>
              ) : (
                ""
              )}
            </Grid>
          )}

          {viewingMeshOnlyTab ? (
            ""
          ) : (
            <Grid container item xs={12} spacing={3}>
              <Grid item xs={12}>
                <Box flexGrow={1} className={classes.headerBox}>
                  <Typography
                    color="inherit"
                    variant="h6"
                    className={classes.headerBoxText}
                  >
                    Track
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <InputLabel shrink>Track Left</InputLabel>
                  <Select
                    margin="dense"
                    required
                    value={ctx.scr.options["Track Left"] || ""}
                    onChange={(e) =>
                      setScr({
                        ...ctx.scr,
                        options: {
                          ...ctx.scr.options,
                          "Track Left": e.target.value,
                        },
                      })
                    }
                  >
                    <MenuItem value="Standard">Standard</MenuItem>
                    <MenuItem value="Recessed">Recessed</MenuItem>
                    <MenuItem value="Cable">Cable</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <InputLabel shrink>Track Right</InputLabel>
                  <Select
                    margin="dense"
                    required
                    value={ctx.scr.options["Track Right"] || ""}
                    onChange={(e) =>
                      setScr({
                        ...ctx.scr,
                        options: {
                          ...ctx.scr.options,
                          "Track Right": e.target.value,
                        },
                      })
                    }
                  >
                    <MenuItem value="Standard">Standard</MenuItem>
                    <MenuItem value="Recessed">Recessed</MenuItem>
                    <MenuItem value="Cable">Cable</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <InputLabel shrink>Mounting Angle</InputLabel>
                  <Select
                    margin="dense"
                    required
                    value={ctx.scr.options["Mounting Angle"] || ""}
                    onChange={(e) =>
                      setScr({
                        ...ctx.scr,
                        options: {
                          ...ctx.scr.options,
                          "Mounting Angle": e.target.value,
                        },
                      })
                    }
                  >
                    <MenuItem value="Yes">Yes</MenuItem>
                    <MenuItem value="No">No</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          )}

          <Grid item container xs={12} spacing={3} direction="row">
            <Grid item xs={12}>
              <Box flexGrow={1} className={classes.headerBox}>
                <Typography
                  color="inherit"
                  variant="h6"
                  className={classes.headerBoxText}
                >
                  Screen
                </Typography>
              </Box>
            </Grid>

            <ScreenMaterial
              scr={scr}
              setScr={setScr}
              kitAttributes={Material?.attributes}
              materialType={materialType}
              setMaterialType={setMaterialType}
            />

            {viewingMeshOnlyTab ? (
              ""
            ) : (
              <DynamicAttributes
                kitAttributes={Bar?.attributes}
                scr={scr}
                setScr={setScr}
              />
            )}

            {viewingMeshOnlyTab ? (
              <Grid item shrink xs={12} sm={4} spacing={3}>
                <FormControl fullWidth>
                  <InputLabel shrink>Mesh Replacement Reason</InputLabel>
                  <Select
                    margin="dense"
                    required
                    style={{ marginTop: 19 }}
                    value={ctx.scr.options["Replacement Reason"] || ""}
                    onChange={(e) => {
                      setScr({
                        ...ctx.scr,
                        options: {
                          ...ctx.scr.options,
                          "Replacement Reason": e.target.value,
                        },
                      });
                    }}
                  >
                    <MenuItem value="3">Manufacturing error</MenuItem>
                    <MenuItem value="2">
                      Mesh / Vinyl Issues (knots, lines, clarity and streaks)
                    </MenuItem>
                    <MenuItem value="4">Ordering error</MenuItem>
                    <MenuItem value="1">New purchase</MenuItem>
                    <MenuItem value="5">Other</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            ) : (
              ""
            )}
          </Grid>

          {viewingMeshOnlyTab ? (
            ""
          ) : (
            <Grid item container xs={12} spacing={3}>
              <Grid item xs={12}>
                <Box flexGrow={1} className={classes.headerBox}>
                  <Typography
                    color="inherit"
                    variant="h6"
                    className={classes.headerBoxText}
                  >
                    Motor
                  </Typography>
                  <InfoTooltip
                    title={
                      <UnorderedList>
                        <UnorderedListItem>
                          Standard (recommended for screens less than 20’ wide)
                        </UnorderedListItem>
                        <UnorderedListItem>
                          Fast (available for screens less than 10’ wide and
                          less than 10’ tall)
                        </UnorderedListItem>
                        <UnorderedListItem>
                          HD (required for screens greater than 20’ wide)
                        </UnorderedListItem>
                        <UnorderedListItem>
                          Super HD (required for screens greater than 25’ wide)
                        </UnorderedListItem>
                      </UnorderedList>
                    }
                    arrow
                  />
                </Box>
              </Grid>

              <DynamicAttributes
                kitAttributes={motorTypeDisplay?.attributes}
                scr={scr}
                setScr={setScr}
                xsSize={12}
              />
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <InputLabel shrink>Motor Side</InputLabel>
                  <Select
                    value={ctx.scr.options["Motor Side"] || ""}
                    required
                    onChange={(e) =>
                      setScr({
                        ...ctx.scr,
                        options: {
                          ...ctx.scr.options,
                          "Motor Side": e.target.value,
                        },
                      })
                    }
                  >
                    <MenuItem value="Left">Left</MenuItem>
                    <MenuItem value="Right">Right</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          )}

          <Grid item container xs={12} spacing={3}>
            <Grid item xs={12}>
              <FormControl
                className={classes.formControl}
                required
                fullWidth
                style={{ width: "100%" }}
              >
                <TextField
                  fullWidth
                  variant="outlined"
                  multiline
                  minRows={6}
                  type="text"
                  InputLabelProps={{ shrink: true }}
                  label="Additional Details"
                  value={ctx.scr.details}
                  onFocus={(e) => e.target.select()}
                  onChange={(e) =>
                    ctx.setScr({ ...ctx.scr, details: e.target.value })
                  }
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <Typography color="error" variant="subtitle2">
                <em>
                  All additional items (remotes, angle for top slope, etc.) need
                  to be ordered separately from the Parts & Extras tab.
                </em>
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Button
                type="submit"
                className={classes.submit}
                fullWidth
                variant="contained"
                color="primary"
                disabled={
                  ctx.isAdmin && order && status === ORDER_STATUS.REJECTED
                }
                size="medium"
              >
                {order || ctx.scr.screenId.length > 0
                  ? "Update Order"
                  : "Add to Order"}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

const StoreScreens = ({ order, status }) => {
  const ctx = useContext(StoreContext);
  return (
    <>
      {ctx.loading ? (
        <Loader />
      ) : ctx.error ? (
        <Message severity="error">{ctx.error}</Message>
      ) : (
        <ScreenForm order={order} status={status} />
      )}
    </>
  );
};

export { StoreScreens };
