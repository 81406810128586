import { useState } from "react";

import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

// info tooltip that works on mobile
const InfoTooltip = (props) => {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        PopperProps={{
          disablePortal: true,
        }}
        onClose={handleTooltipClose}
        open={open}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        {...props}
      >
        <Box
          onClick={handleTooltipOpen}
          style={{ cursor: "pointer", display: "inline-flex" }}
          component="span"
        >
          <InfoOutlinedIcon
            color="secondary"
            style={{ marginLeft: 3 }}
            fontSize="small"
          />
        </Box>
      </Tooltip>
    </ClickAwayListener>
  );
};

export default InfoTooltip;
