import React, { useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  Button,
  CssBaseline,
  Grid,
  Link,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";

import { Message, Loader } from "../../components";
import { register } from "../../actions";

import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import AuthIcon from "./AuthIcon";
import { ViewContainer } from "../../components/ViewContainer";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(2, 0, 2),
  },
}));

const schema = z
  .object({
    fname: z.string().min(1, "First name is required"),
    lname: z.string().min(1, "Last name is required"),
    location: z.string().min(1, "Dealer / Location is required"),
    email: z.string().email("Invalid email address"),
    password: z.string().min(6, "Password must be at least 6 characters"),
    confirmPassword: z.string().min(6, "Confirm your password"),
  })
  .refine((data) => data.password === data.confirmPassword, {
    path: ["confirmPassword"],
    message: "Passwords do not match",
  });

const RegisterView = ({ location, history }) => {
  const userRegister = useSelector((state) => state.userRegister);
  const { loading, error, registerInfo } = userRegister;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    if (registerInfo && !userInfo) {
      history.push("/login");
    }
  }, [history, registerInfo, userInfo]);

  const {
    register: formRegister,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema),
  });

  const submitHandler = (data) => {
    dispatch(register(data));
  };

  const errorHandler = (err) => {
    console.log(err);
  };

  return (
    <ViewContainer component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <AuthIcon />
        <Typography component="h1" variant="h5">
          Register
        </Typography>
        {loading ? (
          <Loader />
        ) : (
          <>
            {error && <Message severity="error">{error}</Message>}
            <form
              className={classes.form}
              onSubmit={handleSubmit(submitHandler, errorHandler)}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="fname"
                    label="First Name"
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    error={!!errors.fname}
                    helperText={errors.fname ? errors.fname.message : ""}
                    {...formRegister("fname")}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="lname"
                    label="Last Name"
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    error={!!errors.lname}
                    helperText={errors.lname ? errors.lname.message : ""}
                    {...formRegister("lname")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="location"
                    label="Dealer / Location"
                    placeholder="e.g. Outdoor Living Ltd., Bonyville, AB"
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    error={!!errors.location}
                    helperText={errors.location ? errors.location.message : ""}
                    {...formRegister("location")}
                  />
                  <TextField
                    name="email"
                    type="email"
                    label="Email Address"
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    error={!!errors.email}
                    helperText={errors.email ? errors.email.message : ""}
                    {...formRegister("email")}
                  />

                  <TextField
                    name="password"
                    type="password"
                    label="Password"
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    error={!!errors.password}
                    helperText={errors.password ? errors.password.message : ""}
                    {...formRegister("password")}
                  />
                  <TextField
                    name="confirmPassword"
                    type="password"
                    label="Confirm Password"
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    error={!!errors.confirmPassword}
                    helperText={
                      errors.confirmPassword
                        ? errors.confirmPassword.message
                        : ""
                    }
                    {...formRegister("confirmPassword")}
                  />
                </Grid>
                <Button
                  className={classes.submit}
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                >
                  Sign Up
                </Button>
                <Grid container justifyContent="flex-end">
                  <Grid item>
                    <Link component={RouterLink} to="/login">
                      Already have an account? Sign in
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </>
        )}
      </div>
    </ViewContainer>
  );
};

export { RegisterView };
