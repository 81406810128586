import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import XLSX from "xlsx";
import readXlsxFile from "read-excel-file";
import { format } from "date-fns";

import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";

import { withStyles } from "@material-ui/core/styles";

import { adminImportItems } from "../../actions";
import { numberFormat } from "../../utils/numberFormatter";

const StyledCard = withStyles({
  root: {
    width: "50%",
    borderRadius: 6,
  },
})(Card);

const Data = () => {
  const bcItems = useSelector((state) => state.bcItemsList);
  const { allBcParts, bcParts } = bcItems;
  const [unitOM, setUnitOM] = useState(undefined);
  const [salesPrice, setSalesPrice] = useState(undefined);
  const dispatch = useDispatch();

  const importCustomersHandler = (e) => {
    // console.log('disabled for now');
  };

  const importItemsHandler = (e) => {
    e.preventDefault();
    dispatch(adminImportItems());
  };

  const handleFileInput = (e) => {
    const uomschema = {
      "No.": {
        prop: "txtItemNo",
        type: String,
      },
      "Base Unit of Measure": {
        prop: "txtBaseUnitOfMeasure",
        type: String,
      },
      "Sales Unit of Measure": {
        prop: "txtSalesUnitOfMeasure",
        type: String,
      },
      "Purch. Unit of Measure": {
        prop: "txtPurchUnitOfMeasure",
        type: String,
      },
    };
    const spschema = {
      "No.": {
        prop: "txtItemNo",
        type: String,
      },
      "Canadian Sales Price": {
        prop: "txtCAD",
        type: Number,
      },
      "US Sales Price": {
        prop: "txtUSD",
        type: Number,
      },
    };
    e.preventDefault();
    const fileName = e.target.files[0].name;
    if (fileName === "uom.xlsx") {
      const schema = uomschema;
      readXlsxFile(e.target.files[0], { schema }).then((rows) => {
        setUnitOM(rows);
      });
    } else if (fileName === "salesprice.xlsx") {
      const schema = spschema;
      readXlsxFile(e.target.files[0], { schema }).then((rows) => {
        setSalesPrice(rows);
      });
    }
  };

  const handleFileOutput = async (e) => {
    e.preventDefault();
    const { rows: uomRows } = unitOM;
    const { rows: spRows } = salesPrice;
    const fileName = "salespricepackage.xlsx";

    const spData = () => {
      let pData = [];
      let uData = [];
      spRows.map((price) => {
        uData = uomRows.filter((uom) => uom.txtItemNo === price.txtItemNo);
        if (uData.length > 0) {
          if (price.txtCAD?.toString().length > 0) {
            pData.push({
              "Item No.": price.txtItemNo,
              "Sales Type": "Customer Price Group",
              "Sales Code": "CAD",
              "Starting Date": "2022-01-01",
              "Currency Code": "CAD",
              "Variant Code": "",
              "Unit of Measure Code": uData[0].txtSalesUnitOfMeasure,
              "Minimum Quantity": 0,
              "Unit Price": Number(price.txtCAD),
              "Price Includes VAT": false,
              "Allow Invoice Disc.": true,
              "Allow Line Disc.": true,
            });
          }
          if (price.txtUSD?.toString().length > 0) {
            pData.push({
              "Item No.": price.txtItemNo,
              "Sales Type": "Customer Price Group",
              "Sales Code": "USD",
              "Starting Date": "2022-01-01",
              "Currency Code": "USD",
              "Variant Code": "",
              "Unit of Measure Code": uData[0].txtSalesUnitOfMeasure,
              "Minimum Quantity": 0,
              "Unit Price": Number(price.txtUSD),
              "Price Includes VAT": false,
              "Allow Invoice Disc.": true,
              "Allow Line Disc.": true,
            });
          }
        }
        return pData;
      });
      return pData;
    };

    const objects = unitOM && salesPrice && spData();

    const worksheet = XLSX.utils.json_to_sheet(objects);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sales Price");
    XLSX.writeFile(workbook, fileName);
  };

  const handleItemsFromPortal = (e) => {
    e.preventDefault();

    const xlsxFromPortal = XLSX.utils.book_new();
    const fileName = `[${format(
      new Date(),
      "MMM dd, yyyy"
    )}] Items from Portal.xlsx`;
    let xId = [];
    let itemsDollar = bcParts;
    const xlsxID =
      itemsDollar?.length > 0 &&
      itemsDollar.map((item) => {
        xId.push({
          bcId: item.id,
          number: item.number,
          displayName: item.displayName,
          baseUnitOfMeasureCode: item.baseUnitOfMeasureCode,
          salesUnitOfMeasureCode: item.baseUnitOfMeasureCode,
          cadPrice: numberFormat(
            "cur-display",
            Number(item.prices[0] ? item.prices[0].unitPrice : "")
          ),
          usdPrice: numberFormat(
            "cur-display",
            Number(item.prices[1] ? item.prices[1].unitPrice : "")
          ),
          itemCategoryCode: item.itemCategoryCode,
          type: item.type,
          createdAt: format(new Date(item.createdAt), "MMM dd, yyyy"),
          updatedAt: format(new Date(item.updatedAt), "MMM dd, yyyy"),
        });
        return xId;
      });
    const xlsxIDSheet =
      xlsxID?.length > 0 && XLSX.utils.json_to_sheet(xlsxID[0]);
    xlsxID?.length > 0 &&
      XLSX.utils.book_append_sheet(
        xlsxFromPortal,
        xlsxIDSheet,
        "Items with Prices"
      );

    let itemsHidden = allBcParts.filter((item) =>
      item.attributes.some((attr) => attr.attributeName === "Hidden")
    );
    let xIh = [];
    const xlsxIH =
      itemsHidden?.length > 0 &&
      itemsHidden.map((item) => {
        xIh.push({
          bcId: item.id,
          number: item.number,
          displayName: item.displayName,
          baseUnitOfMeasureCode: item.baseUnitOfMeasureCode,
          salesUnitOfMeasureCode: item.baseUnitOfMeasureCode,
          cadPrice: numberFormat(
            "cur-display",
            Number(item.prices[0] ? item.prices[0].unitPrice : "")
          ),
          usdPrice: numberFormat(
            "cur-display",
            Number(item.prices[1] ? item.prices[1].unitPrice : "")
          ),
          itemCategoryCode: item.itemCategoryCode,
          type: item.type,
          createdAt: format(new Date(item.createdAt), "MMM dd, yyyy"),
          updatedAt: format(new Date(item.updatedAt), "MMM dd, yyyy"),
        });
        return xIh;
      });
    const xlsxIHSheet =
      xlsxIH?.length > 0 && XLSX.utils.json_to_sheet(xlsxIH[0]);
    XLSX.utils.book_append_sheet(xlsxFromPortal, xlsxIHSheet, "Hidden Items");

    XLSX.writeFile(xlsxFromPortal, fileName);
  };

  return (
    <>
      <h1>Data Management</h1>
      <StyledCard>
        <CardHeader title="Data Imports" />
        <CardActions>
          <Button
            variant="contained"
            color="primary"
            disabled
            onClick={(e) => importCustomersHandler(e)}
          >
            Import Customers
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={(e) => importItemsHandler(e)}
          >
            Import Items
          </Button>
        </CardActions>
      </StyledCard>
      <StyledCard style={{ marginTop: 15 }}>
        <CardHeader title="Data Exports" />

        <CardActions>
          <Button
            variant="contained"
            color="secondary"
            onClick={(e) => handleItemsFromPortal(e)}
          >
            Portal Items Export
          </Button>
        </CardActions>
      </StyledCard>
      <StyledCard style={{ marginTop: 15 }}>
        <CardHeader title="ItemsSheet Generator for BC" />
        <CardContent>
          <form>
            <FormControl>
              <InputLabel shrink>UnitOM Import</InputLabel>
              <Input
                id="unitOMIn"
                type={"file"}
                label="UnitOM Import"
                onChange={(e) => handleFileInput(e)}
              />
            </FormControl>
            <FormControl style={{ marginLeft: 5 }}>
              <InputLabel shrink>Sales Price Import</InputLabel>
              <Input
                id="salesPriceIn"
                type={"file"}
                onChange={(e) => handleFileInput(e)}
              ></Input>
            </FormControl>
          </form>
        </CardContent>
        <CardActions>
          {unitOM && salesPrice && (
            <Button
              variant="contained"
              color="secondary"
              style={{ marginTop: 15 }}
              onClick={(e) => handleFileOutput(e)}
            >
              Download
            </Button>
          )}
        </CardActions>
      </StyledCard>
    </>
  );
};

export default Data;
