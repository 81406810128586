import api from "../api";

import {
  TICKET_CREATE_FAIL,
  TICKET_CREATE_REQUEST,
  TICKET_CREATE_RESET,
  TICKET_CREATE_SUCCESS,
  TICKET_DELETE_FAIL,
  TICKET_DELETE_REQUEST,
  TICKET_DELETE_SUCCESS,
  TICKET_DETAILS_FAIL,
  TICKET_DETAILS_REQUEST,
  TICKET_DETAILS_SUCCESS,
  TICKET_LIST_MY_FAIL,
  TICKET_LIST_MY_REQUEST,
  TICKET_LIST_MY_SUCCESS,
  // TICKET_UPDATE_FAIL,
  // TICKET_UPDATE_REQUEST,
  // TICKET_UPDATE_SUCCESS,
} from "../constants";

export const createTicket = (ticket) => async (dispatch, getState) => {
  try {
    dispatch({
      type: TICKET_CREATE_REQUEST,
    });

    const res = await api.post("/api/tickets", ticket);

    dispatch({
      type: TICKET_CREATE_SUCCESS,
      payload: {
        ticket: res.data,
        status: res.status,
        statusText: res.statusText,
      },
    });
  } catch (err) {
    dispatch({
      type: TICKET_CREATE_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const listMyTickets = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: TICKET_LIST_MY_REQUEST,
    });

    const { data } = await api.get("/api/tickets/mytickets");

    dispatch({
      type: TICKET_LIST_MY_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: TICKET_LIST_MY_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const getTicketDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: TICKET_DETAILS_REQUEST,
    });

    const { data } = await api.get(`/api/tickets/${id}`);

    dispatch({
      type: TICKET_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: TICKET_DETAILS_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const updateTicket =
  (customer, ticketname, roofname, filename) => async (dispatch, getState) => {
    // try {
    //   dispatch({
    //     type: TICKET_UPDATE_REQUEST,
    //   });
    //   const {
    //     userLogin: { userInfo },
    //   } = getState();
    //   const key = `${customer}/${ticketname}/${roofname}/${filename}`;
    //   let link = await api.post(
    //     '/api/docs/dl',
    //     {
    //       bucket: 'roof',
    //       key: key,
    //     }
    //   );
    //   // // console.log(link.data);
    //   // console.log('action', link);
    //   // console.log('action', link.data);
    //   dispatch({
    //     type: TICKET_UPDATE_SUCCESS,
    //     payload: link.data,
    //   });
    // } catch (err) {
    //   dispatch({
    //     type: TICKET_UPDATE_FAIL,
    //     payload:
    //       err.response && err.response.data.message
    //         ? err.response.data.message
    //         : err.message,
    //   });
    // }
  };

export const deleteTicket = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: TICKET_DELETE_REQUEST,
    });

    await api.delete(`/api/tickets/${id}`);

    dispatch({
      type: TICKET_DELETE_SUCCESS,
    });
  } catch (err) {
    dispatch({
      type: TICKET_DELETE_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const resetTicketState = () => (dispatch) => {
  dispatch({ type: TICKET_CREATE_RESET });
};
