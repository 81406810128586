import axios from "axios";

let API_BASE_URL;

switch (process.env.REACT_APP_ENV) {
  case "experimental":
    API_BASE_URL = ""; // TBD
    break;
  case "staging":
    API_BASE_URL = "https://portal-api-staging.suncoastbuilt.com"; // the ALB url
    break;
  case "production":
    API_BASE_URL = ""; // currently using relative URLs for nginx
    break;
  default:
    API_BASE_URL = "http://localhost:5000";
}

const api = axios.create({
  baseURL: API_BASE_URL,
  timeout: 20000,
  headers: {
    "Content-Type": "application/json",
  },
});

// We add a request interceptor to attach the token automatically
api.interceptors.request.use(
  (config) => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo")) || null;

    if (userInfo && userInfo.token) {
      config.headers.Authorization = `Bearer ${userInfo.token}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

export default api;
