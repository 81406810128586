import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { logout, listFiles } from "../actions";
import { LibraryDocs, LibraryVideos, LibraryPhotos } from "../components";

import { Box, Tab, Tabs } from "@material-ui/core";
import { ViewContainer } from "../components/ViewContainer";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div hidden={value !== index} {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

const LibraryDocsTab = ({ value }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (value === 0) {
      dispatch(listFiles("docs"));
    }
  }, [dispatch, value]);

  return (
    <TabPanel value={value} index={0}>
      <LibraryDocs />
    </TabPanel>
  );
};

const LibraryVideosTab = ({ value }) => {
  return (
    <TabPanel value={value} index={1}>
      <LibraryVideos />
    </TabPanel>
  );
};

const LibraryPhotosTab = ({ value }) => {
  return (
    <TabPanel value={value} index={2}>
      <LibraryPhotos />
    </TabPanel>
  );
};

const LibraryView = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // check if user is logged in
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  function isTokenExpired(token) {
    const expiry = JSON.parse(Buffer.from(token.split(".")[1], "base64")).exp;
    return Math.floor(new Date().getTime() / 1000) >= expiry;
  }

  useEffect(() => {
    if (!userInfo || isTokenExpired(userInfo.token)) {
      dispatch(logout());
      history.push("/login");
    }
  }, [history, userInfo, dispatch]);

  return (
    <ViewContainer maxWidth="lg">
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="simple tabs example"
      >
        <Tab label="Documents" />
        <Tab label="Videos" />
        <Tab label="Photos" />
      </Tabs>

      <LibraryDocsTab value={value} />
      <LibraryVideosTab value={value} />
      <LibraryPhotosTab value={value} />
    </ViewContainer>
  );
};

export { LibraryView };
