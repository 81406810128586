import { lighten, withStyles } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

const InfoAlert = withStyles((theme) => ({
  root: {
    backgroundColor: lighten(theme.palette.secondary.main, 0.85),
  },
  icon: {
    color: `${theme.palette.secondary.main} !important`,
  },
}))(Alert);

export default InfoAlert;
