import React, { useContext } from "react";

import { StoreContext } from "../context";
import { InfoDialog } from ".";

import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  Link,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { AddBox, DeleteForever } from "@material-ui/icons";
import StoreRoofSectionTitle from "./Store/Roofs/StoreRoofSectionTitle";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%",
  },
  submit: {
    marginTop: theme.spacing(1),
  },
  marg: {
    margin: theme.spacing(1),
  },
  formControl: {
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(),
    marginRight: theme.spacing(),
    width: "99%",
  },
  mountingAngle: {
    margin: theme.spacing(1),
  },
  btns: {
    display: "flex",
    flexDirection: "row",
  },
  splitCol: {
    display: "flex",
  },
  screenSideIndicator: {
    maxWidth: "65%",
  },
  headerBox: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));
const getMaxValue = (frameType) => {
  return frameType === "4x8" ? 25 : 20;
};
const DrawFrames = ({ rf }) => {
  const classes = useStyles();
  const ctx = useContext(StoreContext);
  const { frames } = rf;

  const deleteFrameHandler = (e, i) => {
    e.preventDefault();
    const newframes = [...frames];
    newframes.splice(i, 1);
    ctx.setRf({
      ...ctx.rf,
      frames: newframes,
    });
  };

  return (
    frames?.length > 0 &&
    frames.map((frame, i) => (
      <Grid container item xs={12} spacing={1} key={i} alignItems="center">
        <Grid item xs={5}>
          <FormControl className={classes.formControl} required fullWidth>
            <InputLabel shrink>Frame Type</InputLabel>
            <Select
              name="frameType"
              required
              style={{ paddingTop: 5 }}
              value={frame.frameType}
              onChange={(e) => ctx.handleChangeFrame(e, i)}
            >
              <MenuItem value={"2x8"}>2x8 Frame</MenuItem>
              <MenuItem value={"2x10"}>2x10 Frame</MenuItem>
              <MenuItem value={"4x8"}>4x8 Frame</MenuItem>
              <MenuItem value={"2 Ply 2x8"}>2 Ply 2x8 Frame</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl className={classes.formControl} required fullWidth>
            <TextField
              name="frameFeet"
              style={{ paddingTop: 5 }}
              required
              value={frame.frameFeet}
              onFocus={(e) => e.target.select()}
              onChange={(e) => ctx.handleChangeFrame(e, i)}
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                min: 0,
                max: getMaxValue(frame.frameType),
              }}
              label="Feet"
            />
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <Box key={i} display="flex">
            <FormControl className={classes.formControl} required fullWidth>
              <TextField
                name="frameInches"
                style={{ paddingTop: 5 }}
                required
                value={frame.frameInches}
                onFocus={(e) => e.target.select()}
                onChange={(e) => ctx.handleChangeFrame(e, i)}
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  min: 0,
                  max: 11.99,
                }}
                label="Inches"
                fullWidth
              />
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={1}>
          <Link
            onClick={(e) => deleteFrameHandler(e, i)}
            size="small"
            color="error"
            component="button"
            variant="body2"
          >
            <DeleteForever fontSize="small" />
          </Link>
        </Grid>
      </Grid>
    ))
  );
};

const StoreRoofsFrames = ({ rf }) => {
  const ctx = useContext(StoreContext);
  const { frames } = rf;

  return (
    <Paper elevation={0}>
      <Box className={useStyles().headerBox}>
        <StoreRoofSectionTitle title="Perimeter Frames">
          <InfoDialog type={"Perimeter Frames"} />
        </StoreRoofSectionTitle>
        {frames.length > 0 && (
          <Typography variant="body2">
            {`${frames?.length} Frame${frames?.length === 1 ? "" : "s"}`}
          </Typography>
        )}
      </Box>

      <Grid
        container
        spacing={1}
        item
        xs={12}
        style={{
          alignItems: "center",
          marginBottom: "5px",
        }}
      >
        <Grid item xs={12}>
          <Button
            variant="text"
            color="primary"
            onClick={(e) =>
              ctx.setRf({
                ...ctx.rf,
                frames: ctx.handleAddFramesToRoof(frames),
              })
            }
            startIcon={<AddBox color="secondary" />}
            style={{ textTransform: "none" }}
          >
            Add frame
          </Button>
        </Grid>

        {frames?.length > 0 && <DrawFrames rf={ctx.rf} />}
      </Grid>
      <Divider style={{ marginTop: 8, marginBottom: 8 }} />
    </Paper>
  );
};

export { StoreRoofsFrames };
