import React, { useContext } from "react";

import { StoreContext } from "../context";
import { InfoDialog } from ".";

import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  Link,
  makeStyles,
  MenuItem,
  Select,
  // TextField,
  Typography,
  Paper,
  Divider,
} from "@material-ui/core";
import { AddBox, DeleteForever } from "@material-ui/icons";
import StoreRoofSectionTitle from "./Store/Roofs/StoreRoofSectionTitle";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%",
  },
  submit: {
    marginTop: theme.spacing(1),
  },
  marg: {
    margin: theme.spacing(1),
  },
  formControl: {
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(),
    marginRight: theme.spacing(),
    width: "99%",
  },
  mountingAngle: {
    margin: theme.spacing(1),
  },
  btns: {
    display: "flex",
    flexDirection: "row",
  },
  splitCol: {
    display: "flex",
  },
  screenSideIndicator: {
    maxWidth: "65%",
  },
  headerBox: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

const DrawPosts = ({ rf }) => {
  const ctx = useContext(StoreContext);
  const classes = useStyles();
  const { posts } = rf;

  const deletePostHandler = (e, i) => {
    e.preventDefault();
    const newPosts = [...posts];
    newPosts.splice(i, 1);
    ctx.setRf({
      ...ctx.rf,
      posts: newPosts,
    });
  };
  const getPostLengthOptions = (postStyle) => {
    if (postStyle?.startsWith("8x8")) {
      return ["10", "12", "25"];
    }
    return ["10", "12", "19'4\""];
  };

  return (
    posts?.length > 0 &&
    posts.map((post, i) => (
      <Grid container item xs={12} spacing={1} key={i} alignItems="center">
        <Grid item xs={7}>
          <FormControl className={classes.formControl} required>
            <InputLabel shrink>Post Style</InputLabel>
            <Select
              name="postStyle"
              required
              value={post.postStyle}
              onChange={(e) => ctx.handleChangePost(e, i)}
            >
              <MenuItem value={"6x6 Corner Saddle"}>6x6 Corner Saddle</MenuItem>
              <MenuItem value={"6x6 Flat Saddle"}>6x6 Flat Saddle</MenuItem>
              <MenuItem value={"6x6 Flush"}>6x6 Flush</MenuItem>
              <MenuItem value={"8x8 Corner Saddle"}>8x8 Corner Saddle</MenuItem>
              <MenuItem value={"8x8 Flat Saddle"}>8x8 Flat Saddle</MenuItem>
              <MenuItem value={"8x8 Flush"}>8x8 Flush</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={5}>
          <Box key={i} display="flex">
            <FormControl className={classes.formControl} required fullWidth>
              <InputLabel shrink>Post Length</InputLabel>
              <Select
                name="postLength"
                required
                value={post.postLength}
                onChange={(e) => ctx.handleChangePost(e, i)}
              >
                {getPostLengthOptions(post.postStyle).map((lengthOption) => (
                  <MenuItem key={lengthOption} value={lengthOption}>
                    {lengthOption}'
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Link
              onClick={(e) => deletePostHandler(e, i)}
              size="small"
              color="error"
              component="button"
              variant="body2"
            >
              <DeleteForever fontSize="small" />
            </Link>
          </Box>
        </Grid>
      </Grid>
    ))
  );
};

const StoreRoofsPosts = ({ rf }) => {
  const ctx = useContext(StoreContext);
  const { posts } = rf;

  return (
    <Paper elevation={0}>
      <Box className={useStyles().headerBox}>
        <StoreRoofSectionTitle title="Posts">
          <InfoDialog type={"Posts"} />
        </StoreRoofSectionTitle>
        {posts?.length > 0 && (
          <Typography variant="body2">
            {`${posts?.length || 0} Post${posts?.length === 1 ? "" : "s"}`}
          </Typography>
        )}
      </Box>
      <Grid
        container
        spacing={1}
        item
        xs={12}
        style={{ alignItems: "center", marginBottom: "5px" }}
      >
        <Grid item xs={12}>
          <Button
            variant="text"
            color="primary"
            onClick={(e) =>
              ctx.setRf({
                ...ctx.rf,
                posts: ctx.handleAddPostsToRoof(posts),
              })
            }
            startIcon={<AddBox color="secondary" />}
            style={{ textTransform: "none" }}
          >
            Add post
          </Button>
        </Grid>
        {posts?.length > 0 && <DrawPosts rf={ctx.rf} />}
      </Grid>
      <Divider style={{ marginTop: 8, marginBottom: 8 }} />
    </Paper>
  );
};

export { StoreRoofsPosts };
