import React from "react";
import { format } from "date-fns";
import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  Collapse,
  Divider,
  Grid,
  Link,
  SvgIcon,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
  withStyles,
} from "@material-ui/core";
import { Person, FiberManualRecord } from "@material-ui/icons";

import { TrackingDialog } from ".";
import { ORDER_STATUS } from "./Orders/constants";
import { numberFormat } from "../utils/numberFormatter";
import { ReactComponent as CASvg } from "../assets/images/ca.svg";
import { ReactComponent as USSvg } from "../assets/images/us.svg";
import useMobile from "../utils/hooks/useMobile";
import InfoAlert from "./Alerts/InfoAlert";

const OrderMobileListItem = withStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: `${theme.spacing(1)}px`,
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.common.white,
  },
}))(Box);

const StyledTableRowAdmin = withStyles((theme) => ({
  root: (props) => ({
    cursor: "pointer",
    boxShadow: props.isExpanded ? "0px 2px 5px #d3d3d3" : "none",
    backgroundColor: props.isExpanded ? theme.palette.grey[100] : "inherit",
    position: "relative",
  }),
}))(({ isExpanded, ...props }) => <TableRow {...props} />);

const OrderListItemAdmin = ({
  order,
  isExpanded,
  onExpandClick,
  status,
  classes,
}) => {
  const isMobile = useMobile();
  const theme = useTheme();

  const expandedOrderContent = (
    <Collapse in={isExpanded} timeout="auto" unmountOnExit>
      <Box sx={{ padding: `${theme.spacing(2)}px 0` }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3}>
            <Typography variant="subtitle2">Details</Typography>
            <Box display="flex" flexDirection="row" alignItems="baseline">
              <Typography variant="subtitle2">Sales Order #:</Typography>
              <Box ml={1}>
                <Typography variant="body2">
                  {order.orderNumber ? order.orderNumber : "N/A"}
                </Typography>
              </Box>
            </Box>
            {status === "Shipped" && (
              <Box display="flex" flexDirection="column">
                <Typography variant="subtitle2">Shipping</Typography>
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="baseline"
                  ml={1}
                >
                  <Typography variant="subtitle2">Agent:</Typography>
                  <Box ml={1}>
                    <Typography variant="body2">
                      {order.shippingAgent ? order.shippingAgent : "N/A"}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="baseline"
                  ml={1}
                >
                  <Typography variant="subtitle2">Tracking:</Typography>
                  <Box ml={1}>
                    <Typography variant="body2">
                      {order.trackingNumber ? order.trackingNumber : "N/A"}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            )}
          </Grid>
          <Grid container item xs={12} sm={3} direction="column">
            <Typography variant="subtitle2">Screens</Typography>
            {order.screens.length > 0
              ? order.screens.map((screen) => (
                  <Box key={screen._id} display="flex" alignItems="baseline">
                    <FiberManualRecord
                      style={{
                        width: 10,
                        height: 10,
                        marginRight: 5,
                      }}
                    />{" "}
                    {`${screen.screenName} - ${numberFormat(
                      "cur-display",
                      screen.price
                    )}`}
                  </Box>
                ))
              : "No screens"}
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography variant="subtitle2">Roofs</Typography>
            {order.roofs.length > 0
              ? order.roofs.map((roof) => (
                  <Box key={roof._id} display="flex" alignItems="baseline">
                    <FiberManualRecord
                      style={{
                        width: 10,
                        height: 10,
                        marginRight: 5,
                      }}
                    />{" "}
                    {`${roof.roofName} - ${numberFormat(
                      "cur-display",
                      roof.price
                    )}`}
                  </Box>
                ))
              : "No roofs"}
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography variant="subtitle2">Parts</Typography>
            {order.parts.length > 0
              ? order.parts.map((part) => (
                  <Box key={part._id} display="flex" alignItems="baseline">
                    <FiberManualRecord
                      style={{
                        width: 10,
                        height: 10,
                        marginRight: 5,
                      }}
                    />{" "}
                    {`${part.displayName} - ${part.quantity}pc${
                      part.quantity > 1 ? "s" : ""
                    }`}
                  </Box>
                ))
              : "No parts"}
          </Grid>
        </Grid>
        {order.transfer && (
          <Box mt={2}>
            <InfoAlert severity="info">
              <Typography variant="subtitle2">Material Transfer</Typography>
              <Typography variant="body2">
                From {order.sender} → {order.receiver}
              </Typography>
            </InfoAlert>
          </Box>
        )}
      </Box>
    </Collapse>
  );

  if (isMobile) {
    return (
      <OrderMobileListItem
        onClick={(e) => {
          e.stopPropagation();
          onExpandClick(order._id);
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 10,
          }}
        >
          <Link
            onClick={(e) => e.stopPropagation()}
            className={classes.textLink}
            component={RouterLink}
            underline="always"
            to={{ pathname: "/store", search: `order=${order._id}` }}
          >
            <Typography noWrap>
              {order.orderName.length > 50
                ? order.orderName.substring(0, 50)
                : order.orderName}
            </Typography>
          </Link>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="body1">{order.status} </Typography>
            {order.status === ORDER_STATUS.APPROVED &&
              order?.quoteId?.length === 0 && <TrackingDialog order={order} />}
          </Box>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {order.userDetails.bc_customer_priceGroup === "USD" ? (
                <SvgIcon
                  component={USSvg}
                  viewBox="0 0 640 480"
                  style={{ width: 20, height: 20, marginRight: 5 }}
                />
              ) : (
                <SvgIcon
                  component={CASvg}
                  viewBox="0 0 640 480"
                  style={{ width: 20, height: 20, marginRight: 5 }}
                />
              )}
              {order.userDetails.bc_customer_displayName}
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Person
                color="primary"
                style={{ width: 20, height: 20, marginRight: 5 }}
              />
              {order.userDetails.userName}
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            <Typography variant="caption">
              Updated: {format(new Date(order.updatedAt), "MMM dd, yyyy")}
            </Typography>
            <Typography variant="caption">
              Created: {format(new Date(order.createdAt), "MMM dd, yyyy")}
            </Typography>
          </Box>
        </Box>
        <Divider
          variant="fullWidth"
          style={{ marginTop: 10, marginBottom: 10 }}
        />
        <Typography align="right">
          Total:{" "}
          {`${numberFormat("cur-display", order.totalPrice)} ${
            order.userDetails.bc_customer_priceGroup
          }`}
        </Typography>

        {expandedOrderContent}
      </OrderMobileListItem>
    );
  }

  return (
    <React.Fragment>
      <StyledTableRowAdmin
        hover
        isExpanded={isExpanded}
        onClick={() => onExpandClick(order._id)}
      >
        <TableCell>
          {format(new Date(order.createdAt), "MMM dd, yyyy")}
        </TableCell>
        <TableCell>
          {format(new Date(order.updatedAt), "MMM dd, yyyy")}
        </TableCell>
        <TableCell>
          <Box display="flex" alignItems="center">
            {order.userDetails.bc_customer_priceGroup === "USD" ? (
              <SvgIcon
                component={USSvg}
                viewBox="0 0 640 480"
                style={{ width: 20, height: 20, marginRight: 5 }}
              />
            ) : (
              <SvgIcon
                component={CASvg}
                viewBox="0 0 640 480"
                style={{ width: 20, height: 20, marginRight: 5 }}
              />
            )}
            {order.userDetails.bc_customer_displayName}
          </Box>
          <Box display="flex" alignItems="center">
            <Person
              color="primary"
              style={{ width: 20, height: 20, marginRight: 5 }}
            />
            {order.userDetails.userName}
          </Box>
        </TableCell>
        <TableCell>
          <Tooltip arrow title={order.orderName} placement="top">
            <Typography
              noWrap
              style={{ maxWidth: "300px", display: "inline-block" }}
              variant="body2"
            >
              <Link
                onClick={(e) => e.stopPropagation()}
                className={classes.textLink}
                component={RouterLink}
                underline="always"
                to={{ pathname: "/store", search: `order=${order._id}` }}
              >
                {order.orderName}
              </Link>
            </Typography>
          </Tooltip>
        </TableCell>
        <TableCell>
          <Box display="flex" alignItems="center">
            {order.status}{" "}
            {order.status === ORDER_STATUS.APPROVED &&
              order?.quoteId?.length === 0 && <TrackingDialog order={order} />}
          </Box>
        </TableCell>
        <TableCell align="right">
          {`${numberFormat("cur-display", order.totalPrice)} ${
            order.userDetails.bc_customer_priceGroup
          }`}
        </TableCell>
      </StyledTableRowAdmin>
      <TableRow>
        <TableCell
          colSpan={6}
          onClick={(e) => e.stopPropagation()}
          style={{
            paddingTop: 0,
            paddingBottom: 0,
            borderBottom: isExpanded ? `1px solid ${theme.palette.divider}` : 0,
          }}
        >
          {expandedOrderContent}
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default OrderListItemAdmin;
