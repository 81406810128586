import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, Link as RouterLink } from "react-router-dom";

import { logout } from "../actions";
import { Loader, Message } from "../components";

import { Button, Grid, makeStyles } from "@material-ui/core";

import Image from "../assets/images/home-img-cropped.jpg";
import { ViewContainer } from "../components/ViewContainer";
import InfoAlert from "../components/Alerts/InfoAlert";

const useStyles = makeStyles((theme) => ({
  card: {
    margin: "auto",
    minHeight: "70vh",
    backgroundImage: `url(${Image})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundPosition: "center",
  },
  navLinks: {
    padding: theme.spacing(2),
    margin: theme.spacing(1),
  },
}));

const HomeView = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo, loading, error } = userLogin;
  const history = useHistory();

  function isTokenExpired(token) {
    const expiry = JSON.parse(Buffer.from(token.split(".")[1], "base64")).exp;
    return Math.floor(new Date().getTime() / 1000) >= expiry;
  }

  useEffect(() => {
    if (!userInfo || isTokenExpired(userInfo.token)) {
      dispatch(logout());
      history.push("/login");
    }
  }, [history, userInfo, dispatch]);

  if (error) {
    return (
      <ViewContainer maxWidth="xs">
        <Message severity="error">{error}</Message>
      </ViewContainer>
    );
  }

  if (loading) {
    return <Loader />;
  }

  if (!userInfo?.isApproved) {
    return (
      <Grid
        container
        item
        direction="column"
        alignContent="center"
        justifyContent="center"
        className={classes.card}
      >
        <ViewContainer maxWidth="xs">
          <InfoAlert severity="info">
            <strong>Welcome to Suncoast!</strong> <br /> Thank you for logging
            in, your account is pending approval.
          </InfoAlert>
        </ViewContainer>
      </Grid>
    );
  }

  return (
    <Grid
      container
      item
      direction="column"
      alignContent="center"
      justifyContent="center"
      className={classes.card}
    >
      {userInfo?.storeAccess && (
        <Button
          className={classes.navLinks}
          variant="contained"
          color="primary"
          component={RouterLink}
          to="/store"
        >
          Store
        </Button>
      )}
      <Button
        className={classes.navLinks}
        variant="contained"
        color="primary"
        component={RouterLink}
        to="/docs"
      >
        Library
      </Button>
      <Button
        className={classes.navLinks}
        variant="contained"
        color="primary"
        component={RouterLink}
        to="/account"
      >
        Account
      </Button>
      {userInfo && userInfo.isAdmin && (
        <Button
          className={classes.navLinks}
          variant="contained"
          color="secondary"
          component={RouterLink}
          to="/admin"
        >
          Admin
        </Button>
      )}
    </Grid>
  );
};

export { HomeView };
