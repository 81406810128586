import React, { useContext } from "react";
import { StoreContext } from "../context";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  Link,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
  Paper,
  Divider,
} from "@material-ui/core";
import { AddBox, DeleteForever } from "@material-ui/icons";
import { InfoDialog } from ".";
import StoreRoofSectionTitle from "./Store/Roofs/StoreRoofSectionTitle";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%",
  },
  submit: {
    marginTop: theme.spacing(1),
  },
  marg: {
    margin: theme.spacing(1),
  },
  formControl: {
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(),
    marginRight: theme.spacing(),
    width: "99%",
  },
  mountingAngle: {
    margin: theme.spacing(1),
  },
  btns: {
    display: "flex",
    flexDirection: "row",
  },
  splitCol: {
    display: "flex",
  },
  screenSideIndicator: {
    maxWidth: "65%",
  },
  headerBox: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

const DrawBlades = ({ rf }) => {
  const classes = useStyles();
  const ctx = useContext(StoreContext);
  const { blades } = rf;

  const deleteBladeHandler = (e, i) => {
    e.preventDefault();
    const newBlades = [...blades];
    newBlades.splice(i, 1);
    ctx.setRf({
      ...ctx.rf,
      blades: newBlades,
    });
  };

  return (
    <>
      {blades?.length > 0 &&
        blades.map((blade, i) => (
          <Grid container item xs={12} spacing={1} key={i} alignItems="center">
            <Grid item xs={4}>
              <FormControl className={classes.formControl} required>
                <InputLabel shrink>Type</InputLabel>
                <Select
                  name="bladeType"
                  required
                  value={blade.bladeType}
                  onChange={(e) => ctx.handleChangeBlade(e, i)}
                  style={{ paddingTop: 5 }}
                >
                  <MenuItem value={"B-Blade"}>B-Blade</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <FormControl className={classes.formControl} required>
                <TextField
                  name="bladeFeet"
                  style={{ paddingTop: 5 }}
                  required
                  value={blade.bladeFeet}
                  onFocus={(e) => e.target.select()}
                  onChange={(e) => ctx.handleChangeBlade(e, i)}
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    min: 1,
                    max: 14,
                  }}
                  label="Feet"
                />
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl className={classes.formControl} required>
                <TextField
                  name="bladeInches"
                  style={{ paddingTop: 5 }}
                  required
                  value={blade.bladeInches}
                  onFocus={(e) => e.target.select()}
                  onChange={(e) => ctx.handleChangeBlade(e, i)}
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    min: 0,
                    max: 11.99,
                  }}
                  label="Inches"
                />
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <Box display="flex">
                <FormControl className={classes.formControl} required>
                  <TextField
                    name="bladeQty"
                    style={{ paddingTop: 5 }}
                    required
                    value={blade.bladeQty}
                    onFocus={(e) => e.target.select()}
                    onChange={(e) => ctx.handleChangeBlade(e, i)}
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      min: 1,
                    }}
                    label="Qty"
                  />
                </FormControl>
                <Link
                  onClick={(e) => deleteBladeHandler(e, i)}
                  size="small"
                  color="error"
                  component="button"
                  variant="body2"
                >
                  <DeleteForever fontSize="small" />
                </Link>
              </Box>
            </Grid>
          </Grid>
        ))}
    </>
  );
};

const StoreRoofsBlades = ({ rf }) => {
  const ctx = useContext(StoreContext);
  const { blades } = rf;
  const classes = useStyles();

  return (
    <Paper elevation={0}>
      <Box className={classes.headerBox}>
        <StoreRoofSectionTitle title={"Blades"}>
          <InfoDialog type={"Blades"} />
        </StoreRoofSectionTitle>
        {blades?.length > 0 && (
          <Typography variant="body2">
            {`${blades.length} Blade${blades.length === 1 ? "" : "s"}`}
          </Typography>
        )}
      </Box>

      <Grid
        container
        spacing={1}
        item
        xs={12}
        style={{ alignItems: "center", marginBottom: "5px" }}
      >
        <Grid item xs={12}>
          <Button
            variant="text"
            color="primary"
            onClick={(e) =>
              ctx.setRf({
                ...ctx.rf,
                blades: ctx.handleAddBladesToRoof(blades),
              })
            }
            startIcon={<AddBox color="secondary" />}
            style={{ textTransform: "none" }}
          >
            Add blade
          </Button>
        </Grid>

        {blades?.length > 0 && <DrawBlades rf={ctx.rf} />}
      </Grid>

      <Divider style={{ marginTop: 8, marginBottom: 8 }} />
    </Paper>
  );
};

export { StoreRoofsBlades };
