import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Lightbox from "yet-another-react-lightbox";
import Counter from "yet-another-react-lightbox/dist/plugins/counter";
import Download from "yet-another-react-lightbox/dist/plugins/download";
import Thumbnails from "yet-another-react-lightbox/dist/plugins/thumbnails";
import "yet-another-react-lightbox/dist/styles.css";
import "yet-another-react-lightbox/dist/plugins/thumbnails/thumbnails.css";
import "yet-another-react-lightbox/dist/plugins/counter/counter.css";

import Alert from "@material-ui/lab/Alert";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import Fade from "@material-ui/core/Fade";
import Grid from "@material-ui/core/Grid";
import Grow from "@material-ui/core/Grow";
import Slide from "@material-ui/core/Slide";
import { withStyles } from "@material-ui/core";

import ZoomInIcon from "@material-ui/icons/ZoomIn";
import VideoLibraryIcon from "@material-ui/icons/VideoLibrary";

import { getLibraryPhotos } from "../../actions";
import { Loader } from "../Loader";
import HeaderBox from "./Layout/HeaderBox";
import SubcategoryHeadingBox from "./Layout/SubcategoryHeadingBox";
import ToolbarBox from "./Layout/ToolbarBox";

const PhotosBox = withStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4),
  },
}))(Box);

const PhotoCard = withStyles((theme) => ({
  root: {
    display: "flex",
    flexFlow: "column",
    gap: theme.spacing(3),
    width: "100%",
    minHeight: theme.spacing(20),
    backgroundColor: theme.palette.primary.main,
    borderRadius: 6,
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2),
    color: theme.palette.common.white,
    position: "relative",
    overflow: "hidden",
    cursor: "pointer",
  },
}))(Box);

const LibraryPhotos = () => {
  const dispatch = useDispatch();
  const fileList = useSelector((state) => state.fileList);
  const { files, loading, error } = fileList;
  const [lightBoxIsOpen, setLightBoxIsOpen] = useState(false);
  const [lightBoxPhotosCategory, setLightBoxPhotosCategory] = useState("");
  const [lightBoxPhotoIndex, setLightBoxPhotoIndex] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState("");

  useEffect(() => {
    dispatch(getLibraryPhotos());
  }, [dispatch]);

  const groupedPhotos = useMemo(() => {
    const groupPhotosByCategory = (photos) => {
      const grouped = {};

      photos.forEach((photo) => {
        const parts = photo.key.split("/");
        const category = parts[0];
        const photoName = parts[parts.length - 1];

        if (!grouped[category]) {
          grouped[category] = [];
        }

        if (
          photoName &&
          (!searchKeyword ||
            photoName.toLowerCase().includes(searchKeyword.toLowerCase()))
        ) {
          grouped[category].push({
            ...photo,
            name: photoName,
            category,
          });
        }
      });

      return grouped;
    };

    return groupPhotosByCategory(files.photos || []);
  }, [files.photos, searchKeyword]);

  const categoriesExpandedState = useMemo(() => {
    if (!Object.keys(groupedPhotos).length) {
      return {};
    }

    return Object.keys(groupedPhotos).reduce((acc, key) => {
      acc[key] = false;
      return acc;
    }, {});
  }, [groupedPhotos]);

  const autocompleteOptions = useMemo(() => {
    if (!Object.keys(groupedPhotos).length) {
      return [];
    }

    return Object.entries(groupedPhotos).reduce((acc, curr) => {
      const [, photos] = curr;
      photos.forEach((photo) => {
        acc.push(photo.name);
      });

      return acc;
    }, []);
  }, [groupedPhotos]);

  // slides for the current category
  const slides = useMemo(() => {
    if (!groupedPhotos[lightBoxPhotosCategory]) {
      return [];
    }

    const result = groupedPhotos[lightBoxPhotosCategory]
      .filter((photo) => photo.category === lightBoxPhotosCategory)
      .map((photo) => ({
        ...photo,
        alt: photo.key,
        download: {
          url: photo.url?.original,
          filename: photo.key,
        },
        srcSet: [
          {
            src: photo.url?.small,
            width: 300,
          },
          {
            src: photo.url?.medium,
            width: 600,
          },
          {
            src: photo.url?.large,
            width: 900,
          },
        ],
      }));

    return result;
  }, [groupedPhotos, lightBoxPhotosCategory]);

  const [expanded, setExpanded] = useState(categoriesExpandedState);
  const [hovered, setHovered] = useState({});

  const handleExpandAllCategories = () => {
    const newState = {};
    Object.keys(categoriesExpandedState).forEach((key) => {
      newState[key] = true;
    });

    setExpanded(newState);
  };

  const handleCollapseAllCategories = () => {
    const newState = {};
    Object.keys(categoriesExpandedState).forEach((key) => {
      newState[key] = false;
    });

    setExpanded(newState);
  };

  const handleToggleCategory = (category) => {
    setExpanded((prevState) => ({
      ...prevState,
      [category]: !prevState[category],
    }));
  };

  const handlePhotoClick = (photo, index) => {
    setLightBoxPhotosCategory(photo.category);
    setLightBoxPhotoIndex(index);
    setLightBoxIsOpen(true);
  };

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  return (
    <>
      <Box>
        <HeaderBox>
          <ToolbarBox
            onExpandAll={handleExpandAllCategories}
            onCollapseAll={handleCollapseAllCategories}
            autocompleteOptions={autocompleteOptions}
            onSearchChange={setSearchKeyword}
            searchPlaceholder="Search photos"
          />
        </HeaderBox>

        {Object.entries(groupedPhotos).map(([category, photos]) => {
          return (
            <React.Fragment key={category}>
              <SubcategoryHeadingBox
                collapsedIcon={<VideoLibraryIcon />}
                expanded={expanded[category]}
                expandedIcon={<VideoLibraryIcon />}
                filterApplied={!!searchKeyword}
                itemsCountLabel="photos"
                itemsCount={photos.length}
                onClick={() => handleToggleCategory(category)}
                title={category}
              />
              <Collapse in={expanded[category]}>
                <PhotosBox>
                  <Grid container spacing={2}>
                    {photos.map((photo, index) => {
                      return (
                        <Grid item xs={12} sm={6} md={3} key={photo.key}>
                          <Grow in={expanded[category]} timeout={800}>
                            <div
                              onMouseEnter={() =>
                                setHovered((prevState) => ({
                                  ...prevState,
                                  [photo.key]: true,
                                }))
                              }
                              onMouseLeave={() =>
                                setHovered((prevState) => ({
                                  ...prevState,
                                  [photo.key]: false,
                                }))
                              }
                            >
                              <PhotoCard
                                onClick={() => handlePhotoClick(photo, index)}
                              >
                                <img
                                  alt={photo.name}
                                  loading="lazy"
                                  srcSet={`${photo.url?.small} 300w, ${photo.url?.medium} 600w`}
                                  sizes="(max-width: 600px) 600px, 300px"
                                  src={photo.url?.small}
                                />

                                <Slide
                                  direction="up"
                                  in={hovered[photo.key]}
                                  timeout={200}
                                  mountOnEnter
                                  unmountOnExit
                                >
                                  <Fade in={hovered[photo.key]}>
                                    <ZoomInIcon
                                      style={{
                                        position: "absolute",
                                        bottom: 10,
                                        left: 10,
                                      }}
                                    />
                                  </Fade>
                                </Slide>
                              </PhotoCard>
                            </div>
                          </Grow>
                        </Grid>
                      );
                    })}
                  </Grid>
                </PhotosBox>
              </Collapse>
            </React.Fragment>
          );
        })}
      </Box>

      <Lightbox
        close={() => setLightBoxIsOpen(false)}
        index={lightBoxPhotoIndex}
        open={lightBoxIsOpen}
        plugins={[Counter, Download, Thumbnails]}
        slides={slides}
      />
    </>
  );
};

export { LibraryPhotos };
