import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Switch from "@material-ui/core/Switch";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import InfoIcon from "@material-ui/icons/Info";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";

import { Loader } from "../../../components/Loader";
import { adminUpdateUser } from "../../../actions/adminActions";
import { superAdminDeleteUser } from "../../../actions/superAdminActions";
import { IconButton, withStyles } from "@material-ui/core";
import { ConfirmationDialog } from "../../../components/Dialogs/ConfirmationDialog";

const CustomTableRow = withStyles((theme) => ({
  root: {
    pointerEvents: (props) => (props.usersLoading ? "none" : "auto"),
    opacity: (props) => (props.usersLoading ? 0.5 : 1),
    transition: "opacity 0.2s ease-in-out",
  },
}))(TableRow);

const UserTableRow = ({ user }) => {
  const [confirmDeleteDialogIsOpen, setConfirmDeleteDialogIsOpen] =
    useState(false);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const customers = useSelector((state) => state.adminBcCustomerList);
  const { loading: bcLoading, bcCustomers } = customers;

  const users = useSelector((state) => state.adminUserList);
  const { loading: usersLoading } = users;

  const dispatch = useDispatch();

  const sortedBusinessCentralCustomers = useMemo(() => {
    if (!bcCustomers) {
      return [];
    }

    return bcCustomers?.sort((a, b) =>
      a.bc_customer_displayName.localeCompare(b.bc_customer_displayName)
    );
  }, [bcCustomers]);

  /**
   * Dispatches the action to update this user
   * @param {object} user the current user object
   * @param {object} updates the updates to be applied to the user object
   */
  const handleUpdateUser = (user, updates) => {
    dispatch(adminUpdateUser(user, updates));
  };

  const handleDeleteUser = () => {
    setConfirmDeleteDialogIsOpen(true);
  };

  const handleDeleteConfirm = () => {
    dispatch(superAdminDeleteUser(user));
    setConfirmDeleteDialogIsOpen(false);
  };

  const handleDeleteDiscard = () => {
    setConfirmDeleteDialogIsOpen(false);
  };

  return (
    <>
      <CustomTableRow
        style={{
          pointerEvents: usersLoading ? "none" : "auto",
          opacity: usersLoading ? 0.5 : 1,
          transition: "opacity 0.2s ease-in-out",
        }}
      >
        <TableCell>{user.fname + " " + user.lname}</TableCell>
        <TableCell>
          <Typography noWrap variant="body2">
            {user.email}
          </Typography>
        </TableCell>
        <TableCell>
          {bcLoading ? (
            <Loader />
          ) : (
            sortedBusinessCentralCustomers && (
              <>
                <Select
                  onChange={(e) =>
                    handleUpdateUser(user, { bc_customer_id: e.target.value })
                  }
                  value={user.bc_customer_id}
                >
                  {sortedBusinessCentralCustomers.map((cust) => (
                    <MenuItem
                      key={cust.bc_customer_id}
                      value={cust.bc_customer_id}
                    >
                      {cust.bc_customer_displayName}
                    </MenuItem>
                  ))}
                </Select>
                {user.location &&
                  user.bc_customer_displayName === "unmatched" && (
                    <Tooltip arrow title={`Requested for: ${user.location}`}>
                      <InfoIcon color="secondary" fontSize="small" />
                    </Tooltip>
                  )}
              </>
            )
          )}
        </TableCell>
        <TableCell>
          <Switch
            checked={!!user.isCorporation}
            color="secondary"
            onChange={(e) =>
              handleUpdateUser(user, { isCorporation: e.target.checked })
            }
          />
        </TableCell>
        <TableCell>
          <Switch
            checked={!!user.isApproved}
            color="primary"
            disabled={
              user.storeAccess || user.isAdmin || user.bc_customer_id === "000"
            }
            onChange={(e) =>
              handleUpdateUser(user, { isApproved: e.target.checked })
            }
          />
        </TableCell>
        <TableCell>
          <Switch
            checked={!!user.storeAccess}
            color="primary"
            disabled={user.isAdmin || user.isApproved === false}
            onChange={(e) =>
              handleUpdateUser(user, { storeAccess: e.target.checked })
            }
          />
        </TableCell>
        <TableCell>
          <Switch
            checked={!!user.isAdmin}
            color="secondary"
            disabled={user.isApproved === false}
            onChange={(e) =>
              handleUpdateUser(user, { isAdmin: e.target.checked })
            }
          />
        </TableCell>
        <TableCell>
          <Switch
            checked={!!user.isSuperAdmin}
            color="secondary"
            disabled={!userInfo?.isSuperAdmin || userInfo?._id === user._id}
            onChange={(e) =>
              handleUpdateUser(user, { isSuperAdmin: e.target.checked })
            }
          />
        </TableCell>
        <TableCell>
          {userInfo?.isSuperAdmin && (
            <IconButton onClick={handleDeleteUser} size="small">
              <DeleteOutlineIcon fontSize="inherit" />
            </IconButton>
          )}
        </TableCell>
      </CustomTableRow>

      <ConfirmationDialog
        open={confirmDeleteDialogIsOpen}
        title="Delete User"
        text={`Are you sure you want to delete ${user.fname} ${user.lname} (${user.email})?`}
        onConfirm={handleDeleteConfirm}
        onDiscard={handleDeleteDiscard}
        confirmButtonText="Delete"
        discardButtonText="Cancel"
      />
    </>
  );
};

export default UserTableRow;
