import React, { useMemo } from "react";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { SvgIcon, Collapse } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Fade from "@material-ui/core/Fade";

import SubcategoryHeadingBox from "./Layout/SubcategoryHeadingBox";

const OpenFolderIcon = (props) => {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 24, height: 24 }} {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path d="M5 19l2.757 -7.351a1 1 0 0 1 .936 -.649h12.307a1 1 0 0 1 .986 1.164l-.996 5.211a2 2 0 0 1 -1.964 1.625h-14.026a2 2 0 0 1 -2 -2v-11a2 2 0 0 1 2 -2h4l3 3h7a2 2 0 0 1 2 2v2" />
      </svg>
    </SvgIcon>
  );
};

const ClosedFolderIcon = (props) => {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 24, height: 24 }} {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path d="M5 4h4l3 3h7a2 2 0 0 1 2 2v8a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-11a2 2 0 0 1 2 -2" />
      </svg>
    </SvgIcon>
  );
};

const FileIcon = (props) => {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 24, height: 24 }} {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path d="M14 3v4a1 1 0 0 0 1 1h4" />
        <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
      </svg>
    </SvgIcon>
  );
};

/**
 * Builds and returns a nested tree object from an array of flat file paths.
 * @param {array} items
 * @returns
 */
function buildTree(items) {
  const tree = {};

  if (!items || items.length === 0) {
    return tree;
  }

  items.forEach((item) => {
    const parts = item.path.split("/");
    let current = tree;
    parts.forEach((part, index) => {
      if (index === parts.length - 1) {
        // Leaf node: store the whole item object
        current[part] = item;
      } else {
        if (!current[part]) {
          current[part] = {};
        }
        current = current[part];
      }
    });
  });
  return tree;
}

const StyledUnorderedList = withStyles((theme) => ({
  root: (props) => ({
    margin: 0,
    padding: `${theme.spacing(1)}px 0`,
    marginLeft: (props.level || 0) * 15,
    borderLeft: props.noBorder
      ? "none"
      : `dashed 1px ${theme.palette.text.primary}`,
    // overflow: "hidden",
    position: "relative",
    "&::after": {
      content: '""',
      position: "absolute",
      left: `-${theme.spacing(0.5)}px`,
      bottom: `-${theme.spacing(0.125)}px`,
      width: theme.spacing(1),
      height: theme.spacing(3.5),
      backgroundColor: theme.palette.common.white,
      zIndex: 1,
    },
  }),
}))(({ classes, slide, expanded, noBorder, ...props }) =>
  slide ? (
    <Fade
      in={expanded}
      direction="right"
      mountOnEnter
      unmountOnExit
      timeout={700}
    >
      <Box className={classes.root} {...props} />
    </Fade>
  ) : (
    <Box className={classes.root} {...props} />
  )
);

const StyledFolderEntry = withStyles((theme) => ({
  root: (props) => ({
    cursor: "pointer",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: (props.level || 0) * 15,
    color: theme.palette.secondary.main,
    fontSize: "1rem",
    display: "flex",
    alignItems: "center",
    gap: 8,
    userSelect: "none",
    position: "relative",
    "&:hover": {
      textDecoration: "underline",
    },
    "&::before": {
      content: '""',
      width: (props.level || 0) * 15,
      height: theme.spacing(1),
      borderTop: `dashed 1px ${theme.palette.text.primary}`,
      position: "absolute",
      left: theme.spacing(0),
      top: `calc(50% + ${theme.spacing(1) / 2}px)`,
      transform: "translateY(-50%)",
    },
  }),
}))(({ classes, ...props }) => <Box className={classes.root} {...props} />);

const StyledFileEntry = withStyles((theme) => ({
  root: (props) => ({
    cursor: "pointer",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: (props.level || 0) * 15,
    color: theme.palette.text.primary,
    fontSize: "1rem",
    display: "flex",
    alignItems: "center",
    gap: 8,
    textDecoration: "none",
    userSelect: "none",
    overflow: "hidden",
    position: "relative",
    transition: "color 0.15s",
    "&:hover": {
      textDecoration: "underline",
      color: theme.palette.secondary.main,
    },
    "&::before": {
      content: '""',
      width: (props.level || 0) * 15,
      height: theme.spacing(1),
      borderTop: `dashed 1px ${theme.palette.text.primary}`,
      position: "absolute",
      left: theme.spacing(0),
      top: `calc(50% + ${theme.spacing(1) / 2}px)`,
      transform: "translateY(-50%)",
    },
  }),
}))(({ classes, ...props }) => (
  <Box component="a" className={classes.root} {...props} />
));

const FirstLevelListContainer = withStyles((theme) => ({
  root: {
    padding: `0 ${theme.spacing(3)}px`,
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(2),
  },
}))(Box);

/**
 * CustomTreeNode component renders a tree node which can either be a file or a folder.
 *
 * @param {Object} props - The properties object.
 * @param {string} props.nodeKey - The key or name of the node.
 * @param {Object} props.nodeValue - The value of the node, which can be an object representing a folder or an object with a `url` property representing a file.
 * @param {number} [props.level=0] - The level of the node in the tree, used for indentation.
 *
 * @returns {JSX.Element}
 */
const CustomTreeNode = ({
  expandedFolders,
  level = 0,
  nodeKey,
  nodeValue,
  onToggle,
  parentPath = "",
}) => {
  const fullPath = parentPath ? `${parentPath}/${nodeKey}` : nodeKey;
  const isLeaf = nodeValue && nodeValue.url;

  if (isLeaf) {
    return (
      <StyledFileEntry
        level={level}
        href={nodeValue.url}
        target="_blank"
        rel="noopener noreferrer"
      >
        <FileIcon />{" "}
        <Typography noWrap style={{ width: "100%" }}>
          {nodeKey}
        </Typography>
      </StyledFileEntry>
    );
  } else {
    const expanded = expandedFolders[fullPath] || false;
    return (
      <>
        <StyledFolderEntry level={level} onClick={() => onToggle(fullPath)}>
          {expanded ? <OpenFolderIcon /> : <ClosedFolderIcon />} {nodeKey}
        </StyledFolderEntry>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <StyledUnorderedList level={level + 1} expanded={expanded} slide>
            {Object.entries(nodeValue).map(([childKey, childValue]) => (
              <CustomTreeNode
                key={childKey}
                nodeKey={childKey}
                nodeValue={childValue}
                level={level + 1}
                parentPath={fullPath}
                expandedFolders={expandedFolders}
                onToggle={onToggle}
              />
            ))}
          </StyledUnorderedList>
        </Collapse>
      </>
    );
  }
};

/**
 * Helper function to count leaf nodes (items) in a tree.
 * @param {*} node
 * @returns
 */
const countFiles = (node) => {
  if (node && node.url) return 1;
  return Object.values(node).reduce((sum, child) => sum + countFiles(child), 0);
};

const TopLevelTreeNode = ({
  expandedFolders,
  nodeKey,
  nodeValue,
  onToggle,
  searchKeyword,
}) => {
  const fullPath = nodeKey; // top-level node
  const isLeaf = nodeValue && nodeValue.url;

  if (isLeaf) {
    return (
      <StyledFolderEntry level={0}>
        <span>
          <FileIcon />{" "}
          <a href={nodeValue.url} target="_blank" rel="noopener noreferrer">
            {nodeKey}
          </a>
        </span>
      </StyledFolderEntry>
    );
  } else {
    const expanded = expandedFolders[fullPath] || false;
    return (
      <>
        <SubcategoryHeadingBox
          collapsedIcon={<ClosedFolderIcon />}
          expanded={expanded}
          expandedIcon={<OpenFolderIcon />}
          filterApplied={!!searchKeyword}
          itemsCountLabel="files"
          itemsCount={countFiles(nodeValue)}
          onClick={() => onToggle(fullPath)}
          title={nodeKey}
        />
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <FirstLevelListContainer>
            <StyledUnorderedList level={1} expanded={expanded} slide>
              {Object.entries(nodeValue).map(([childKey, childValue]) => (
                <CustomTreeNode
                  key={childKey}
                  nodeKey={childKey}
                  nodeValue={childValue}
                  level={1}
                  parentPath={fullPath}
                  expandedFolders={expandedFolders}
                  onToggle={onToggle}
                />
              ))}
            </StyledUnorderedList>
          </FirstLevelListContainer>
        </Collapse>
      </>
    );
  }
};

/**
 * FileTree component renders a hierarchical tree structure of files and folders.
 *
 * @param {Object} props - The properties object.
 * @param {Array<{ path: string, url?: string }>} props.items - An array of file/folder objects with their paths and optional URLs.
 * @param {Object} props.expandedFolders - An object representing the expanded state of folders, where keys are folder paths and values are booleans.
 * @param {Function} props.onToggle - A function to handle the toggling of folder expansion.
 * @param {string} [props.searchKeyword] - An optional search keyword to filter the displayed tree nodes.
 *
 * @returns {JSX.Element} The rendered file tree component.
 */
const FileTree = ({ items, expandedFolders, onToggle, searchKeyword }) => {
  const treeData = useMemo(() => buildTree(items), [items]);
  return (
    <StyledUnorderedList level={0} expanded={true} noBorder>
      {Object.entries(treeData).map(([key, value]) => (
        <TopLevelTreeNode
          key={key}
          nodeKey={key}
          nodeValue={value}
          expandedFolders={expandedFolders}
          onToggle={onToggle}
          searchKeyword={searchKeyword}
        />
      ))}
    </StyledUnorderedList>
  );
};

export { FileTree };
