import { TicketContextProvider } from "../../context";

import { TicketListAdmin, TicketListMy } from "../../components";

const Tickets = ({ admin }) => {
  return (
    <TicketContextProvider>
      {admin ? <TicketListAdmin /> : <TicketListMy />}
    </TicketContextProvider>
  );
};

export default Tickets;
