import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Typography } from "@material-ui/core";

import {
  Message,
  Loader,
  OrderListAdmin,
  OrderListAccount,
} from "../../components";

const Orders = ({ type, shipped }) => {
  const history = useHistory();
  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, userInfo } = userLogin;

  useEffect(() => {
    if (!userInfo) {
      history.push("/login");
    }
  }, [history, userInfo]);

  return (
    <>
      <Typography
        align="right"
        color="error"
        component="div"
        style={{
          marginLeft: "auto",
          marginRight: 5,
        }}
        variant="caption"
      >
        *Only Draft or Rejected orders can be deleted.
      </Typography>

      {loading ? (
        <Loader />
      ) : error ? (
        <Message severity="error">{error}</Message>
      ) : type === "admin" ? (
        <OrderListAdmin shipped={shipped} />
      ) : (
        // ) : admin && shipped ? (
        //   <OrderListAdmin shipped={true} />
        <OrderListAccount type={type} />
      )}
    </>
  );
};

export default Orders;
