import React, { useContext } from "react";

import { StoreContext } from "../context";

import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  Link,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
  Paper,
  Divider,
} from "@material-ui/core";
import { AddBox, DeleteForever } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%",
  },
  submit: {
    marginTop: theme.spacing(1),
  },
  marg: {
    margin: theme.spacing(1),
  },
  formControl: {
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(),
    marginRight: theme.spacing(),
    width: "99%",
  },
  mountingAngle: {
    margin: theme.spacing(1),
  },
  btns: {
    display: "flex",
    flexDirection: "row",
  },
  splitCol: {
    display: "flex",
  },
  screenSideIndicator: {
    maxWidth: "65%",
  },
  headerBox: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  titleBox: {
    flexGrow: 1,
  },
}));

const DrawParts = ({ rf }) => {
  const ctx = useContext(StoreContext);
  const classes = useStyles();
  const { addParts } = rf;

  const deletePartHandler = (e, i) => {
    e.preventDefault();
    const newParts = [...addParts];
    newParts.splice(i, 1);
    ctx.setRf({
      ...ctx.rf,
      addParts: newParts,
    });
  };

  return (
    addParts?.length > 0 &&
    addParts.map((part, i) => (
      <Grid container item xs={12} spacing={1} key={i} alignItems="center">
        <Grid item xs={8}>
          <FormControl className={classes.formControl} required>
            <InputLabel shrink>Part</InputLabel>
            <Select
              name="partName"
              style={{ paddingTop: 5 }}
              required
              value={part.partName}
              onChange={(e) => ctx.handleChangePart(e, i)}
            >
              <MenuItem value={"Gutter Corner"}>Gutter Corner</MenuItem>
              <MenuItem value={"Flow Through Gutter - 4"}>
                Flow Through Gutter - 4"
              </MenuItem>
              <MenuItem value={"Flow Through Gutter - 2"}>
                Flow Through Gutter - 2"
              </MenuItem>
              <MenuItem value={"Remote - 1 Channel - Handheld"}>
                Remote - 1 Channel - Handheld
              </MenuItem>
              <MenuItem value={"Remote - 5 Channel - Handheld"}>
                Remote - 5 Channel - Handheld
              </MenuItem>
              <MenuItem value={"Remote - 16 Channel - Handheld"}>
                Remote - 16 Channel - Handheld
              </MenuItem>
              {/* <MenuItem value={'Architectural Ends'}>
                Architectural Ends
              </MenuItem>
              <MenuItem value={'Flow Through Gutter'}>
                Flow Through Gutter
              </MenuItem>
              <MenuItem value={'Double Flow Through Gutter'}>
                Double Flow Through Gutter
              </MenuItem>
              <MenuItem value={'Angle Bracket'}>Angle Bracket</MenuItem> */}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <Box key={i} display="flex">
            <FormControl className={classes.formControl} required>
              <TextField
                name="partQty"
                style={{ paddingTop: 5 }}
                required
                value={part.partQty}
                onFocus={(e) => e.target.select()}
                onChange={(e) => ctx.handleChangePart(e, i)}
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  min: 1,
                }}
                label="Qty"
              />
            </FormControl>
            <Link
              onClick={(e) => deletePartHandler(e, i)}
              size="small"
              color="error"
              component="button"
              variant="body2"
            >
              <DeleteForever fontSize="small" />
            </Link>
          </Box>
        </Grid>
      </Grid>
    ))
  );
};

const StoreRoofsParts = ({ rf }) => {
  const ctx = useContext(StoreContext);
  const { addParts } = rf;

  return (
    <Paper elevation={0}>
      <Box className={useStyles().headerBox}>
        <Typography>
          <strong>Additional Parts</strong>
        </Typography>
        {addParts?.length > 0 && (
          <Typography variant="body2">
            {`${addParts.length} Part${addParts.length === 1 ? "" : "s"}`}
          </Typography>
        )}
      </Box>
      <Grid
        container
        spacing={1}
        item
        xs={12}
        style={{ alignItems: "center", marginBottom: "5px" }}
      >
        <Grid item xs={12}>
          <Button
            variant="text"
            color="primary"
            onClick={(e) =>
              ctx.setRf({
                ...ctx.rf,
                addParts: ctx.handleAddPartsToRoof(addParts),
              })
            }
            startIcon={<AddBox color="secondary" />}
            style={{ textTransform: "none" }}
          >
            Add part
          </Button>
        </Grid>
        {addParts?.length > 0 && <DrawParts rf={ctx.rf} />}
      </Grid>
      <Divider style={{ marginTop: 8, marginBottom: 8 }} />
    </Paper>
  );
};

export { StoreRoofsParts };
