import React, { useContext } from "react";

import { StoreContext } from "../context";
import { InfoDialog } from ".";

import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { AddBox, DeleteForever } from "@material-ui/icons";
import StoreRoofSectionTitle from "./Store/Roofs/StoreRoofSectionTitle";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%",
  },
  submit: {
    marginTop: theme.spacing(1),
  },
  marg: {
    margin: theme.spacing(1),
  },
  formControl: {
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(),
    marginRight: theme.spacing(),
    width: "99%",
  },
  mountingAngle: {
    margin: theme.spacing(1),
  },
  btns: {
    display: "flex",
    flexDirection: "row",
  },
  splitCol: {
    display: "flex",
  },
  screenSideIndicator: {
    maxWidth: "65%",
  },
  headerBox: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));
const getMaxValue = (beamType) => {
  return beamType === "Midspan 4x8" ? 25 : 20;
};
const DrawBeams = ({ rf }) => {
  const classes = useStyles();
  const ctx = useContext(StoreContext);
  const { beamMs } = rf;

  const deleteBeamHandler = (e, i) => {
    e.preventDefault();
    const newBeamMs = [...beamMs];
    newBeamMs.splice(i, 1);
    ctx.setRf({
      ...ctx.rf,
      beamMs: newBeamMs,
    });
  };

  return (
    beamMs?.length > 0 &&
    beamMs.map((beamM, i) => (
      <Grid container item xs={12} spacing={1} key={i} alignItems="center">
        <Grid item xs={5}>
          <FormControl className={classes.formControl} required fullWidth>
            <InputLabel shrink>Beam Type</InputLabel>
            <Select
              name="beamType"
              required
              style={{ paddingTop: 5 }}
              value={beamM.beamType}
              onChange={(e) => ctx.handleChangeBeamM(e, i)}
            >
              <MenuItem value={"Midspan 2x8"}>Midspan 2x8</MenuItem>
              <MenuItem value={"Midspan 2x10"}>Midspan 2x10</MenuItem>
              <MenuItem value={"Midspan 4x8"}>Midspan 4x8</MenuItem>
              <MenuItem value={"Midspan 2 Ply 2x8"}>2 Ply Midspan 2x8</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl className={classes.formControl} required fullWidth>
            <TextField
              name="beamFeet"
              style={{ paddingTop: 5 }}
              required
              value={beamM.beamFeet}
              onFocus={(e) => e.target.select()}
              onChange={(e) => ctx.handleChangeBeamM(e, i)}
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                min: 0,
                max: getMaxValue(beamM.beamType),
              }}
              label="Feet"
            />
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <Box key={i} display="flex">
            <FormControl className={classes.formControl} required fullWidth>
              <TextField
                name="beamInches"
                style={{ paddingTop: 5 }}
                required
                value={beamM.beamInches}
                onFocus={(e) => e.target.select()}
                onChange={(e) => ctx.handleChangeBeamM(e, i)}
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  min: 0,
                  max: 11.99,
                }}
                label="Inches"
              />
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={1}>
          <Link
            onClick={(e) => deleteBeamHandler(e, i)}
            size="small"
            color="error"
            component="button"
            variant="body2"
          >
            <DeleteForever fontSize="small" />
          </Link>
        </Grid>
      </Grid>
    ))
  );
};

const StoreRoofsBeamMs = ({ rf }) => {
  const ctx = useContext(StoreContext);
  const { beamMs } = rf;

  return (
    <Paper elevation={0}>
      <Box className={useStyles().headerBox}>
        <StoreRoofSectionTitle title="Midspan Beams">
          <InfoDialog type={"Midspan Beams"} />
        </StoreRoofSectionTitle>
        {beamMs?.length > 0 && (
          <Typography variant="body2">
            {`${beamMs?.length} Beam${beamMs?.length === 1 ? "" : "s"}`}
          </Typography>
        )}
      </Box>

      <Grid
        container
        spacing={1}
        item
        xs={12}
        style={{
          alignItems: "center",
          marginBottom: "5px",
        }}
      >
        <Grid item xs={12}>
          <Button
            variant="text"
            color="primary"
            onClick={(e) =>
              ctx.setRf({
                ...ctx.rf,
                beamMs: ctx.handleAddBeamMsToRoof(beamMs),
              })
            }
            startIcon={<AddBox color="secondary" />}
            style={{ textTransform: "none" }}
          >
            Add beam
          </Button>
        </Grid>

        {beamMs?.length > 0 && <DrawBeams rf={ctx.rf} />}
      </Grid>
      <Divider style={{ marginTop: 8, marginBottom: 8 }} />
    </Paper>
  );
};

export { StoreRoofsBeamMs };
