import {
  ADMIN_CUSTOMER_TIER_FAIL,
  ADMIN_CUSTOMER_TIER_REQUEST,
  ADMIN_CUSTOMER_TIER_SUCCESS,
  ADMIN_DELETE_USER_FAIL,
  ADMIN_DELETE_USER_REQUEST,
  ADMIN_DELETE_USER_SUCCESS,
  ADMIN_LIST_BCCUSTOMERS_FAIL,
  ADMIN_LIST_BCCUSTOMERS_REQUEST,
  ADMIN_LIST_BCCUSTOMERS_SUCCESS,
  ADMIN_LIST_ORDERS_FAIL,
  ADMIN_LIST_ORDERS_REQUEST,
  ADMIN_LIST_ORDERS_RESET,
  ADMIN_LIST_ORDERS_SUCCESS,
  ADMIN_LIST_TICKETS_FAIL,
  ADMIN_LIST_TICKETS_REQUEST,
  ADMIN_LIST_TICKETS_SUCCESS,
  ADMIN_LIST_USERS_FAIL,
  ADMIN_LIST_USERS_REQUEST,
  ADMIN_LIST_USERS_SUCCESS,
  ADMIN_LIST_WARRANTY_FAIL,
  ADMIN_LIST_WARRANTY_REQUEST,
  ADMIN_LIST_WARRANTY_SUCCESS,
  ADMIN_LIST_WARRANTY_UPDATE_FAIL,
  ADMIN_LIST_WARRANTY_UPDATE_REQUEST,
  ADMIN_LIST_WARRANTY_UPDATE_SUCCESS,
  ADMIN_PORTALPRICING_FAIL,
  ADMIN_PORTALPRICING_REQUEST,
  ADMIN_PORTALPRICING_SUCCESS,
  ADMIN_SAVE_PORTALPRICING_FAIL,
  ADMIN_SAVE_PORTALPRICING_REQUEST,
  ADMIN_SAVE_PORTALPRICING_SUCCESS,
  ADMIN_UPDATE_BC_CUSTOMERS_FAIL,
  ADMIN_UPDATE_BC_CUSTOMERS_REQUEST,
  ADMIN_UPDATE_BC_CUSTOMERS_SUCCESS,
  ADMIN_UPDATE_USER_FAIL,
  ADMIN_UPDATE_USER_REQUEST,
  ADMIN_UPDATE_USER_SUCCESS,
} from "../constants";

export const adminUserListReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_LIST_USERS_REQUEST:
      return { loading: true };
    case ADMIN_LIST_USERS_SUCCESS:
      return { loading: false, users: action.payload };
    case ADMIN_LIST_USERS_FAIL:
      return { loading: false, error: action.payload };
    // -----
    case ADMIN_UPDATE_USER_REQUEST:
      return { ...state, loading: true };
    case ADMIN_UPDATE_USER_SUCCESS: {
      const updatedUser = action.payload;

      return {
        ...state,
        loading: false,
        users: state.users.map((u) =>
          u._id === updatedUser._id ? updatedUser : u
        ),
      };
    }
    case ADMIN_UPDATE_USER_FAIL:
      return { loading: false, error: action.payload };
    // ----
    case ADMIN_DELETE_USER_REQUEST:
      return { ...state, loading: true };
    case ADMIN_DELETE_USER_SUCCESS: {
      const deletedUser = action.payload;

      return {
        ...state,
        loading: false,
        users: state.users.filter((u) => u._id !== deletedUser._id),
      };
    }

    case ADMIN_DELETE_USER_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const adminBcCustomerListReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_LIST_BCCUSTOMERS_REQUEST:
      return { loading: true };
    case ADMIN_LIST_BCCUSTOMERS_SUCCESS:
      return { loading: false, bcCustomers: action.payload };
    case ADMIN_LIST_BCCUSTOMERS_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const adminUpdateBcCustomersReducer = (state = {}, action) => {
  // // console.log('adminUpdateBcCustomersReducer', action.payload);
  switch (action.type) {
    case ADMIN_UPDATE_BC_CUSTOMERS_REQUEST:
      return { loading: true };
    case ADMIN_UPDATE_BC_CUSTOMERS_SUCCESS:
      return { loading: false, users: action.payload };
    case ADMIN_UPDATE_BC_CUSTOMERS_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const adminListOrdersReducer = (state = { orders: [] }, action) => {
  switch (action.type) {
    case ADMIN_LIST_ORDERS_REQUEST:
      return {
        loading: true,
      };
    case ADMIN_LIST_ORDERS_SUCCESS:
      return {
        loading: false,
        orders: action.payload.orders,
        count: action.payload.totalOrdersCount,
      };
    case ADMIN_LIST_ORDERS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case ADMIN_LIST_ORDERS_RESET:
      return {
        orders: [],
      };

    default:
      return state;
  }
};

export const adminListWarrantiesReducer = (state = { orders: [] }, action) => {
  switch (action.type) {
    case ADMIN_LIST_WARRANTY_REQUEST:
      return {
        loading: true,
      };
    case ADMIN_LIST_WARRANTY_UPDATE_REQUEST:
      return {
        loading: true,
        warranties: state.warranties,
      };
    case ADMIN_LIST_WARRANTY_SUCCESS:
      return {
        loading: false,
        warranties: action.payload,
      };
    case ADMIN_LIST_WARRANTY_UPDATE_SUCCESS:
      console.log(state);
      console.log({
        loading: false,
        warranties: {
          count: state.warranties.count,
          warranties: state.warranties.warranties.map((obj) =>
            obj._id === action.payload._id ? action.payload : obj
          ),
        },
      });
      return {
        loading: false,
        warranties: {
          count: state.count,
          warranties: state.warranties.warranties.map((obj) =>
            obj._id === action.payload._id ? action.payload : obj
          ),
        },
      };
    case ADMIN_LIST_WARRANTY_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case ADMIN_LIST_WARRANTY_UPDATE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const adminTicketListReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_LIST_TICKETS_REQUEST:
      return {
        loading: true,
      };
    case ADMIN_LIST_TICKETS_SUCCESS:
      return {
        loading: false,
        tickets: action.payload,
      };
    case ADMIN_LIST_TICKETS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    // case ADMIN_LIST_TICKETS_RESET:
    //   return {
    //     tickets: [],
    //   };

    default:
      return state;
  }
};

export const adminPortalPricingReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_PORTALPRICING_REQUEST:
      return { loading: true };
    case ADMIN_PORTALPRICING_SUCCESS:
      return { loading: false, portalPricings: action.payload };
    case ADMIN_PORTALPRICING_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const adminCustomerTiersReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_CUSTOMER_TIER_REQUEST:
      return { loading: true };
    case ADMIN_CUSTOMER_TIER_SUCCESS:
      return { loading: false, customerTiers: action.payload };
    case ADMIN_CUSTOMER_TIER_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const adminPortalPricingSaveReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_SAVE_PORTALPRICING_REQUEST:
      return { loading: true };
    case ADMIN_SAVE_PORTALPRICING_SUCCESS:
      const { portalPricing, status, statusText } = action.payload;
      return {
        loading: false,
        portalPricing,
        status,
        statusText,
      };
    case ADMIN_SAVE_PORTALPRICING_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
