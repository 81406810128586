import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";

import Alert from "@material-ui/lab/Alert";
import Box from "@material-ui/core/Box";

import { Loader } from "..";
import { FileTree } from "./FileTree";
import HeaderBox from "./Layout/HeaderBox";
import ToolbarBox from "./Layout/ToolbarBox";

const getAutocompleteFileName = (fileKey) => {
  return fileKey.split("/").pop();
};

/**
 * LibraryDocs component is responsible for displaying a tree structure of technical files.
 * It provides functionalities to search, expand/collapse subcategories, and open files in
 * a new tab.
 *
 * @returns {JSX.Element} The rendered component.
 */
const LibraryDocs = () => {
  // const dispatch = useDispatch();
  const [searchKeyword, setSearchKeyword] = useState("");
  const [expandedFolders, setExpandedFolders] = useState({}); // NEW state

  const fileList = useSelector((state) => state.fileList);
  const { files, loading, error } = fileList;

  // Helper to compute folder paths from file keys.
  const getAllFolderPaths = (docs) => {
    const folders = {};
    docs.forEach((doc) => {
      const parts = doc.key.split("/");
      // accumulate intermediate folder paths
      let path = "";
      for (let i = 0; i < parts.length - 1; i++) {
        path = path ? `${path}/${parts[i]}` : parts[i];
        folders[path] = true;
      }
    });
    return folders;
  };

  const handleToggle = (folderPath) => {
    setExpandedFolders((prev) => ({
      ...prev,
      [folderPath]: !prev[folderPath],
    }));
  };

  const handleExpandAllDirectories = () => {
    if (files && files.docs) {
      setExpandedFolders(getAllFolderPaths(files.docs));
    }
  };

  const handleCollapseAllDirectories = () => {
    setExpandedFolders({});
  };

  const filteredFiles = useMemo(() => {
    if (!files || !files.docs) {
      return [];
    }

    return files.docs.filter((doc) =>
      doc.key.toLowerCase().includes(searchKeyword.toLowerCase())
    );
  }, [files, searchKeyword]);

  const treeItems = useMemo(() => {
    return !filteredFiles
      ? []
      : filteredFiles.map((doc) => ({
          path: doc.key,
          url: doc.signedUrl,
        }));
  }, [filteredFiles]);

  const autocompleteOptions = useMemo(() => {
    if (!files || !files.docs) {
      return [];
    }

    return files.docs.reduce((acc, file) => {
      acc.push(getAutocompleteFileName(file.key));

      return acc;
    }, []);
  }, [files]);

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  return (
    <Box>
      <HeaderBox>
        <ToolbarBox
          autocompleteOptions={autocompleteOptions}
          onCollapseAll={handleCollapseAllDirectories}
          onExpandAll={handleExpandAllDirectories}
          onSearchChange={setSearchKeyword}
          searchPlaceholder="Search documentation"
        />
      </HeaderBox>
      <FileTree
        items={treeItems}
        expandedFolders={expandedFolders}
        onToggle={handleToggle}
        searchKeyword={searchKeyword}
      />
    </Box>
  );
};

export { LibraryDocs };
