import React, { useEffect, useState, useContext } from "react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { StoreContext } from "../context";
import "../assets/css/store.css";
import { Message, Loader } from ".";
import lunr from "lunr";

import {
  Box,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  TextField,
  Tooltip,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { Clear, AddBox } from "@material-ui/icons";
import useMobile from "../utils/hooks/useMobile";

const useStyles = makeStyles((theme) => ({
  sortedHeader: {
    fontSize: "1rem",
    verticalAlign: "middle",
    fontWeight: "bold",
    cursor: "pointer",
    userSelect: "none",
  },
  numberInput: {
    "& input[type=number]": {
      "-webkit-appearance": "textfield",
      "-moz-appearance": "textfield",
      appearance: "textfield",
      outline: "none",
    },
    "& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button":
      {
        "-webkit-appearance": "inner-spin-button",
        opacity: 1,
        display: "block",
      },
    "&:focus-within input[type=number]": {
      borderColor: "#3f51b5",
    },
  },
  header: {
    fontSize: "1rem",
    verticalAlign: "middle",
    fontWeight: "bold",
    cursor: "pointer",
    userSelect: "none",
  },
  collapsedHeader: {
    fontSize: "1rem",
    verticalAlign: "middle",
    fontWeight: "bold",
  },
  stickyFilterHeader: {
    position: "sticky",
    top: theme.spacing(6),
    zIndex: 1,
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.up("sm")]: {
      top: theme.spacing(8),
    },
  },
  sortedColumn: {
    backgroundColor: "#f5f5f5",
  },
  column: {},
  sortIcon: {
    fontSize: "1rem",
    verticalAlign: "middle",
    marginLeft: "0.25rem",
  },
  expandIcon: {
    verticalAlign: "middle",
    cursor: "pointer",
  },
  textLink: {
    color: theme.palette.text.link,
    cursor: "pointer",
  },
  partItemPaper: {
    marginTop: theme.spacing(2),
    marginLeft: `-${theme.spacing(1.5)}px`,
    marginRight: `-${theme.spacing(1.5)}px`,
    padding: theme.spacing(1.5),
  },
  priceGridItemMobile: {
    display: "flex",
    flexFlow: "column",
    justifyContent: "flex-end",
  },
}));

const StoreParts = ({ classes }) => {
  const classes2 = useStyles();
  const isMobile = useMobile();

  const ctx = useContext(StoreContext);
  const {
    portalItemsLoading,
    portalItemsError,
    portalParts,
    bcParts,
    handleAddPrtToCart,
  } = ctx;
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  // console.log('portalCategories', portalCategories);

  const [portalPartsNameFilter, setPortalPartsNameFilter] = useState("");
  const [portalPartsCategoryFilter, setPortalPartsCategoryFilter] =
    useState("");
  const [selectedAttributes, setSelectedAttributes] = useState({});
  const [partQuantities, setPartQuantities] = useState({});
  const [visibleAttributes, setVisibleAttributes] = useState({});
  const [displayPrices, setDisplayPrices] = useState({});
  const [portalPartsFiltered, setPortalPartsFiltered] = useState(portalParts);
  const [truePortalParts, setTruePortalParts] = useState([]);

  const categories = [
    { label: "3 Season", value: "3S" },
    { label: "Accessories", value: "ACC" },
    { label: "Fixed Screen", value: "FS" },
    { label: "Hardware", value: "HW" },
    { label: "Louvered Roof", value: "LR" },
    { label: "Motors and Controls", value: "MC" },
    { label: "Mesh and Vinyl", value: "MV" },
    { label: "Other", value: "OTH" },
    { label: "Polycarbonate Roof", value: "PC" },
    { label: "Retractable Screen", value: "RS" },
    { label: "Sales and Marketing", value: "SM" },
  ];
  const idx = lunr(function () {
    this.pipeline.remove(lunr.stemmer); // Disable stemming
    this.searchPipeline.remove(lunr.stemmer); // Disable stemming in search queries
    this.field("displayName2");
    portalParts?.forEach((part, i) => {
      this.add({ ...part, id: i });
    });
  });
  const filterParts = () => {
    if (portalParts && Array.isArray(portalParts)) {
      let portalPartsIn = portalParts;
      portalPartsIn = portalPartsIn.filter((part) => part?.displayName2 !== "");
      if (portalPartsNameFilter || portalPartsCategoryFilter) {
        if (portalPartsNameFilter) {
          const results = idx.search(`*${portalPartsNameFilter}*`);
          portalPartsIn = results.map((result) => portalParts[result.ref]);
        }
        if (portalPartsCategoryFilter) {
          portalPartsIn = portalPartsIn.filter(
            (part) => part?.itemCategoryCode === portalPartsCategoryFilter
          );
        }
      }
      const categoryOrder = categories.reduce((acc, cat, index) => {
        acc[cat.value] = index;
        return acc;
      }, {});

      portalPartsIn.sort((a, b) => {
        const categoryA = categoryOrder[a.itemCategoryCode] ?? Infinity;
        const categoryB = categoryOrder[b.itemCategoryCode] ?? Infinity;

        if (categoryA === categoryB) {
          return a.displayName2.localeCompare(b.displayName2);
        }
        return categoryA - categoryB;
      });
      setPortalPartsFiltered(portalPartsIn);
    } else {
      setPortalPartsFiltered(portalPartsFiltered);
    }
  };
  useEffect(() => {
    portalParts?.forEach((part) =>
      setDisplayPrices((prevPrices) => ({
        ...prevPrices,
        [part.displayName2]: findRelevantPrice(part.displayName2),
      }))
    );

    //setPortalPartsFiltered(portalParts)
    filterParts();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    portalPartsNameFilter,
    portalPartsCategoryFilter,
    portalParts,
    selectedAttributes,
  ]);
  useEffect(() => {
    if (portalParts && truePortalParts.length === 0) {
      setTruePortalParts(portalParts);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portalParts]);

  // const twoby8s = portalParts?.filter((part) => {
  //   return part?.displayName2?.toLowerCase() === '2x8';
  // });

  // console.log('twoby8s', twoby8s);

  // let portalCats = portalCategories?.filter((pc) => {
  //   return !pc.toLowerCase().includes('PK-');
  // });

  const filterNextAttributeOptions = (
    displayName2,
    selectedAttributeName,
    selectedAttributeValue
  ) => {
    const matchingBcParts = bcParts.filter(
      (bcPart) =>
        bcPart.displayName2 === displayName2 &&
        bcPart.attributes.some(
          (attr) =>
            attr.attributeName === selectedAttributeName &&
            attr.attributeValue === selectedAttributeValue
        )
    );
    const validAttributes = {};
    matchingBcParts.forEach((item) => {
      item.attributes.forEach((attribute) => {
        if (!validAttributes[attribute.attributeName]) {
          validAttributes[attribute.attributeName] = [];
        }
        if (
          !validAttributes[attribute.attributeName].includes(
            attribute.attributeValue
          )
        ) {
          validAttributes[attribute.attributeName].push(
            attribute.attributeValue
          );
        }
      });
    });
    validAttributes[selectedAttributeName] = new Set();
    bcParts.forEach((obj) => {
      obj.attributes.forEach((attribute) => {
        if (
          obj.displayName2 === displayName2 &&
          attribute.attributeName === selectedAttributeName
        ) {
          validAttributes[selectedAttributeName].add(attribute.attributeValue);
        }
      });
    });
    validAttributes[selectedAttributeName] = Array.from(
      validAttributes[selectedAttributeName]
    );
    const itemToUpdate = portalPartsFiltered.find(
      (item) => item.displayName2 === displayName2
    );
    if (itemToUpdate) {
      const updatedAttributes = Object.keys(validAttributes).map(
        (attributeName) => ({
          attributeName: attributeName,
          attributeValues: validAttributes[attributeName],
        })
      );
      setPortalPartsFiltered((prevPortalParts) =>
        prevPortalParts.map((part) => {
          if (part.displayName2 === displayName2) {
            portalParts.find(
              (part) => part.displayName2 === displayName2
            ).attributes = updatedAttributes;
            return {
              ...part,
              attributes: updatedAttributes,
            };
          }
          return part;
        })
      );
    }
  };
  const handleAttributeChange = (
    displayName2,
    attributeName,
    attributeValue
  ) => {
    setSelectedAttributes((prevAttributes) => ({
      ...prevAttributes,
      [displayName2]: {
        ...(prevAttributes[displayName2] || {}),
        [attributeName]: attributeValue,
      },
    }));

    setVisibleAttributes((prev) => ({
      ...prev,
      [displayName2]: (prev[displayName2] || 1) + 1,
    }));
    filterNextAttributeOptions(displayName2, attributeName, attributeValue);
  };

  const findRelevantPrice = (displayName2) => {
    let part = portalParts.find((i) => i.displayName2 === displayName2);
    if (!isAddButtonDisabled(part) && displayName2 !== "") {
      let bcPart = findRelevantPart(displayName2);
      let filteredPrice = bcPart?.prices.find(
        (price) => price.salesCode === userInfo.bc_customer_priceGroup
      );
      if (filteredPrice) {
        return (
          "$" +
          (
            bcPart?.prices.find(
              (price) => price.salesCode === userInfo.bc_customer_priceGroup
            ).unitPrice * userInfo.bc_customer_tierRate
          ).toFixed(2)
        );
      } else {
        return "";
      }
    } else {
      return "";
    }
  };
  const handleQuantityChange = (displayName2, quantity) => {
    setPartQuantities((prevQuantities) => ({
      ...prevQuantities,
      [displayName2]: quantity,
    }));
  };

  const findRelevantPart = (displayName2) => {
    const attributes = selectedAttributes[displayName2]
      ? selectedAttributes[displayName2]
      : {};

    return bcParts?.find((bcPart) => {
      if (bcPart.displayName2 !== displayName2) return false;
      return Object.entries(attributes).every(([attributeName, value]) => {
        return bcPart.attributes.some(
          (attr) =>
            attr.attributeName === attributeName &&
            attr.attributeValue === value
        );
      });
    });
  };

  const addToCartHandler = (part) => {
    const quantity = partQuantities[part.displayName2];
    if (!quantity || quantity === 0) {
      toast.error("Please enter a quantity");
      return;
    }
    let missingAttribute = isAddButtonDisabled(part);
    if (missingAttribute) {
      toast.error("Please enter all options");
      return;
    }
    const relevantPart = findRelevantPart(part.displayName2);
    portalParts.find(
      (portalPart) => portalPart.displayName2 === part.displayName2
    ).attributes = truePortalParts.find(
      (portalPart) => portalPart.displayName2 === part.displayName2
    ).attributes;

    if (relevantPart) {
      handleAddPrtToCart(
        relevantPart._id,
        relevantPart.number,
        relevantPart.displayName,
        quantity,
        relevantPart.prices,
        false
      );
      setSelectedAttributes((prevAttributes) => ({
        ...prevAttributes,
        [part.displayName2]: {},
      }));
      setPartQuantities((prevAttributes) => ({
        ...prevAttributes,
        [part.displayName2]: 0,
      }));
      setVisibleAttributes((prev) => ({
        ...prev,
        [part.displayName2]: 1,
      }));
    } else {
      toast.error("That option combination is not allowed");
      return;
    }
  };
  const isAddButtonDisabled = (part) => {
    const selectedAttributesForPart =
      selectedAttributes?.[part.displayName2] || {};
    return part.attributes.some((attribute) => {
      if (
        attribute.attributeName === "Portal Kit" ||
        attribute.attributeName === "Roof Kit" ||
        attribute.attributeName.startsWith("Kit-")
      ) {
        return false;
      }
      return !selectedAttributesForPart[attribute.attributeName];
    });
  };

  return portalItemsLoading ? (
    <Loader />
  ) : portalItemsError ? (
    <Message severity="error">{portalItemsError}</Message>
  ) : (
    <Grid container spacing={2}>
      <Grid
        container
        className={classes2.stickyFilterHeader}
        spacing={0}
        item
        xs={12}
      >
        <Grid item xs={6} sm={3}>
          <TextField
            label="Filter by Category"
            value={portalPartsCategoryFilter}
            onChange={(e) => setPortalPartsCategoryFilter(e.target.value)}
            InputLabelProps={{
              style: {
                fontVariant: "normal",
                color: "rgba(0, 0, 0, 0.75)",
              },
            }}
            style={{ marginBottom: 10, marginTop: -15 }}
            size="small"
            select
            fullWidth
          >
            <MenuItem value="">All</MenuItem>
            {categories.map((category) => (
              <MenuItem key={category.value} value={category.value}>
                {category.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={6} sm={3}>
          <FormControl fullWidth>
            <TextField
              label="Search by Part Name"
              value={portalPartsNameFilter}
              onChange={(e) => setPortalPartsNameFilter(e.target.value)}
              InputLabelProps={{
                style: {
                  fontVariant: "normal",
                  color: "rgba(0, 0, 0, 0.75)",
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {portalPartsNameFilter && (
                      <IconButton
                        aria-label="clear filter"
                        edge="end"
                        onClick={() => setPortalPartsNameFilter("")}
                        size="small"
                      >
                        <Clear fontSize="small" color="error" />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
              style={{ marginBottom: 10, marginTop: -15, marginLeft: 10 }}
              size="small"
            />
          </FormControl>
        </Grid>
      </Grid>

      {/* Parts Header section on desktop only  */}
      {!isMobile && (
        <>
          <Grid item xs={3} className={classes2.header}>
            Part Name
          </Grid>
          <Grid item xs={6} className={classes2.header}>
            Options
          </Grid>
          <Grid
            item
            xs={1}
            className={classes2.header}
            style={{ textAlign: "center" }}
          >
            Price
          </Grid>
          <Grid
            item
            xs={2}
            className={classes2.header}
            style={{ textAlign: "center" }}
          >
            Quantity
          </Grid>
        </>
      )}

      <Grid item xs={12}>
        {portalPartsFiltered &&
          portalPartsFiltered.map((part) => (
            <Paper
              key={part?._id}
              className={classes2.partItemPaper}
              variant="outlined"
            >
              <Grid container spacing={2} style={{ alignItems: "center" }}>
                {/* Part name and category */}
                <Grid item xs={12} sm={3}>
                  <Typography variant="caption" color="textSecondary">
                    {categories.find(
                      (cat) => cat.value === part?.itemCategoryCode
                    )?.label || part?.itemCategoryCode}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Typography variant="body1">
                      {part?.displayName2}
                    </Typography>
                  </Box>
                </Grid>

                {/* Part attributes */}
                <Grid item container spacing={2} xs={10} sm={6} direction="row">
                  {part &&
                    part?.attributes
                      ?.filter(
                        (attribute) => attribute?.attributeName !== "Portal Kit"
                      )
                      ?.filter(
                        (attribute) => attribute?.attributeName !== "Roof Kit"
                      )

                      ?.filter(
                        (attribute) =>
                          !attribute?.attributeName.startsWith("Kit-")
                      )
                      .slice(0, visibleAttributes[part.displayName2] || 1)
                      .map((attribute) => (
                        <Grid item xs={4} sm={4} key={attribute?.attributeName}>
                          <FormControl fullWidth>
                            <TextField
                              select
                              label={attribute?.attributeName}
                              defaultValue={attribute?.attributeValues[0]}
                              value={
                                selectedAttributes?.[part.displayName2]?.[
                                  attribute.attributeName
                                ] || ""
                              }
                              fullWidth
                              size="small"
                              onChange={(e) =>
                                handleAttributeChange(
                                  part?.displayName2,
                                  attribute?.attributeName,
                                  e.target.value
                                )
                              }
                            >
                              {attribute &&
                                attribute?.attributeValues?.map((value) => (
                                  <MenuItem key={value} value={value}>
                                    {value}
                                  </MenuItem>
                                ))}
                            </TextField>
                          </FormControl>
                        </Grid>
                      ))}
                </Grid>

                {/* Part Price on desktop */}
                {!isMobile && (
                  <Grid item sm={1} style={{ textAlign: "right" }}>
                    <Typography
                      color="secondary"
                      variant="body1"
                      style={{ height: "12px" }}
                    >
                      {displayPrices[part.displayName2]}
                    </Typography>
                  </Grid>
                )}

                {/* Quantity and Add button */}
                <Grid
                  item
                  container
                  xs={2}
                  sm={2}
                  alignItems="flex-end"
                  direction="row"
                  justifyContent="flex-end"
                >
                  <Grid item xs={10} style={{ textAlign: "right" }}>
                    <FormControl>
                      <TextField
                        id={"outlined-number" + part?._id}
                        type="number"
                        size="small"
                        required
                        label=" "
                        InputLabelProps={{
                          shrink: true,
                          style: { visibility: "hidden" },
                        }}
                        inputProps={{ min: 0 }}
                        InputProps={{ classes: { root: classes2.numberInput } }}
                        style={{
                          width: 45,
                          textAlign: "right",
                          marginRight: 15,
                        }}
                        value={partQuantities[part.displayName2] || 0}
                        onChange={(e) =>
                          handleQuantityChange(
                            part.displayName2,
                            e.target.value
                          )
                        }
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={2}>
                    <Box>
                      <Tooltip title="Add to Cart">
                        <IconButton
                          size="small"
                          variant="contained"
                          color="secondary"
                          onClick={() => addToCartHandler(part)}
                        >
                          <AddBox />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Grid>
                </Grid>

                {/* Part price on mobile */}
                {isMobile && (
                  <Grid item xs={12} className={classes2.priceGridItemMobile}>
                    <Typography color="textSecondary" variant="body1">
                      Price:{" "}
                      <Typography color="secondary" component="span">
                        {displayPrices[part.displayName2]}
                      </Typography>
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Paper>
          ))}
      </Grid>
    </Grid>
  );
};

export { StoreParts };
