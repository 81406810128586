import React from "react";

import Typography from "@material-ui/core/Typography";

/**
 * Renders a Store > Roof section title with optional children.
 *
 * @param {Object} props - The props for the component.
 * @param {string} props.title - The title to display in the section.
 * @param {React.ReactNode} [props.children] - Optional children to render alongside the title.
 */
const StoreRoofSectionTitle = (props) => {
  const { title, children } = props;

  return (
    <Typography style={{ display: "flex", alignItems: "center" }}>
      <strong>{title}</strong>
      {children}
    </Typography>
  );
};

export default StoreRoofSectionTitle;
