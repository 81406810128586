import React, { useContext } from "react";

import {
  StoreContext,
  // FileContext
} from "../context";
import {
  Loader,
  Message,
  StoreRoofsPosts,
  StoreRoofsFrames,
  StoreRoofsBeamMs,
  StoreRoofsBeamAs,
  StoreRoofsBlades,
  StoreRoofsParts,
} from ".";

import {
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
  Button,
  Box,
  // Link,
} from "@material-ui/core";
import { ORDER_STATUS } from "./Orders/constants";
// import { DeleteForever } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%",
  },
  submit: {
    marginTop: theme.spacing(1),
  },
  marg: {
    margin: theme.spacing(1),
  },
  formControl: {
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(),
    marginRight: theme.spacing(),
    width: "99%",
  },
  mountingAngle: {
    margin: theme.spacing(1),
  },
  btns: {
    display: "flex",
    flexDirection: "row",
  },
  splitCol: {
    display: "flex",
  },
  screenSideIndicator: {
    maxWidth: "65%",
  },
  headerBox: {
    width: "100%",
    display: "flex",
    flexDirection: "rowReverse",
    gap: "16px",
  },
  titleBox: {
    flexGrow: 1,
  },
}));

const RoofForm = ({ order, status }) => {
  const classes = useStyles();
  const ctx = useContext(StoreContext);

  return (
    <form
      className={classes.form}
      onSubmit={(e) => ctx.handleAddRfToCart(e, ctx.rf)}
    >
      <Typography color="inherit" variant="h6" gutterBottom>
        {ctx.rf.roofName || "Louvered Roofs"}
      </Typography>
      <Grid container spacing={8}>
        <Grid item xs={12} sm={7}>
          <StoreRoofsFrames rf={ctx.rf} />
          <StoreRoofsBeamMs rf={ctx.rf} />
          <StoreRoofsBeamAs rf={ctx.rf} />
          <StoreRoofsBlades rf={ctx.rf} />
          <StoreRoofsPosts rf={ctx.rf} />
          <StoreRoofsParts rf={ctx.rf} />
        </Grid>
        <Grid item xs={12} sm={5}>
          <Typography style={{ paddingTop: "10px" }}>
            <strong>Colors</strong>
          </Typography>
          <FormControl required className={classes.formControl} fullWidth>
            <InputLabel shrink>Frame and Post Color</InputLabel>
            <Select
              required
              value={ctx.rf.colors.frameColor}
              onChange={(e) =>
                ctx.setRf({
                  ...ctx.rf,
                  colors: {
                    ...ctx.rf.colors,
                    frameColor: e.target.value,
                    postColor: e.target.value,
                    frameColorRal:
                      e.target.value !== "Custom *"
                        ? ""
                        : ctx.rf.colors.frameColorRal,
                  },
                })
              }
            >
              <MenuItem value={"White"}>White</MenuItem>
              <MenuItem value={"Black"}>Black</MenuItem>
              <MenuItem value={"Brown"}>Brown</MenuItem>
              <MenuItem value={"Tan"}>Tan</MenuItem>
              <MenuItem value={"Linen"}>Linen</MenuItem>
              <MenuItem value={"Grey"}>Grey</MenuItem>
              <MenuItem value={"MF"}>Custom *</MenuItem>
            </Select>
          </FormControl>
          {ctx.rf.colors.frameColor === "MF" ||
          ctx.rf.colors.postColor === "MF" ? (
            <FormControl
              style={{ paddingTop: "10px" }}
              required
              className={classes.formControl}
              fullWidth
            >
              <InputLabel shrink>
                RAL Frame and Post Color (4 Digit Code)
              </InputLabel>
              <TextField
                required
                type="number"
                value={ctx.rf.colors.frameColorRal}
                onFocus={(e) => e.target.select()}
                onKeyDown={(e) => {
                  if (
                    e.key === "e" ||
                    e.key === "E" ||
                    e.key === "." ||
                    e.key === "+"
                  ) {
                    e.preventDefault();
                  }
                }}
                onChange={(e) => {
                  const value = e.target.value.slice(0, 4);
                  ctx.setRf({
                    ...ctx.rf,
                    colors: {
                      ...ctx.rf.colors,
                      frameColorRal: value,
                    },
                  });
                }}
              />
            </FormControl>
          ) : (
            ""
          )}
          <FormControl required className={classes.formControl} fullWidth>
            <InputLabel shrink>Blade Color</InputLabel>
            <Select
              required
              style={{ paddingTop: 5 }}
              //
              value={ctx.rf.colors.bladeColor}
              onChange={(e) =>
                ctx.setRf({
                  ...ctx.rf,
                  colors: {
                    ...ctx.rf.colors,
                    bladeColor: e.target.value,
                    bladeColorRal:
                      e.target.value !== "Custom *"
                        ? ""
                        : ctx.rf.colors.bladeColorRal,
                  },
                })
              }
            >
              <MenuItem value={"White"}>White</MenuItem>
              <MenuItem value={"Black"}>Black</MenuItem>
              <MenuItem value={"Brown"}>Brown</MenuItem>
              <MenuItem value={"Tan"}>Tan</MenuItem>
              <MenuItem value={"Linen"}>Linen</MenuItem>
              <MenuItem value={"Grey"}>Grey</MenuItem>
              <MenuItem value={"MF"}>Custom *</MenuItem>
            </Select>
          </FormControl>
          {ctx.rf.colors.bladeColor === "MF" ? (
            <FormControl
              style={{ paddingTop: "10px" }}
              required
              className={classes.formControl}
              fullWidth
            >
              <InputLabel shrink>RAL Blade Color (4 Digit Code)</InputLabel>
              <TextField
                required
                type="number"
                value={ctx.rf.colors.bladeColorRal}
                onFocus={(e) => e.target.select()}
                onKeyDown={(e) => {
                  if (
                    e.key === "e" ||
                    e.key === "E" ||
                    e.key === "." ||
                    e.key === "+"
                  ) {
                    e.preventDefault();
                  }
                }}
                onChange={(e) => {
                  const value = e.target.value.slice(0, 4);
                  ctx.setRf({
                    ...ctx.rf,
                    colors: {
                      ...ctx.rf.colors,
                      bladeColorRal: value,
                    },
                  });
                }}
              />
            </FormControl>
          ) : (
            ""
          )}

          <Typography style={{ paddingTop: "10px" }}>
            <strong>Motors</strong>
          </Typography>
          <Box display="flex">
            <FormControl required className={classes.formControl} fullWidth>
              <InputLabel shrink>Motor Type</InputLabel>
              <Select
                required
                style={{ paddingTop: 5 }}
                value={ctx.rf.motorType}
                onChange={(e) =>
                  ctx.setRf({
                    ...ctx.rf,
                    motorType: e.target.value,
                  })
                }
              >
                <MenuItem value={"Standard"}>Standard</MenuItem>
              </Select>
            </FormControl>
            <FormControl required className={classes.formControl} fullWidth>
              <TextField
                style={{ paddingTop: 5 }}
                value={ctx.rf.motorQuantity}
                onFocus={(e) => e.target.select()}
                onChange={(e) => {
                  ctx.setRf({
                    ...ctx.rf,
                    motorQuantity: Number(e.target.value),
                  });
                }}
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  min: 1,
                }}
                label="Quantity"
              />
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Typography style={{ paddingTop: "10px", paddingBottom: "10px" }}>
            <strong>Additional Details</strong>
          </Typography>
          <FormControl required fullWidth>
            <TextField
              variant="outlined"
              type="text"
              multiline
              minRows={5}
              value={ctx.rf.details}
              onFocus={(e) => e.target.select()}
              onChange={(e) =>
                ctx.setRf({
                  ...ctx.rf,
                  details: e.target.value,
                })
              }
            />
          </FormControl>
        </Grid>
        <Button
          type="submit"
          className={classes.submit}
          fullWidth
          variant="contained"
          color="primary"
          disabled={
            // true
            ctx.isAdmin &&
            order &&
            (status === ORDER_STATUS.APPROVED ||
              status === ORDER_STATUS.REJECTED)
          }
          size="medium"
        >
          {order || ctx.rf.roofId.length > 0 ? "Update Order" : "Add to Order"}
        </Button>
      </Grid>
    </form>
  );
};

const StoreRoofs = ({ order, status }) => {
  const ctx = useContext(StoreContext);
  return (
    <>
      {ctx.loading ? (
        <Loader />
      ) : ctx.error ? (
        <Message severity="error">{ctx.error}</Message>
      ) : (
        <RoofForm order={order} status={status} />
      )}
    </>
  );
};

export { StoreRoofs };
