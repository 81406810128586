import React, { useState } from "react";

import beamsAimg from "../assets/images/louvered-beamsA.png";
import beamsMimg from "../assets/images/louvered-beamsM.png";
import beamsPimg from "../assets/images/louvered-beamsP.png";
import bladesimg from "../assets/images/blade_sizing.png";
import bladesizing from "../assets/images/blade_sizing.png";
import postsimg from "../assets/images/louvered-posts.png";
import dimensions from "../assets/images/retractableScreens1.png";

import { Dialog, DialogContent, InputLabel, Box } from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

function InfoDialog({ type }) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Box
        onClick={handleClickOpen}
        color="secondary"
        style={{ cursor: "pointer", display: "inline-flex" }}
        component="span"
      >
        {type === "BladeSizing" ? (
          <InputLabel shrink error style={{ marginTop: 5 }}>
            How many blades do I need?
          </InputLabel>
        ) : (
          <InfoOutlinedIcon
            color="secondary"
            style={{ marginLeft: 3 }}
            fontSize="small"
          />
        )}
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="tracking-dialog-title"
        maxWidth="md"
      >
        <DialogContent>
          {type.includes("Perimeter") && <img src={beamsPimg} alt="" />}
          {type.includes("Hosuing") && <div>Housing is cool</div>}
          {type.includes("Midspan") && <img src={beamsMimg} alt="" />}
          {type.includes("Accessory") && <img src={beamsAimg} alt="" />}
          {type.includes("dimensions") && <img src={dimensions} alt="" />}
          {type === "Blades" && <img src={bladesimg} alt="" />}
          {type === "BladeSizing" && <img src={bladesizing} alt="" />}
          {type === "Posts" && <img src={postsimg} alt="" />}
        </DialogContent>
      </Dialog>
    </>
  );
}

export { InfoDialog };
