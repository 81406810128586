import api from "../api";
import { toast } from "react-toastify";

import {
  ADMIN_DELETE_USER_REQUEST,
  ADMIN_DELETE_USER_SUCCESS,
  ADMIN_DELETE_USER_FAIL,
} from "../constants";

export const superAdminDeleteUser = (user) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADMIN_DELETE_USER_REQUEST,
    });

    await toast.promise(api.delete(`/api/admin/users/${user._id}`), {
      pending: "Deleting user...",
      success: "User deleted.",
      error: "Could not delete user.",
    });

    dispatch({
      type: ADMIN_DELETE_USER_SUCCESS,
      payload: user,
    });
  } catch (err) {
    dispatch({
      type: ADMIN_DELETE_USER_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};
