import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactPlayer from "react-player";

import Alert from "@material-ui/lab/Alert";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Fade from "@material-ui/core/Fade";
import Grid from "@material-ui/core/Grid";
import Slide from "@material-ui/core/Slide";
import Typography from "@material-ui/core/Typography";
import { withStyles, useMediaQuery, useTheme } from "@material-ui/core";
import VideoLibraryIcon from "@material-ui/icons/VideoLibrary";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import Grow from "@material-ui/core/Grow";

import { listFiles } from "../../actions";
import { Loader } from "../../components";
import SubcategoryHeadingBox from "./Layout/SubcategoryHeadingBox";
import { toTitleCase } from "../../utils/stringHelpers";
import ToolbarBox from "./Layout/ToolbarBox";
import HeaderBox from "./Layout/HeaderBox";

const VideosBox = withStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4),
  },
}))(Box);

const VideoCard = withStyles((theme) => ({
  root: {
    display: "flex",
    flexFlow: "column",
    gap: theme.spacing(3),
    height: theme.spacing(22),
    width: "100%",
    backgroundColor: theme.palette.primary.main,
    borderRadius: 6,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    color: theme.palette.common.white,
    position: "relative",
    overflow: "hidden",
    cursor: "pointer",
  },
}))(Box);

const VideoDialog = withStyles({
  paper: {
    background: "transparent",
  },
})(Dialog);

const VideoDialogContent = withStyles({
  root: {
    padding: 0,
    "&:first-child": {
      paddingTop: 0,
    },
  },
})(DialogContent);

const getVideoName = (video) => {
  const videoNumber = video.name.split("-").shift().trim();
  let videoName = video.name.split("-").pop().trim();
  videoName = videoName.split(".mp4").shift().trim();
  videoName = videoName[0].toUpperCase() + videoName.slice(1).toLowerCase();

  return `${videoNumber}. ${toTitleCase(videoName)}`;
};

const getAutocompleteVideoName = (video) => {
  return getVideoName(video).split(". ").pop();
};

/**
 * LibraryVideos component is responsible for displaying a categorized list of training videos.
 * It fetches the video files from the server, groups them by category and subcategory,
 * and provides functionalities to search, expand/collapse subcategories, and play videos in a dialog.
 *
 * @returns {JSX.Element} The rendered component.
 */
const LibraryVideos = () => {
  const fileList = useSelector((state) => state.fileList);
  const { files, loading, error } = fileList;
  const dispatch = useDispatch();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const dialogSizeProps = isDesktop
    ? { fullScreen: false, fullWidth: true, maxWidth: "lg" }
    : { fullScreen: false };

  const [videoPlayerDialogIsOpen, setVideoPlayerDialogIsOpen] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [searchKeyword, setSearchKeyword] = useState("");

  useEffect(() => {
    dispatch(listFiles("library", ["Training"]));
  }, [dispatch]);

  const groupedVideos = useMemo(() => {
    const groupVideosByCategory = (videos) => {
      const grouped = {};

      videos.forEach((video) => {
        const parts = video.key.split("/");
        const category = parts[0];
        const subcategory = parts[1];

        if (!grouped[category]) {
          grouped[category] = {};
        }

        if (subcategory) {
          if (!grouped[category][subcategory]) {
            grouped[category][subcategory] = [];
          }

          if (!video.name) {
            return;
          }

          const videoName = getVideoName(video);

          if (
            videoName &&
            (!searchKeyword ||
              videoName.toLowerCase().includes(searchKeyword.toLowerCase()))
          ) {
            grouped[category][subcategory].push({
              ...video,
              name: videoName,
            }); // Add video to subcategory
          }
        }
      });

      return grouped;
    };

    return groupVideosByCategory(files.videos || []);
  }, [files.videos, searchKeyword]);

  const trainingSubcategoriesExpandedState = useMemo(() => {
    if (!groupedVideos?.Training) {
      return {};
    }

    return Object.keys(groupedVideos?.Training).reduce((acc, key) => {
      acc[key] = false;
      return acc;
    }, {});
  }, [groupedVideos]);

  const autocompleteOptions = useMemo(() => {
    if (!groupedVideos?.Training) {
      return [];
    }

    return Object.entries(groupedVideos?.Training).reduce((acc, curr) => {
      const [, videos] = curr;
      videos.forEach((video) => {
        acc.push(getAutocompleteVideoName(video));
      });

      return acc;
    }, []);
  }, [groupedVideos]);

  const [expanded, setExpanded] = useState(trainingSubcategoriesExpandedState);
  const [hovered, setHovered] = useState({});

  const handleToggleTrainingSubcategory = (subcategory) => {
    setExpanded((prevState) => ({
      ...prevState,
      [subcategory]: !prevState[subcategory],
    }));
  };

  const handleExpandAllSubcategories = () => {
    const newState = {};
    Object.keys(trainingSubcategoriesExpandedState).forEach((key) => {
      newState[key] = true;
    });

    setExpanded(newState);
  };

  const handleCollapseAllSubcategories = () => {
    const newState = {};
    Object.keys(trainingSubcategoriesExpandedState).forEach((key) => {
      newState[key] = false;
    });

    setExpanded(newState);
  };

  const handleVideoClick = (video) => {
    setSelectedVideo(video);
    setVideoPlayerDialogIsOpen(true);
  };

  const handleCloseVideoPlayerDialog = () => {
    setSelectedVideo(null);
    setVideoPlayerDialogIsOpen(false);
  };

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  return (
    <>
      <Box>
        <HeaderBox>
          <ToolbarBox
            onExpandAll={handleExpandAllSubcategories}
            onCollapseAll={handleCollapseAllSubcategories}
            autocompleteOptions={autocompleteOptions}
            onSearchChange={setSearchKeyword}
            searchPlaceholder="Search videos"
          />
        </HeaderBox>

        {Object.entries(groupedVideos).map(([category, subcategories]) => {
          if (category.startsWith("Sales")) {
            return null;
          }

          return Object.entries(subcategories).map(([subcategory, videos]) => {
            return (
              <React.Fragment key={subcategory}>
                <SubcategoryHeadingBox
                  collapsedIcon={<VideoLibraryIcon />}
                  expanded={expanded[subcategory]}
                  expandedIcon={<VideoLibraryIcon />}
                  filterApplied={!!searchKeyword}
                  itemsCountLabel="videos"
                  itemsCount={videos.length}
                  key={subcategory}
                  onClick={() => handleToggleTrainingSubcategory(subcategory)}
                  title={subcategory}
                />
                <Collapse
                  key={`${category}-${subcategory}`}
                  in={expanded[subcategory]}
                >
                  <VideosBox>
                    <Grid container spacing={2}>
                      {videos.map((video) => {
                        return (
                          <Grid item xs={12} sm={6} md={3} key={video.key}>
                            <Grow in={expanded[subcategory]} timeout={800}>
                              <div
                                onMouseEnter={() =>
                                  setHovered((prevState) => ({
                                    ...prevState,
                                    [video.key]: true,
                                  }))
                                }
                                onMouseLeave={() =>
                                  setHovered((prevState) => ({
                                    ...prevState,
                                    [video.key]: false,
                                  }))
                                }
                              >
                                <VideoCard
                                  onClick={() => handleVideoClick(video)}
                                >
                                  <Typography
                                    gutterBottom
                                    variant="caption"
                                    color="secondary"
                                  >
                                    {subcategory.toLocaleUpperCase()}
                                  </Typography>
                                  <Typography variant="body1" color="inherit">
                                    <strong>{video.name}</strong>
                                  </Typography>

                                  <Slide
                                    direction="up"
                                    in={hovered[video.key]}
                                    timeout={200}
                                    mountOnEnter
                                    unmountOnExit
                                  >
                                    <Fade in={hovered[video.key]}>
                                      <PlayCircleOutlineIcon
                                        style={{
                                          position: "absolute",
                                          bottom: 10,
                                          left: 10,
                                        }}
                                      />
                                    </Fade>
                                  </Slide>
                                </VideoCard>
                              </div>
                            </Grow>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </VideosBox>
                </Collapse>
              </React.Fragment>
            );
          });
        })}
      </Box>

      {videoPlayerDialogIsOpen && (
        <VideoDialog
          open={videoPlayerDialogIsOpen}
          onClose={handleCloseVideoPlayerDialog}
          {...dialogSizeProps}
        >
          <VideoDialogContent
            style={{
              position: "relative",
            }}
          >
            <ReactPlayer
              url={selectedVideo.signedUrl}
              controls
              width="100%"
              height="100%"
              config={{
                file: {
                  attributes: {
                    preload: "auto",
                  },
                },
              }}
              styles={{
                position: "absolute",
                top: 0,
                left: 0,
              }}
            />
          </VideoDialogContent>
        </VideoDialog>
      )}
    </>
  );
};

export { LibraryVideos };
