import React, { useContext, useState } from "react";

import { StoreContext } from "../context";

import { Button, ButtonGroup } from "@material-ui/core";
import { ORDER_STATUS } from "./Orders/constants";

const StoreCardButtons = ({ item }) => {
  const ctx = useContext(StoreContext);
  const [counter, setCounter] = useState(1);
  // console.log('item', item);
  const itemId = item[5];
  const number = item[1];
  const title = item[2];
  const price = item[4];

  const handleIncrement = () => {
    setCounter(() => counter + 1);
  };

  const handleDecrement = () => {
    setCounter(() => (counter > 0 ? counter - 1 : 0));
  };

  const handleAddToCart = (e, itemId, number, title, price) => {
    // // console.log('handleAddToCart', e.target.value);
    ctx.handleAddPrtToCart(e, itemId, number, title, counter, price);
    setCounter(1);
  };

  const isDisabled = () => {
    let flag;
    if (ctx.isOrder) {
      if (ctx.cartInfo.status === ORDER_STATUS.DRAFT) {
        flag = false;
      } else if (
        ctx.cartInfo.status === ORDER_STATUS.SUBMITTED &&
        ctx.isAdmin
      ) {
        flag = false;
      } else {
        flag = true;
      }
    } else {
      flag = false;
    }
    return flag;
  };

  return (
    <ButtonGroup size="small" aria-label="small outlined button group">
      <Button variant="contained" color="secondary" onClick={handleDecrement}>
        -
      </Button>
      <Button variant="text" disabled>
        {counter}
      </Button>
      <Button
        variant="contained"
        disabled={isDisabled()}
        color="primary"
        onClick={handleIncrement}
      >
        +
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={(e) => handleAddToCart(e, itemId, number, title, price)}
        style={{ marginLeft: 5, marginRight: "auto" }}
      >
        Add
      </Button>
    </ButtonGroup>
  );
};

export { StoreCardButtons };
