import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import { makeStyles, Box, Button } from "@material-ui/core";

import { clearCart } from "../actions";
import { StoreContext } from "../context";
import { FileDialog } from "../components";

import { OrderConfirmationDialog } from "./Orders/Dialogs/OrderConfirmationDialog";
import { ORDER_STATUS } from "./Orders/constants";

const useStyles = makeStyles((theme) => ({
  btns: {
    display: "flex",
    flexDirection: "row",
  },
  clearBtn: {
    marginRight: 5,
  },
}));

const CartButtons = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const ctx = useContext(StoreContext);

  const { cartInfo } = ctx;
  const { status, _id, userDetails } = cartInfo;
  const { bc_customer_displayName: dealer } = userDetails;

  const handleClearCart = () => {
    dispatch(clearCart());
    history.replace({ search: "" });
    window.location.reload();
  };

  return (
    status !== undefined && (
      <Box className={classes.btns}>
        <Button
          onClick={handleClearCart}
          variant="contained"
          color="secondary"
          size="small"
          className={classes.clearBtn}
        >
          {status === ORDER_STATUS.IN_CART ? "Clear Cart" : "New Order"}
        </Button>

        {_id?.length > 0 && <FileDialog dealerName={dealer} orderId={_id} />}

        <OrderConfirmationDialog
          dialogTitle="Save or Submit Order"
          openDialogButtonLabel="Continue"
          saveButtonLabel="Save Order"
          submitButtonLabel="Submit Order"
        />
      </Box>
    )
  );
};

export { CartButtons };
