import React from "react";
import Avatar from "@material-ui/core/Avatar";
import { withStyles } from "@material-ui/core/styles";
import { LockOutlined } from "@material-ui/icons";

const StyledAvatar = withStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
}))(Avatar);

const AuthIcon = () => (
  <StyledAvatar>
    <LockOutlined />
  </StyledAvatar>
);

export default AuthIcon;
