import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, useLocation } from "react-router-dom";
import queryString from "query-string";

import { requestPasswordReset, passwordReset } from "../../actions";

import { Message, Loader } from "../../components";

import {
  Box,
  Button,
  CssBaseline,
  Grid,
  Link,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import AuthIcon from "./AuthIcon";
import { ViewContainer } from "../../components/ViewContainer";
import InfoAlert from "../../components/Alerts/InfoAlert";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    margin: theme.spacing(1),
    width: "100%",
  },
  submit: {
    margin: theme.spacing(2, 0, 2),
  },
}));

function useQuery() {
  const location = useLocation();
  return queryString.parse(location.search);
}

const ResetView = ({ history }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const query = useQuery();

  const [email, setEmail] = useState("");
  const [token, setToken] = useState(null);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState(null);

  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, userInfo } = userLogin;
  const userPasswordReset = useSelector((state) => state.userPasswordReset);
  const { resetResult, loading: resetLoading } = userPasswordReset;

  // // console.log('resetResult', resetResult);

  const inToken = query.token;

  useEffect(() => {
    if (userInfo) {
      history.push("/");
    }
    if (resetResult) {
      setMessage({
        message: resetResult.message,
        type: resetResult.type,
      });
    }
    if (inToken) {
      setToken(inToken);
      setMessage({
        message: `Please enter your new password.`,
        type: "info",
      });
    }
  }, [history, userInfo, resetResult, inToken]);

  const requestHandler = (e) => {
    e.preventDefault();
    if (!email.length > 0) {
      setMessage({
        message: "Please enter an email address.",
        type: "error",
      });
    } else {
      dispatch(requestPasswordReset(email));
    }
  };
  const resetHandler = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setMessage({
        message: "Passwords do not match.",
        type: "error",
      });
    } else {
      dispatch(passwordReset(token, password));
      const location = {
        pathname: "/login",
        state: { reset: "complete" },
      };
      history.push(location);
    }
  };

  return (
    <ViewContainer maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <AuthIcon />
        <Typography gutterBottom variant="h5">
          Password Reset
        </Typography>

        {message && (
          <Box style={{marginTop: "2rem", width: "100%"}}>
            <InfoAlert severity={message.type}>{message.message}</InfoAlert>
          </Box>
        )}

        {error && <Message severity={"error"}>{error}</Message>}

        {loading || resetLoading ? (
          <Loader />
        ) : (
          <form
            className={classes.form}
            onSubmit={token !== null ? resetHandler : requestHandler}
          >
            {token === null && message === null && (
              <>
                <TextField
                  id="resetemail"
                  name="resetemail"
                  type="email"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    autoComplete: "no",
                  }}
                  label="Email Address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  fullWidth
                  disabled={token !== null}
                  autoFocus
                  variant="outlined"
                  margin="normal"
                />
                <Button
                  className={classes.submit}
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                >
                  Reset Password
                </Button>
              </>
            )}
            {token !== null && (
              <>
                <TextField
                  id="resetpassword"
                  name="resetpassword"
                  type="password"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    autoComplete: "no",
                  }}
                  label="Password"
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  fullWidth
                  autoFocus
                  variant="outlined"
                  margin="normal"
                />
                <TextField
                  id="resetconfirmpassword"
                  name="resetconfirmpassword"
                  type="password"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    autoComplete: "no",
                  }}
                  label="Confirm Password"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                  fullWidth
                  variant="outlined"
                  margin="normal"
                />
                <Button
                  className={classes.submit}
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                >
                  Reset Password
                </Button>
              </>
            )}
          </form>
        )}

        <Grid container>
          <Grid item xs>
            <Link component={RouterLink} to="/login">
              Log in
            </Link>
          </Grid>
          <Grid item>
            <Link component={RouterLink} to="/register">
              Don't have an account? Sign up
            </Link>
          </Grid>
        </Grid>
      </div>
    </ViewContainer>
  );
};

export { ResetView };
