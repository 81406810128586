// admin update user constants
export const ADMIN_UPDATE_USER_REQUEST = "ADMIN_UPDATE_USER_REQUEST";
export const ADMIN_UPDATE_USER_SUCCESS = "ADMIN_UPDATE_USER_SUCCESS";
export const ADMIN_UPDATE_USER_FAIL = "ADMIN_UPDATE_USER_FAIL";

// admin delete user constants
export const ADMIN_DELETE_USER_REQUEST = "ADMIN_DELETE_USER_REQUEST";
export const ADMIN_DELETE_USER_SUCCESS = "ADMIN_DELETE_USER_SUCCESS";
export const ADMIN_DELETE_USER_FAIL = "ADMIN_DELETE_USER_FAIL";

// admin list users constants
export const ADMIN_LIST_USERS_REQUEST = "ADMIN_LIST_USERS_REQUEST";
export const ADMIN_LIST_USERS_SUCCESS = "ADMIN_LIST_USERS_SUCCESS";
export const ADMIN_LIST_USERS_FAIL = "ADMIN_LIST_USERS_FAIL";

// admin list orders constants
export const ADMIN_LIST_ORDERS_REQUEST = "ADMIN_LIST_ORDERS_REQUEST";
export const ADMIN_LIST_ORDERS_SUCCESS = "ADMIN_LIST_ORDERS_SUCCESS";
export const ADMIN_LIST_ORDERS_FAIL = "ADMIN_LIST_ORDERS_FAIL";
export const ADMIN_LIST_ORDERS_RESET = "ADMIN_LIST_ORDERS_RESET";

// admin list warranty constants
export const ADMIN_LIST_WARRANTY_REQUEST = "ADMIN_LIST_WARRANTY_REQUEST";
export const ADMIN_LIST_WARRANTY_SUCCESS = "ADMIN_LIST_WARRANTY_SUCCESS";
export const ADMIN_LIST_WARRANTY_FAIL = "ADMIN_LIST_WARRANTY_FAIL";
export const ADMIN_LIST_WARRANTY_RESET = "ADMIN_LIST_WARRANTY_RESET";

export const ADMIN_LIST_WARRANTY_UPDATE_REQUEST =
  "ADMIN_LIST_WARRANTY_UPDATE_REQUEST";
export const ADMIN_LIST_WARRANTY_UPDATE_SUCCESS =
  "ADMIN_LIST_WARRANTY_UPDATE_SUCCESS";
export const ADMIN_LIST_WARRANTY_UPDATE_FAIL =
  "ADMIN_LIST_WARRANTY_UPDATE_FAIL";
export const ADMIN_LIST_WARRANTY_UPDATE_RESET =
  "ADMIN_LIST_WARRANTY_UPDATE_RESET";

// admin list tickets constants
export const ADMIN_LIST_TICKETS_REQUEST = "ADMIN_LIST_TICKETS_REQUEST";
export const ADMIN_LIST_TICKETS_SUCCESS = "ADMIN_LIST_TICKETS_SUCCESS";
export const ADMIN_LIST_TICKETS_FAIL = "ADMIN_LIST_TICKETS_FAIL";
export const ADMIN_LIST_TICKETS_RESET = "ADMIN_LIST_TICKETS_RESET";

// admin list bc customers constants
export const ADMIN_LIST_BCCUSTOMERS_REQUEST = "ADMIN_LIST_BCCUSTOMERS_REQUEST";
export const ADMIN_LIST_BCCUSTOMERS_SUCCESS = "ADMIN_LIST_BCCUSTOMERS_SUCCESS";
export const ADMIN_LIST_BCCUSTOMERS_FAIL = "ADMIN_LIST_BCCUSTOMERS_FAIL";

// admin get portal pricing constants
export const ADMIN_PORTALPRICING_REQUEST = "ADMIN_PORTALPRICING_REQUEST";
export const ADMIN_PORTALPRICING_SUCCESS = "ADMIN_PORTALPRICING_SUCCESS";
export const ADMIN_PORTALPRICING_FAIL = "ADMIN_PORTALPRICING_FAIL";

// admin post portal pricing constants
export const ADMIN_SAVE_PORTALPRICING_REQUEST =
  "ADMIN_SAVE_PORTALPRICING_REQUEST";
export const ADMIN_SAVE_PORTALPRICING_SUCCESS =
  "ADMIN_SAVE_PORTALPRICING_SUCCESS";
export const ADMIN_SAVE_PORTALPRICING_FAIL = "ADMIN_SAVE_PORTALPRICING_FAIL";

// admin update bc customers constants
export const ADMIN_UPDATE_BC_CUSTOMERS = "ADMIN_UPDATE_BC_CUSTOMERS";
export const ADMIN_UPDATE_BC_CUSTOMERS_REQUEST =
  "ADMIN_UPDATE_BC_CUSTOMERS_REQUEST";
export const ADMIN_UPDATE_BC_CUSTOMERS_SUCCESS =
  "ADMIN_UPDATE_BC_CUSTOMERS_SUCCESS";
export const ADMIN_UPDATE_BC_CUSTOMERS_FAIL = "ADMIN_UPDATE_BC_CUSTOMERS_FAIL";

// admin get customer tier constants
export const ADMIN_CUSTOMER_TIER_REQUEST = "ADMIN_CUSTOMER_TIER_REQUEST";
export const ADMIN_CUSTOMER_TIER_SUCCESS = "ADMIN_CUSTOMER_TIER_SUCCESS";
export const ADMIN_CUSTOMER_TIER_FAIL = "ADMIN_CUSTOMER_TIER_FAIL";
