import api from "../api";

import {
  // GET_USER,
  ADMIN_LIST_ORDERS_RESET,
  CART_CLEAR_ITEMS,
  GET_USER_FAIL,
  GET_USER_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_REGISTER_FAIL,
  USER_REGISTER_LOGOUT,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_RESET_FAIL,
  USER_RESET_REQUEST,
  USER_RESET_SUCCESS,
} from "../constants";

// import { generateResetToken } from '../utils/generateToken';

/**
 * Registers a new user.
 *
 * @param {Object} user - The user registration data.
 * @param {string} user.fname - The first name of the user.
 * @param {string} user.lname - The last name of the user.
 * @param {string} user.location - The location of the user.
 * @param {string} user.email - The email address of the user.
 * @param {string} user.password - The password for the user.
 * @returns {Function} A function that takes dispatch as an argument and performs the registration process.
 */
export const register = (user) => async (dispatch) => {
  try {
    dispatch({
      type: USER_REGISTER_REQUEST,
    });

    const { data } = await api.post("/api/users", user);

    dispatch({
      type: USER_REGISTER_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: USER_REGISTER_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const requestPasswordReset = (email) => async (dispatch) => {
  try {
    dispatch({
      type: USER_RESET_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { data } = await api.post("/api/users/reset", { email }, config);

    dispatch({
      type: USER_RESET_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: USER_RESET_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const passwordReset = (token, password) => async (dispatch) => {
  try {
    dispatch({
      type: USER_RESET_REQUEST,
    });

    const { data } = await api.post(`/api/users/reset/${token}`, { password });

    dispatch({
      type: USER_RESET_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: USER_RESET_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const login = (email, password) => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOGIN_REQUEST,
    });

    const { data } = await api.post("/api/users/login", { email, password });

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });

    dispatch({
      type: USER_REGISTER_LOGOUT,
    });

    localStorage.setItem("userInfo", JSON.stringify(data));
  } catch (err) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const getUser = () => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();

    const { data } = await api.get(`/api/users/profile`);

    data.token = userInfo.token;

    dispatch({
      type: GET_USER_SUCCESS,
      payload: data,
    });

    localStorage.setItem("userInfo", JSON.stringify(data));
  } catch (err) {
    dispatch({
      type: GET_USER_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const logout = () => (dispatch) => {
  localStorage.removeItem("cartInfo");
  localStorage.removeItem("orderInfo");
  localStorage.removeItem("registerInfo");
  localStorage.removeItem("userInfo");
  dispatch({ type: ADMIN_LIST_ORDERS_RESET });
  dispatch({ type: CART_CLEAR_ITEMS });
  dispatch({ type: USER_REGISTER_LOGOUT });
  dispatch({ type: USER_LOGOUT });
};
