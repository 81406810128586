import React, { useContext, useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

import { StoreContext } from "../../../context";
import { adminBcCustomerList, createOrder } from "../../../actions";
import { Loader } from "../../Loader";
import { Message } from "../../Message";

import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  makeStyles,
  withStyles,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { Cancel } from "@material-ui/icons";

import { ORDER_STATUS } from "../constants";
import { RejectOrderDialog } from "./RejectOrderDialog";

/**
 * @typedef {import('../../types/orderTypes.js').Order} Order
 */

const useStyles = makeStyles((theme) => ({
  cancelBtn: {
    marginRight: "auto",
  },
  dialogBtn: {
    marginLeft: "auto",
    marginRight: 5,
  },
  closeBtn: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.secondary.main,
  },
}));

const RowBox = withStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),

    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
    },
  },
}))(Box);

const getSchema = (userInfo, onlyPartsInCart) =>
  z
    .object({
      orderName: z
        .string()
        .min(3, {
          message: "Order Name is required (at least 3 characters long)",
        })
        .max(50, { message: "Order Name is too long (50 characters max)" }),
      orderNumber: z.string().nullable().optional(),
      isMaterialTransfer: z.boolean(),
      sender: z.string().nullable().optional(),
      receiver: z.string().nullable().optional(),
    })
    .refine(
      (data) => {
        // Order number is required only for corporation users with a full order
        if (
          userInfo.isCorporation &&
          !onlyPartsInCart &&
          !data.isMaterialTransfer
        ) {
          return Boolean(data.orderNumber && data.orderNumber.trim());
        }

        return true;
      },
      {
        message: "Order Number is required",
        path: ["orderNumber"],
      }
    )
    .refine(
      (data) => {
        if (!data.isMaterialTransfer && data.orderNumber) {
          return /^\d+$/.test(data.orderNumber);
        }
        return true;
      },
      {
        message:
          "Order Number needs to contain only numbers",
        path: ["orderNumber"],
      }
    )
    .refine(
      (data) => {
        if (data.isMaterialTransfer) {
          return !!data.sender;
        }
        return true;
      },
      {
        message: "Required for Material Transfer",
        path: ["sender"],
      }
    )
    .refine(
      (data) => {
        if (data.isMaterialTransfer) {
          return !!data.receiver;
        }
        return true;
      },
      {
        message: "Required for Material Transfer",
        path: ["receiver"],
      }
    )
    .refine(
      (data) => {
        if (data.isMaterialTransfer) {
          return data.receiver !== data.sender;
        }

        return true;
      },
      {
        message: "Sender and Receiver must be different",
        path: ["receiver"],
      }
    );

/**
 * OrderConfirmationDialog component for creating or editing an order. Used by both for customers and admins.
 *
 * @param {Object} props - The component props.
 * @param {string} props.dialogTitle - The title of the dialog.
 * @param {string} props.extraDetails - Additional details for the order.
 * @param {string} props.saveButtonLabel - Label for the *save* form button.
 * @param {string} props.submitButtonLabel - Label for the *submit* form button.
 * @param {string} props.openDialogButtonLabel - Label for the button that triggers the dialog.
 * @returns {JSX.Element} The rendered component.
 */
function OrderConfirmationDialog(props) {
  const {
    dialogTitle,
    extraDetails,
    saveButtonLabel,
    submitButtonLabel,
    openDialogButtonLabel,
  } = props;

  const classes = useStyles();
  const dispatch = useDispatch();
  const ctx = useContext(StoreContext);
  const { cartInfo } = ctx;
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (userInfo.isAdmin || userInfo.isCorporation) {
      dispatch(adminBcCustomerList());
    }
  }, [dispatch, userInfo.isCorporation, userInfo.isAdmin]);

  const adminData = useSelector((state) => state.adminBcCustomerList);
  const { loading, error: adminBcCustomerListError, bcCustomers } = adminData;

  const corporateLocations = useMemo(() => {
    if (!Array.isArray(bcCustomers)) return [];

    const result = bcCustomers
      ?.filter((c) => c.isSenderReceiver)
      .map((c) => c.bc_customer_displayName)
      .sort();

    return result;
  }, [bcCustomers]);

  const defaultSender = corporateLocations[0] || null;

  const onlyPartsInCart =
    cartInfo?.roofs?.length === 0 &&
    cartInfo?.screens?.length === 0 &&
    cartInfo?.parts?.length > 0;

  const schema = useMemo(
    () => getSchema(userInfo, onlyPartsInCart),
    [userInfo, onlyPartsInCart]
  );

  const methods = useForm({
    defaultValues: {
      orderName: "",
      orderNumber: "",
      isMaterialTransfer: false,
      sender: defaultSender,
      receiver: null,
    },
    resolver: zodResolver(schema),
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    trigger,
    watch,
  } = methods;
  const formValues = watch();
  const { isMaterialTransfer, sender } = formValues;

  useEffect(() => {
    if (isMaterialTransfer && sender) {
      setValue("orderName", "Material Transfer");
    } else {
      setValue("orderName", cartInfo.orderName || "");
    }
  }, [sender, isMaterialTransfer, setValue, cartInfo.orderName]);

  useEffect(() => {
    if (ctx.isOrder) {
      setIsEditing(true);

      const numericOrderNumber = cartInfo.orderNumber
        ? cartInfo.orderNumber.replace(/\D/g, "")
        : "";

      reset({
        isMaterialTransfer: cartInfo.transfer,
        orderName: cartInfo.orderName,
        orderNumber: numericOrderNumber,
        receiver: cartInfo.receiver,
        sender: cartInfo.sender,
      });
    }
  }, [ctx.isOrder, cartInfo, reset]);

  const isCorporationButNotOnlyPartsInCart =
    userInfo.isCorporation && !onlyPartsInCart;

  const shouldShowOrderNumber = isCorporationButNotOnlyPartsInCart;

  const dropDiffs = () => {
    const { screens } = cartInfo;
    const diffs = screens.filter(
      (e) => e.measurements.dropLeftDisplay !== e.measurements.dropRightDisplay
    );
    return diffs;
  };

  const handleCancelSubmit = () => {
    reset();
    setDialogIsOpen(false);
  };

  const submitOrder = (data) => {
    const status =
      isEditing && ctx?.isAdmin && cartInfo?.status !== ORDER_STATUS.DRAFT
        ? ORDER_STATUS.APPROVED
        : ORDER_STATUS.SUBMITTED;

    dispatch(
      createOrder(cartInfo, {
        extraDetails,
        orderName: data.orderName,
        orderNumber: data.orderNumber,
        receiver: data.isMaterialTransfer ? data.receiver : null,
        sender: data.isMaterialTransfer ? data.sender : null,
        status,
        transfer: data.isMaterialTransfer,
      })
    );
  };

  const saveOrder = (data) => {
    const status =
      isEditing && ctx?.isAdmin && cartInfo?.status !== ORDER_STATUS.DRAFT
        ? ORDER_STATUS.SUBMITTED
        : ORDER_STATUS.DRAFT;

    dispatch(
      createOrder(cartInfo, {
        extraDetails,
        orderName: data.orderName,
        orderNumber: data.orderNumber,
        receiver: data.receiver,
        sender: data.sender,
        status,
        transfer: data.isMaterialTransfer,
      })
    );
  };

  const onSubmitForm = handleSubmit(
    (data, event) => {
      const submitType = event?.nativeEvent?.submitter?.name;

      if (submitType === "saveOrder") {
        saveOrder(data);
      } else if (submitType === "submitOrder") {
        submitOrder(data);
      }

      setDialogIsOpen(false);
    },
    (errors) => {
      console.log("Form errors:", errors);
    }
  );

  const shouldAllowMaterialTransfer =
    userInfo.isCorporation &&
    !cartInfo.screens.length &&
    !cartInfo.roofs.length;

  const shouldShowOrderNameField = !isMaterialTransfer;

  const shouldShowRejectOrder =
    userInfo.isAdmin && cartInfo.status === ORDER_STATUS.SUBMITTED;

  const dropDifferences = dropDiffs()?.length > 0 ? dropDiffs() : [];

  const allowedStatues = [
    ORDER_STATUS.DRAFT,
    ORDER_STATUS.IN_CART,
    ORDER_STATUS.SUBMITTED,
  ];

  const canView = allowedStatues.includes(cartInfo?.status);
  const canEdit = allowedStatues.includes(cartInfo?.status);

  return (
    <>
      {canView && (
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() => setDialogIsOpen(true)}
          className={classes.dialogBtn}
        >
          {openDialogButtonLabel}
        </Button>
      )}

      <Dialog
        open={dialogIsOpen}
        onClose={handleCancelSubmit}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          {dialogTitle}

          <IconButton onClick={handleCancelSubmit} className={classes.closeBtn}>
            <Cancel />
          </IconButton>
        </DialogTitle>

        {loading && <Loader />}

        {userInfo.isCorporation && adminBcCustomerListError && (
          <Message severity="error">{adminBcCustomerListError}</Message>
        )}

        {!loading && (
          <form onSubmit={onSubmitForm}>
            <DialogContent>
              {shouldAllowMaterialTransfer && (
                <>
                  <FormControl
                    component="fieldset"
                    error={!!errors.isMaterialTransfer}
                  >
                    <Controller
                      name="isMaterialTransfer"
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              {...field}
                              color="primary"
                              checked={field.value}
                            />
                          }
                          label="Material Transfer"
                        />
                      )}
                    />

                    {errors.isMaterialTransfer && (
                      <FormHelperText error>
                        {errors.isMaterialTransfer.message}
                      </FormHelperText>
                    )}
                  </FormControl>

                  {isMaterialTransfer && (
                    <RowBox>
                      <Controller
                        defaultValue={defaultSender}
                        name="sender"
                        control={control}
                        render={({ field }) => (
                          <Autocomplete
                            {...field}
                            options={corporateLocations}
                            onChange={(_, value) => {
                              field.onChange(value);
                              trigger("sender");
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Sender"
                                margin="dense"
                                fullWidth
                                error={!!errors.sender}
                                helperText={errors.sender?.message}
                              />
                            )}
                            style={{ width: "100%" }}
                          />
                        )}
                      />

                      <Controller
                        name="receiver"
                        control={control}
                        render={({ field }) => (
                          <Autocomplete
                            {...field}
                            options={corporateLocations}
                            onChange={(_, value) => {
                              field.onChange(value);
                              trigger("receiver");
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Receiver"
                                margin="dense"
                                fullWidth
                                error={!!errors.receiver}
                                helperText={errors.receiver?.message}
                              />
                            )}
                            style={{ width: "100%" }}
                          />
                        )}
                      />
                    </RowBox>
                  )}
                </>
              )}

              {shouldShowOrderNameField && (
                <Controller
                  name="orderName"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      InputLabelProps={{ shrink: true }}
                      autoFocus
                      disabled={!canEdit}
                      error={!!errors.orderName}
                      helperText={errors.orderName?.message}
                      fullWidth
                      label="Order Name"
                      margin="dense"
                    />
                  )}
                />
              )}

              {shouldShowOrderNumber && (
                <Controller
                  name="orderNumber"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      InputLabelProps={{ shrink: true }}
                      error={!!errors.orderNumber}
                      helperText={errors.orderNumber?.message}
                      fullWidth
                      label="Sales Order / Approved Estimate Number"
                      margin="dense"
                      inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                      onChange={(e) => {
                        const value = e.target.value.replace(/\D/g, "");
                        field.onChange(value);
                      }}
                    />
                  )}
                />
              )}

              {dropDifferences?.length > 0 && (
                <div style={{ marginTop: 5 }}>
                  <TableContainer style={{ marginTop: 5 }} component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Screen Name</TableCell>
                          <TableCell>Drop Left</TableCell>
                          <TableCell>Drop Right</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {dropDifferences.map((dd) => (
                          <TableRow key={dd.screenId}>
                            <TableCell>{dd.screenName}</TableCell>
                            <TableCell>
                              {dd.measurements.dropLeftDisplay}
                            </TableCell>
                            <TableCell>
                              {dd.measurements.dropRightDisplay}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Message severity="error">
                    Please review the following:
                    <br />
                    These screens will be cut with a{" "}
                    <strong>
                      <u>bottom slope</u>
                    </strong>{" "}
                    to match the difference listed in the measurements below.
                  </Message>
                </div>
              )}
            </DialogContent>

            <DialogActions>
              {shouldShowRejectOrder && (
                <Box>
                  <RejectOrderDialog />
                </Box>
              )}

              <Button
                className={classes.saveBtn}
                color="primary"
                name="saveOrder"
                size="small"
                type="submit"
                variant="contained"
              >
                {saveButtonLabel}
              </Button>

              <Button
                color="primary"
                name="submitOrder"
                size="small"
                type="submit"
                variant="contained"
              >
                {submitButtonLabel}
              </Button>
            </DialogActions>
          </form>
        )}
      </Dialog>
    </>
  );
}

export { OrderConfirmationDialog };
