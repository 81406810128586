import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TableBody from "@material-ui/core/TableBody";
import { Message } from "../../../components/Message";

import UserTableRow from "./UserTableRow";
import { adminUserList } from "../../../actions";
import { StickyTableHead } from "../../../components/Table/StickyTableHead";

const Users = (props) => {
  const [orderBy, setOrderBy] = useState("createdAt");
  const [sortOrder, setSortOrder] = useState("desc");
  const users = useSelector((state) => state.adminUserList);
  const { error, users: userList } = users;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(adminUserList({ orderBy, sortOrder }));
  }, [dispatch, orderBy, sortOrder]);

  const handleSort = (column) => {
    if (column === orderBy) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setOrderBy(column);
      setSortOrder("asc");
    }
  };

  if (error) {
    return <Message severity={"error"}>{error}</Message>;
  }

  return (
    <Table component={Paper}>
      <StickyTableHead>
        <TableRow>
          {["Portal User", "Email", "Business Central Customer"].map(
            (column) => (
              <TableCell key={column}>
                <TableSortLabel
                  active={orderBy === column}
                  direction={orderBy === column ? sortOrder : "asc"}
                  onClick={() => handleSort(column)}
                >
                  {column.charAt(0).toUpperCase() + column.slice(1)}
                </TableSortLabel>
              </TableCell>
            )
          )}
          <TableCell>Corporate Store</TableCell>
          <TableCell>Portal Access</TableCell>
          <TableCell>Store Access</TableCell>
          <TableCell>Portal Admin</TableCell>
          <TableCell>Super Admin</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </StickyTableHead>
      <TableBody>
        {userList?.map((user) => (
          <UserTableRow key={user._id} user={user} />
        ))}
      </TableBody>
    </Table>
  );
};

export default Users;
