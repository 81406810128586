import React from 'react';
import {useTheme} from '@material-ui/core/styles';

const UnorderedList = ({ children, ...props }) => {
    const theme = useTheme();

  return (
    <ul style={{ 
      paddingLeft: theme.spacing(1), 
      marginLeft: 0, 
      listStyleType: 'disc', 
      listStylePosition: 'inside' 
    }} {...props}>
      {children}
    </ul>
  );
};

export default UnorderedList;
