import { unstable_createMuiStrictModeTheme as createMuiTheme } from "@material-ui/core/styles";

const defaultTheme = createMuiTheme({
  palette: {
    background: { paper: "white", default: "#fafafa" },
    primary: {
      main: "#000000",
      contrastText: "#fff",
    },
    secondary: {
      main: "#C69023",
      contrastText: "#000",
    },
    error: {
      light: "#e57373",
      main: "#f44336",
      dark: "#d32f2f",
      contrastText: "#fff",
    },
    text: {
      primary: "rgba(0, 0, 0, 0.87)",
      link: "rgb(198, 144, 35)",
      secondary: "rgb(0, 0, 0, 0.38)",
      disabled: "rgba(0, 0, 0, 0.38)",
      hint: "rgba(0, 0, 0, 0.38)",
    },
  },
});

const basicTheme = createMuiTheme({
  palette: { ...defaultTheme.palette },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1680,
    },
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        ".yarl__icon": {
          color: defaultTheme.palette.secondary.main,
        },
      },
    },
    MuiTabs: {
      root: {
        borderBottom: "1px solid #e8e8e8",
        marginBottom: defaultTheme.spacing(2),
        backgroundColor: defaultTheme.palette.background.paper,
      },
      indicator: {
        backgroundColor: defaultTheme.palette.secondary.main,
        height: "4px",
        borderTopLeftRadius: defaultTheme.shape.borderRadius,
        borderTopRightRadius: defaultTheme.shape.borderRadius,
      },
    },
    MuiTab: {
      root: {
        textTransform: "none",
        minWidth: defaultTheme.spacing(6),
        [defaultTheme.breakpoints.up("sm")]: {
          minWidth: defaultTheme.spacing(24),
        },
        fontSize: defaultTheme.typography.pxToRem(15),
        fontWeight: defaultTheme.typography.fontWeightRegular,
        color: defaultTheme.palette.text.primary,
        "&:hover": {
          color: defaultTheme.palette.primary.light,
          opacity: 1,
        },
        "&$selected": {
          color: defaultTheme.palette.primary.main,
          fontWeight: defaultTheme.typography.fontWeightMedium,
        },
        "&:focus": {
          color: defaultTheme.palette.primary.light,
        },
      },
      selected: {},
    },
    MuiTooltip: {
      tooltip: {
        fontSize: 12,
      },
    },
  },
});

export { basicTheme };
