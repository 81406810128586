import React, { useContext } from "react";

import { StoreContext } from "../context";
import { StoreScreens, StoreParts, StoreRoofs } from "../components";

import { Box, Tab, Tabs, makeStyles } from "@material-ui/core";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div hidden={value !== index} {...other}>
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  cardTitle: {
    fontSize: "1.25rem",
    fontFamily: ("Roboto", "Helvetica", "Arial", "sans-serif"),
    fontWeight: 500,
    lineHeight: 1.6,
    letterSpacing: "0.0075em",
  },
}));

const ScrTab = ({ value, cartInfo }) => {
  return (
    <TabPanel value={value} index={0}>
      <StoreScreens order={cartInfo?.orderId} status={cartInfo?.status} />
    </TabPanel>
  );
};
const RoofTab = ({ value }) => {
  return (
    <TabPanel value={value} index={1}>
      <StoreRoofs />
    </TabPanel>
  );
};
const PrtTab2 = ({ value, cartInfo }) => {
  return (
    <TabPanel value={value} index={2}>
      <StoreParts />
    </TabPanel>
  );
};

const StoreTabs = ({ tabValue, setTabValue }) => {
  const classes = useStyles();
  const cartInfo = useContext(StoreContext).cartInfo;

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Tabs value={tabValue} onChange={handleChange} variant="scrollable">
        <Tab label="Retractable Screens" />
        <Tab label="Louvered Roofs" />
        <Tab label="Parts & Extras" />
      </Tabs>
      <ScrTab value={tabValue} cartInfo={cartInfo} />
      <PrtTab2 value={tabValue} cartInfo={cartInfo} />
      <RoofTab value={tabValue} />
    </div>
  );
};

export { StoreTabs };
